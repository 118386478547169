import { Button, Form, Input, Table, notification, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { AGetConstants } from "services/v1/template";

export interface Step1Props {
  onCancel: () => void
  onNext: (data: Api.V1Component.ConstantConfigurationVo) => void
  defaultValue?: Api.V1Component.ConstantConfigurationVo
}

interface Step1State {
  lists: { label: string, rawValue: number, value?: number, isEditor: boolean, message?: string }[],
  loading: boolean
}

export class TemplateStep1 extends React.Component<Step1Props, Step1State> {
  constructor(props: Step1Props) {
    super(props)
    if (props.defaultValue) {
      this.state.lists = this.disposeData(props.defaultValue)
    }
  }

  state: Step1State = {
    lists: [],
    loading: false
  }

  componentDidMount() {
    if (this.state.lists.length === 0) {
      this.getTemplateLists()
    }
  }
  
  columns:ColumnsType<Step1State['lists'][0]>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1,
      width: '50px',
      align: 'center'
    },
    {
      title: 'Constant Name',
      render: (_val, record) => record.label,
      width: '200px'
    },
    {
      title: 'Value',
      width: '200px',
      render: (_val, record, index) => {
        const value = record.value || record.rawValue
        if (record.isEditor) {
          return (
            <Tooltip title={record.message} placement="topLeft">
              <Input onChange={(e) => this.inputChange(index, e.target.value)} defaultValue={value} type="number" placeholder="Please enter value" />
            </Tooltip>
          )
        }
        return value
      }
    },
    {
      title: 'Option',
      render: (_val, record, index) => {
        const saveBtn = [
          <Button disabled={!!record.message} onClick={() => this.save(index)} type="link" key="Save">Save</Button>,
          <Button onClick={() => this.cancel(index)} type="link" key="Cancel">Cancel</Button>
        ]

        const editorBtn = <Button onClick={() => this.editor(index)} type="link" key="Editor">Editor</Button>
        return (
          <Space>
            {
              record.isEditor ? saveBtn : editorBtn
            }
          </Space>
        )
      }
    }
  ]

  editor = (index: number) => {
    const current = this.state.lists[index]
    current.isEditor = true
    this.setState({
      lists: this.state.lists
    })
  }

  cancel = (index: number) => {
    const current = this.state.lists[index]
    current.isEditor = false
    current.value = current.rawValue
    current.message = ''
    this.setState({
      lists: this.state.lists
    })
  }

  save = (index: number) => {
    const current = this.state.lists[index]
    current.isEditor = false
    this.setState({
      lists: this.state.lists
    })
  }

  inputChange = (index: number, value: string) => {
    const current = this.state.lists[index]
    if (value) {
      const val = Number(value)
      if (isNaN(val)) {
        current.message = 'Not legal.'
        current.value = 0
      } else {
        current.value = val
        current.message = ''
      }
    } else {
      current.value = 0
      current.message = 'Not null.'
    }
    this.setState({
      lists: this.state.lists
    })
  }

  getTemplateLists = async () => {
    const res = await AGetConstants({})

    if (res.success) {
      this.setState({
        lists: this.disposeData(res.data)
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }
  
  disposeData = (data: Api.V1Component.ConstantConfigurationVo) => {
    let lists: Step1State['lists'] = []
    for (const [key, value] of Object.entries(data)) {
      lists.push({
        label: key,
        rawValue: value as number,
        isEditor: false
      })
    }
    return lists
  }

  onNext = () => {
    const data = Object.fromEntries(this.state.lists.map(item => [item.label, item.isEditor ? item.rawValue : item.value || item.rawValue]))
    this.props.onNext(data as unknown as Api.V1Component.ConstantConfigurationVo)
  }

  render() {
    return (
      <>
      <Table
        loading={this.state.loading}
        columns={this.columns}
        dataSource={this.state.lists}
        pagination={false}
        ></Table>

        <Form.Item style={{ marginTop: '20px' }}>
          <Space>
            <Button onClick={this.props.onCancel}>Cancel</Button>
            <Button onClick={this.onNext} type="primary">
              Next
            </Button>
          </Space>
        </Form.Item>
      </>
    )
  }
}