import { Empty, Form, FormInstance, Modal, notification, Select, Spin } from "antd";
import React from "react";
import { AGetTenantRefTemplateList, AGetTenantTemplateList, APostTenantRefTemplate } from "services/v1/template";

export interface RelevanceTemplateProps {
  visible: boolean
  id: number
  onClose: () => void
}

interface RelevanceTemplateState {
  loading: boolean
  options: { label: string, value: number }[],
  initLoading: boolean
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

interface FormData {
  templateIds?: number[]
}

const request = [{ required: true }];

export class RelevanceTemplate extends React.Component<RelevanceTemplateProps, RelevanceTemplateState> {
  componentDidMount() {
    if (this.props.id === -1) return
    this.getExistRelevance()
  }

  state: RelevanceTemplateState = {
    loading: false,
    options: [],
    initLoading: false
  }

  getExistRelevance = async () => {
    this.setState({
      initLoading: true
    })

    const res = await AGetTenantTemplateList({
      tenantId: this.props.id,
      pageIndex: 1,
      pageSize: 10000
    })

    this.setState({
      initLoading: true
    })
    
    if (res.success) {
      const options = res.data.values?.map(item => {
        return { label: `${item.templateName}`, value: item.id }
      }) || []
      const value = options.map(item => item.value)

      this.formRef.current?.setFieldsValue({
        templateIds: value
      })

      this.setState({
        options
      })
    }

    this.getLists('')
  }

  formRef = React.createRef<FormInstance<FormData>>()

  onFinish = async () => {
    const formData = await this.formRef.current?.validateFields()

    this.setState({
      loading: true
    })

    const res = await APostTenantRefTemplate({
      tenantId: this.props.id,
      templateIds: formData?.templateIds
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Relevance success.'
      })
      
      this.props.onClose()
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  timer: any = null
  onSearch = (val: string) => {
    clearInterval(this.timer)

    this.timer = setTimeout(() => {
      this.getLists(val)
    }, 500)
  }

  getLists = async (keyword?: string) => {
    if (this.state.options.length !== 0 && !keyword) return

    this.setState({ loading: true })

    const res = await AGetTenantRefTemplateList({
      templateName: keyword,
      pageIndex: 1,
      pageSize: 100
    })

    this.formRef.current?.getFieldValue('templateIds')

    if (res.success) {
      const values: number[] = this.formRef.current?.getFieldValue('templateIds')
      const selectedOptions = this.state.options?.filter(item => values.includes(item.value)) || []
      const filterOptions = res.data?.values?.filter((item) => !values.includes(item.id)) || []
      const createNewOptions = filterOptions.map(item => {
        return { label: `${item.templateName}`, value: item.id }
      })
      this.setState({
        options: selectedOptions.concat(createNewOptions),
        loading: false
      })
    } else {
      this.setState({
        loading: false
      })
    }
  }

  render () {
    let notFoundContent = null
    const { loading, options } = this.state
    if (loading) {
      notFoundContent = <Spin />
    } else if (options?.length === 0) {
      notFoundContent = <Empty />
    }
    return (
      <Modal
        title="Relevance Template"
        visible={this.props.visible}
        onCancel={this.props.onClose}
        onOk={this.onFinish}>
        <Form<FormData>
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
          ref={this.formRef}
        >
          <Form.Item
            label="Template"
            name="templateIds"
            rules={request}
          >
            <Select<number[]>
              options={options}
              onSearch={this.onSearch}
              placeholder="Please select."
              showSearch
              filterOption={false}
              mode="multiple"
              notFoundContent={notFoundContent}
              allowClear
            >
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}