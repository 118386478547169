import { Button, notification, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { connect } from "react-redux";
import { AGetHistory } from "services/v1/calculator";
import { dateFormat } from "utils";
import { CalculatorDetail } from "./details2"
import { CalculatorImport } from "./import";
import { UploadHistory } from './upload-history'

interface CalculatorProps {
  isAdmin: boolean
}

interface CalculatorState {
  calculatorLists: Api.V1Component.HistoryVo[],
  listFormData: Api.V1Calculator.AGetHistory.Request
  loading: boolean
  showDetail: boolean
  total: number
  currendId: string
  visibleImport: boolean
  visibleUploadFileHistory: boolean
}

class CalculatorManage extends React.Component<CalculatorProps, CalculatorState> {
  state: CalculatorState = {
    calculatorLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    showDetail: false,
    total: 0,
    currendId: '',
    visibleImport: false,
    visibleUploadFileHistory: false
  }
  
  componentDidMount() {
    this.getCalculatorLists()
  }

  getCalculatorLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await AGetHistory(listFormData)

    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        calculatorLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns:ColumnsType<Api.V1Component.HistoryVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1
    },
    {
      title: 'TicketNumber',
      dataIndex: 'ticketNumber'
    },
    {
      title: 'Creator',
      dataIndex: 'creator'
    },
    {
      title: 'CreateTime',
      dataIndex: 'createTime',
      render: text => dateFormat(text)
    },
    {
      title: 'operate',
      render: (_val, _record, index) => {
        return (
          <Button onClick={_ => this.seeDetail(index)} type="link">Details</Button>
        )
      }
    }
  ]

  seeDetail = (index: number) => {
    this.setState({
      currendId: this.state.calculatorLists[index].id || '',
      showDetail: true,
    })
  }

  onFormClose = () => {
    this.setState({
      showDetail: false
    })

    this.getCalculatorLists()
  }

  import = () => {

  }

  renderBtns = () => {
    // console.log(this.props.isAdmin)
    // if (!this.props.isAdmin) return null 
    return (
      <Space style={{ marginBottom: '16px' }}>
        <Button onClick={_ => this.setState({ visibleImport: true })} type="primary">Import</Button>
        {!this.props.isAdmin ? null : <Button onClick={_ => this.setState({ visibleUploadFileHistory: true })}>Upload File History</Button>}
      </Space>
    )
  }

  renderTable() {
    return (
      <>
        {this.renderBtns()}
        <Table
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.calculatorLists}
          pagination={{
            onChange: this.getCalculatorLists,
            total: this.state.total,
            pageSize: this.state.listFormData.pageSize,
            current: this.state.listFormData.pageIndex,
            size: "small"
          }}></Table>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderTable()}
        {
          this.state.currendId &&
          <CalculatorDetail
            visible={this.state.showDetail}
            id={this.state.currendId}
            onClose={this.onFormClose}
            key={this.state.currendId}/>
        }
        <CalculatorImport
          onCancel={() => this.setState({ visibleImport: false })}
          visible={this.state.visibleImport}
          key={`CalculatorImport:${this.state.visibleImport.toString()}`}/>
        
        <UploadHistory
          onCancel={() => this.setState({ visibleUploadFileHistory: false })}
          visible={this.state.visibleUploadFileHistory}
          key={`UploadHistory:${this.state.visibleUploadFileHistory.toString()}`}/>
      </>
    )
  }
}

export default connect((store: any) => {
  return {
    isAdmin: store.username === 'admin' || store.username === 'digital_super_user'
  }
})(CalculatorManage)