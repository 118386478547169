import { Form, message, Modal, notification } from "antd";
import moment from "moment";
import React from "react";
import { APostPurchase_Id } from "services/v1/subscription";

export interface BuySubscriptionProps {
  visible: boolean
  onClose: (isRefresh: boolean) => void
  id: number
  subscriptionName?: string
  subscriptionRemark?: string
  startTime?: number
  endTime?: number
  price?: number
}

interface BuySubscriptionState {
  loading: boolean
}

export class BuySubscription extends React.Component<BuySubscriptionProps, BuySubscriptionState> {

  state: BuySubscriptionState = {
    loading: false
  }

  onFinish = async () => {
    this.setState({
      loading: true
    })

    const res = await APostPurchase_Id({
      id: this.props.id
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      message.success('Buy success.')
      this.props.onClose(true)
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render() {
    const { subscriptionName, subscriptionRemark, startTime, endTime, price } = this.props
    const startDate = startTime ? moment(startTime * 1000).format('yyyy/MM/DD') : null
    const endDate = endTime ? moment(endTime * 1000).format('yyyy/MM/DD') : null
    return (
      <Modal
        title="Buy Subscription"
        visible={this.props.visible}
        onCancel={() => this.props.onClose(false)}
        onOk={this.onFinish}
        okText="Confirm Buy"
        confirmLoading={this.state.loading}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          style={{width: '400px'}}
        >
          <Form.Item label="Subscription Name">{subscriptionName}</Form.Item>
          <Form.Item label="Subscription Remark">{subscriptionRemark}</Form.Item>
          <Form.Item label="Available Time">{`${startDate} - ${endDate}`}</Form.Item>
          <Form.Item label="Price">${price}</Form.Item>
        </Form>
      </Modal>
    )
  }
}