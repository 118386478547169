import { Form, FormInstance, Input, Modal, notification, Space } from "antd";
import React from "react";
import GetVerifyCode from "../../../utils/verify-code"
import { APostChangePassWord } from "services/v1/tenant-manage";

export interface ModifyPasswordProps {
  visible: boolean
  id: number
  onClose: () => void
}

interface ModifyPasswordState {
  loading: boolean
  verifyImgSrc: string;
  random: string;
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export class ModifyPassword extends React.Component<ModifyPasswordProps, ModifyPasswordState> {
  componentWillMount() {
    this.getVerifyCode()
  }

  state: ModifyPasswordState = {
    loading: false,
    verifyImgSrc: '',
    random: ''
  }

  formRef = React.createRef<FormInstance<Api.V1TenantManage.APostChangePassWord.Request>>()

  onFinish = async () => {
    const data = await this.formRef.current?.validateFields()

    this.setState({
      loading: true
    })

    const res = await APostChangePassWord({
      coverPassWord: data?.coverPassWord,
      verifyCode: data?.verifyCode,
      userId: this.props.id,
      slideProof: this.state.random
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Modify password success.'
      })
      
      this.props.onClose()
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  getVerifyCode = async() => {
    const data = await GetVerifyCode()
    this.setState({
      verifyImgSrc: data.imgSrc,
      random: data.random
    })
  }

  render () {
    return (
      <Modal
        title="Modify password"
        visible={this.props.visible}
        onCancel={this.props.onClose}
        onOk={this.onFinish}>
        <Form 
          ref={this.formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="Cover PassWord"
            name="coverPassWord"
            rules={request}
          >
            <Input placeholder="Please enter password"/>
          </Form.Item>
          
          <Form.Item label="Verify Code" name="verifyCode" rules={request}>
            <Space>
              <Input placeholder="input the verify code" />
              { this.state.verifyImgSrc &&
                <img style={{ height: '28px' }} onClick={this.getVerifyCode} src={this.state.verifyImgSrc} alt="verify code"></img>
              }
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}