
import UserDetails from 'pages/history/calculator/details';
import RoleLists from 'pages/user-manage/role/index'
import Administrator from 'pages/user-manage/manage-user/index'

import { SubscriptionRoute } from './subscription'

import Welcome from "pages/welcome/index";
import NotFound from "pages/not-found/404";
import NotAllow from "pages/not-found/403";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { AuditRoute } from "./audit"
import { SystemRoute } from './system';
import { TemplateRoute } from './template';
import { TenantRoute } from './tenant';

export interface RouteType {
  exact?: boolean;
  path: string;
  redirectPath?: string;
  name?: string;
  icon?: string;
  component?:
  | React.ComponentType<any>
  | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
  | undefined;
  moduleCode?: string;
  route?: Array<RouteType>;
  breadList?: Array<string>;
  hidden?: boolean
}

const routes: Array<RouteType> = [
  {
    exact: true,
    path: "/",
    redirectPath: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "HomeOutlined",
    moduleCode: 'dashboard',
    component: Welcome,
    breadList: ["Dashboard"],
  },
  {
    name: 'Users',
    icon: 'TeamOutlined',
    path: '/user-manager',
    moduleCode: 'm.bsUserMg',
    route: [
      {
        path: '/user-manager/manage-list',
        name: 'Administrator',
        moduleCode: 'm.bsUserMg.bsUserList',
        component: Administrator,
        breadList: ['Users', 'Administrator'],
      },
      {
        path: '/user-manager/user-details',
        name: 'user-detail',
        moduleCode: 'm.bsUserMg.bsUserList',
        component: UserDetails,
        hidden: true,
        breadList: ['Users', 'User', "Details"],
      },
      {
        path: '/user-manager/user-role',
        name: 'User Role',
        moduleCode: 'm.bsUserMg.bsUserList',
        component: RoleLists,
        breadList: ['Users', 'User Role'],
      },
    ],
  },
  ...TenantRoute,
  ...TemplateRoute,
  ...AuditRoute,
  ...SubscriptionRoute,
  ...SystemRoute,
  {
    path: "/404",
    component: NotFound,
  },
  {
    path: "/403",
    component: NotAllow,
  },
];

export default routes;
