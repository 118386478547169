import { Form, FormInstance, Modal, notification } from "antd";
import React from "react";
import { APostModifyClaims } from "services/v1/infra-role";
import PermissionSelect from "./permission";

export interface ModifyPermissionProps {
  visible: boolean
  id: number
  claims: string[]
  onClose: () => void
}

interface ModifyPermissionState {
  loading: boolean
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export default class ModifyPermission extends React.Component<ModifyPermissionProps, ModifyPermissionState> {
  state: ModifyPermissionState = {
    loading: false
  }

  formRef = React.createRef<FormInstance<{ claims: string[] }>>()

  onFinish = async () => {
    const data = await this.formRef.current?.validateFields()

    this.setState({
      loading: true
    })

    const res = await APostModifyClaims({
      roleId:this.props.id,
      claims: data?.claims
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Modify role permission success.'
      })
      
      this.props.onClose()
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <Modal
        title="Modify permisson"
        visible={this.props.visible}
        onCancel={this.props.onClose}
        onOk={this.onFinish}>
        <Form 
          ref={this.formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="Claims"
            name="claims"
            rules={request}
          >
            <PermissionSelect onChange={(value) => this.formRef.current?.setFieldsValue({ claims: value})} />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}