import React from "react";
import { CountBox, CountBoxProps } from './count-box'
import { AGetLists } from 'services/v1/statistics'

interface IState {
  countBoxProps: CountBoxProps
}

export class DashboardTenant extends React.Component<{}, IState> {
  state: IState ={
    countBoxProps: {
      lists: [
        { title: 'Group Count', count: 0 },
        { title: 'User Count', count: 0 },
      ]
    }
  }

  componentDidMount() {
    this.getCountLists()
  }

  getCountLists = async () => {
    const res = await AGetLists({})

    if (res.success) {
      const { groupCount, userCount } = res.data
      this.state.countBoxProps.lists = [
        { title: 'Group Count', count: groupCount },
        { title: 'User Count', count: userCount },
      ]
      this.setState({
        countBoxProps: this.state.countBoxProps
      })
    }
  }

  render() {
    return <CountBox {...this.state.countBoxProps} />
  }

}