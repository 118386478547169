import { Button, notification, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { APostLists } from "services/v1/sup";
import RoleForm from "./form"


interface ManageRoleState {
  manageUserLists: Api.V1Component.SupUserVo[],
  listFormData: Api.V1Sup.APostLists.Request
  loading: boolean
  showForm: boolean
  total: number
}

export default class ManageRole extends React.Component<any, ManageRoleState> {
  state: ManageRoleState = {
    manageUserLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    showForm: false,
    total: 0
  }
  
  componentWillMount() {
    this.getManageUserLists()
  }

  getManageUserLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await APostLists(listFormData)
    
    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        manageUserLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns:ColumnsType<Api.V1Component.InfraRolesVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1
    },
    { title: 'User Name', dataIndex: 'userName' },
    { title: 'Email', dataIndex: 'email' },
    { title: 'Phone Number', dataIndex: 'phoneNumber' }
  ]

  onFormClose = () => {
    this.setState({
      showForm: false
    })

    this.getManageUserLists()
  }

  renderTable() {
    return (
      <>
        <div style={{ marginBottom: '16px' }}>
          <Button onClick={() => this.setState({ showForm: true })} type="primary">Add</Button>
        </div>
        <Table
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.manageUserLists}
          pagination={{
            onChange: this.getManageUserLists,
            total: this.state.total,
            pageSize: this.state.listFormData.pageSize,
            current: this.state.listFormData.pageIndex,
            size: "small"
          }}></Table>
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.showForm ? <RoleForm onClose={this.onFormClose} /> : this.renderTable()}
      </>
    )
  }
}