import React from 'react';
import { connect } from 'react-redux';
import { DashboardAdministrator } from './administrator'
import { DashboardTenant } from './tenant'

interface DashboardProps {
    /** 当前登录的用户名 */
    username?: string;
}

class AgentList extends React.Component<DashboardProps, {}> {

    render() {
        return (
            <>
            {this.props.username === 'admin' || this.props.username === 'digital_super_user' ? <DashboardAdministrator /> : <DashboardTenant />}
            </>
        )
    }
}

export default connect((store: any) => {
    return {
        username: store.username
    }
})(
    AgentList
);