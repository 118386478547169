import { RouteType } from "./router-config"

import { Template } from 'pages/template/manage/index'

export const TemplateRoute: RouteType[] = [
  {
    name: 'Template',
    icon: 'BarsOutlined',
    path: '/template',
    moduleCode: 'template',
    route: [
      {
        path: '/template/manage',
        name: 'Manage',
        moduleCode: 'template',
        component: Template,
        breadList: ['Template', 'Manage'],
      }
    ]
  }
]