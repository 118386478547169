import { Form, FormInstance, Input, Modal, notification } from "antd";
import React from "react";
import { APutConfigEdit } from "services/v1/config";

export interface ModifyConfigProps {
  visible: boolean
  id: number
  onClose: (isRefresh: boolean) => void
}

interface ModifyConfigState {
  loading: boolean
}

interface FormData {
  value: string
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export class ModifyConfig extends React.Component<ModifyConfigProps, ModifyConfigState> {
  state: ModifyConfigState = {
    loading: false
  }

  formRef = React.createRef<FormInstance<FormData>>()

  onFinish = async () => {
    const data = await this.formRef.current?.validateFields()

    this.setState({
      loading: true
    })

    const res = await APutConfigEdit({
      id: this.props.id,
      value: data?.value
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Modify config success.'
      })
      
      this.props.onClose(true)
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <Modal
        title="Modify config"
        visible={this.props.visible}
        onCancel={() => this.props.onClose(false)}
        onOk={this.onFinish}>
        <Form 
          ref={this.formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="Value"
            name="value"
            rules={request}
          >
            <Input placeholder="Please enter value"/>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}