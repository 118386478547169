import { Button, notification, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { AGetConfigList } from "services/v1/config";
import { ModifyConfig, ModifyConfigProps } from './form'

interface SettingState {
  settingLists: Api.V1Component.SystemConfigVo[],
  listFormData: Api.V1Config.AGetConfigList.Request
  loading: boolean
  total: number,
  configProps: ModifyConfigProps
}

export default class Setting extends React.Component<any, SettingState> {
  state: SettingState = {
    settingLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    total: 0,
    configProps: {
      id: 0,
      visible: false,
      onClose: (isRefresh) => {
        this.setState({
          configProps: {
            ...this.state.configProps,
            visible: false
          }
        })
        if (isRefresh) {
          this.getSettingLists()
        }
      }
    }
  }
  
  componentWillMount() {
    this.getSettingLists()
  }

  getSettingLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await AGetConfigList(listFormData)
    
    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        settingLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns:ColumnsType<Api.V1Component.SystemConfigVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1,
      align: 'center'
    },
    {
      title: 'Config Name',
      render: (_val, record) => record.configName
    },
    {
      title: 'Config Value',
      render: (_val, record) => record.configValue
    },
    {
      title: 'Config Description',
      render: (_val, record) => record.configDescription
    },
    {
      title: 'Options',
      render: (_val, record) => {
        return (
          <Space>
            <Button onClick={() => this.modifyConfig(record.id)} type="link">Modify</Button>
          </Space>
        )
      }
    }
  ]

  modifyConfig = (id: number) => {
    this.setState({
      configProps: {
        ...this.state.configProps,
        visible: true,
        id
      }
    })
  }

  renderTable() {
    return (
      <>
        <Table
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.settingLists}
          pagination={{
            onChange: this.getSettingLists,
            total: this.state.total,
            pageSize: this.state.listFormData.pageSize,
            current: this.state.listFormData.pageIndex,
            size: "small"
          }}></Table>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderTable()}
        <div><ModifyConfig {...this.state.configProps} key={this.state.configProps.id}/></div>
      </>
    )
  }
}