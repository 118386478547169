import { Form, FormInstance, Input, Modal, notification } from "antd";
import React from "react";
import { APostModifyPassWord } from "services/v1/infra-mg";

export interface ModifyPasswordProps {
  visible: boolean
  id: number
  onClose: () => void
}

interface ModifyPasswordState {
  loading: boolean
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export class ModifyPassword extends React.Component<ModifyPasswordProps, ModifyPasswordState> {
  state: ModifyPasswordState = {
    loading: false
  }

  formRef = React.createRef<FormInstance<Api.V1InfraMg.APostModifyPassWord.Request>>()

  onFinish = async () => {
    const data = await this.formRef.current?.validateFields()

    this.setState({
      loading: true
    })

    const res = await APostModifyPassWord({
      id: this.props.id,
      coverPassWord: data?.coverPassWord
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Modify password success.'
      })
      
      this.props.onClose()
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <Modal
        title="Modify password"
        visible={this.props.visible}
        onCancel={this.props.onClose}
        onOk={this.onFinish}>
        <Form 
          ref={this.formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="Cover PassWord"
            name="coverPassWord"
            rules={request}
          >
            <Input placeholder="Please enter password"/>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}