import {notification, Table, Tabs} from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { AGetSupLogs, AGetSystemConfigLogs, AGetTemplateLogs } from "services/v1/audit";
import { dateFormat } from "utils";

interface AudtiLogsState {
  tabs: {
    name: string
    loading: boolean
    logs: Api.V1Component.SupLogVo[]
    listFormData: Api.V1Audit.AGetSupLogs.Request
    total: number,
    getLogs: (params: Api.V1Audit.AGetSupLogs.Request) => Promise<Api.V1Audit.AGetSupLogs.Response>
  }[]
  currentIndex: number
}

export default class AuditLogs extends React.Component<{}, AudtiLogsState> {
  state: AudtiLogsState = {
    tabs: [],
    currentIndex: 0
  }

  componentDidMount() {
    // init tabs data
    this.setState({
      tabs: [
        { name: 'Account Logs', api: AGetSupLogs },
        { name: 'Template Logs', api: AGetTemplateLogs },
        { name: 'System Config Logs', api: AGetSystemConfigLogs },
      ].map(item => {
        return {
          name: item.name,
          loading: false,
          logs: [],
          listFormData: {
            pageIndex: 1,
            pageSize: 10
          },
          total: 0,
          getLogs: item.api
        }
      })
    }, () => {
      this.getLogsLists(this.state.currentIndex)
    })
  }

  getLogsLists = async (index: number, page?: number) => {
    const currentTabs = this.state.tabs[index]

    currentTabs.listFormData = {
      ...currentTabs.listFormData,
      pageIndex: page || currentTabs.listFormData.pageIndex
    }

    currentTabs.loading = true
    
    this.setState({
      tabs: this.state.tabs
    })

    const res = await currentTabs.getLogs(currentTabs.listFormData)

    currentTabs.loading = false

    this.setState({ tabs: this.state.tabs })

    if (res.success) {
      currentTabs.logs = res.data.values || []
      currentTabs.total = res.data.total
      this.setState({ tabs: this.state.tabs })
    } else {
      notification.error({
        message: res.message
      })
    }

  }
  
  columns:ColumnsType<Api.V1Component.SupLogVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1,
      align: 'center',
      width: 50
    },
    {
      title: 'Creator',
      render: (_val, record) => record.creator,
      width: '100px',
      align: 'center'
    },
    {
      title: 'Create Time',
      render: (_val, record) => dateFormat(record.createTime),
      width: '150px',
      align: 'center'
    },
    {
      title: 'Action',
      render: (_val, record) => record.action,
      width: '150px',
      align: 'center'
    },
    {
      title: 'Action Result',
      render: (_val, record) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{record.actionResult}</div>
    }
  ]

  renderTable = (index: number) => {
    const currentTabs = this.state.tabs[index]
    return (
      <Table
      loading={currentTabs.loading}
      columns={this.columns}
      dataSource={currentTabs.logs}
      pagination={{
        onChange: (page) => { this.getLogsLists(index, page) },
        total: currentTabs.total,
        pageSize: currentTabs.listFormData.pageSize,
        current: currentTabs.listFormData.pageIndex,
        size: "small"
      }}></Table>
    )
  }

  onChangeTabs = (key: string) => {
    const index = Number(key)
    if (isNaN(index)) return

    const currentTabs = this.state.tabs[index]

    if (currentTabs.logs.length === 0) {
      this.getLogsLists(index)
    }
  }

  render() {
    const { tabs, currentIndex } = this.state
    return (
      <Tabs defaultActiveKey={currentIndex.toString()} onChange={this.onChangeTabs}>
        {
          tabs.map((item, index) => {
            return <Tabs.TabPane tab={item.name} key={index.toString()}>{ this.renderTable(index) }</Tabs.TabPane>
          })
        }
      </Tabs>
    )
  }
  
}