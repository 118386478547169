export const formRequest = [{ required: true }]

export const validateMessages = {
  required: `'\${label}' Is a required field`,
};

export const formCol = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

export const formBtnCol = {
  wrapperCol: { offset: 8, span: 16 }
}