import request from "../../utils/request";

/**
** api name: Rate
** api address: /api/Rate/Create
** request mode: POST
** interface description: 新增商户相关的Rate
*/
export function APostCreate (params: Api.V1Rate.APostCreate.Request) {
  return request<Api.V1Rate.APostCreate.Response>(
    `/api/Rate/Create`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: Rate
** api address: /api/Rate/Lists
** request mode: GET
** interface description: 获取默认的Rate值
*/
export function AGetLists (params: Api.V1Rate.AGetLists.Request) {
  return request<Api.V1Rate.AGetLists.Response>(
    `/api/Rate/Lists`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Rate
** api address: /api/Rate/TenantRateLists
** request mode: GET
** interface description: 获取商户相关的Rate List
*/
export function AGetTenantRateLists (params: Api.V1Rate.AGetTenantRateLists.Request) {
  return request<Api.V1Rate.AGetTenantRateLists.Response>(
    `/api/Rate/TenantRateLists`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Rate
** api address: /api/Rate/Edit
** request mode: PUT
** interface description: 修改商户Rate
*/
export function APutEdit (params: Api.V1Rate.APutEdit.Request) {
  return request<Api.V1Rate.APutEdit.Response>(
    `/api/Rate/Edit`,
    {
      method: "PUT",
      data: params,
    }
  );
}
        
/**
** api name: Rate
** api address: /api/Rate/Detail/{id}
** request mode: GET
** interface description: 获取商户Rate详情
*/
export function AGetDetail_Id (params: Api.V1Rate.AGetDetail_Id.Request) {
  return request<Api.V1Rate.AGetDetail_Id.Response>(
    `/api/Rate/Detail/${params.id}`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Rate
** api address: /api/Rate/Delete/{id}
** request mode: DELETE
** interface description: 删除商户Rate
*/
export function ADeleteDelete_Id (params: Api.V1Rate.ADeleteDelete_Id.Request) {
  return request<Api.V1Rate.ADeleteDelete_Id.Response>(
    `/api/Rate/Delete/${params.id}`,
    {
      method: "DELETE",
      data: params,
    }
  );
}
        
/**
** api name: Rate
** api address: /api/Rate/EnabledSet
** request mode: POST
** interface description: Rate状态设置
*/
export function APostEnabledSet (params: Api.V1Rate.APostEnabledSet.Request) {
  return request<Api.V1Rate.APostEnabledSet.Response>(
    `/api/Rate/EnabledSet`,
    {
      method: "POST",
      data: params,
    }
  );
}