import React from "react";
import { CountBox, CountBoxProps } from './count-box'
import { AGetCreate } from 'services/v1/statistics'

interface IState {
  countBoxProps: CountBoxProps
}

export class DashboardAdministrator extends React.Component<{}, IState> {
  state: IState ={
    countBoxProps: {
      lists: [
        { title: 'Tenant Count', count: 0 },
        { title: 'Tenant Administrator Count', count: 0 },
        { title: 'Group Count', count: 0 },
        { title: 'User Count', count: 0 },
      ]
    }
  }

  componentDidMount() {
    this.getCountLists()
  }

  getCountLists = async () => {
    const res = await AGetCreate({})

    if (res.success) {
      const { groupCount, tenantCount, tenantManageCount, userCount } = res.data
      this.state.countBoxProps.lists = [
        { title: 'Tenant Count', count: tenantCount },
        { title: 'Tenant Administrator Count', count: tenantManageCount },
        { title: 'Group Count', count: groupCount },
        { title: 'User Count', count: userCount },
      ]
      this.setState({
        countBoxProps: this.state.countBoxProps
      })
    }
  }

  render() {
    return <CountBox {...this.state.countBoxProps} />
  }

}