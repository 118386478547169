import { Select } from 'antd'
import React from 'react'
import { AGetHsCodes } from 'services/v1/data-dic'

interface HS_CodeState {
  loading: boolean
  lists: Api.V1Component.HsCodeVo[]
  disable: boolean
}

interface HS_CodeProps {
  value?: string
  onChange?: (val?: string) => void
  podCountry?: string
}

export class HSCode extends React.Component<HS_CodeProps, HS_CodeState> {

  state:HS_CodeState = {
    loading: false,
    lists: [],
    disable: false
  }

  componentWillReceiveProps(nextProps: HS_CodeProps) {
    if (!nextProps.podCountry && nextProps.podCountry !== this.props.podCountry) {
      this.props.onChange && this.props.onChange('')
    }
    this.getLists('', nextProps.podCountry)
  }


  getLists = async (keyword?: string, podCountry?: string) => {
    this.setState({
      loading: true
    })
    const res = await AGetHsCodes({
      pageIndex: 1,
      pageSize: 100,
      keyword,
      podCountry: podCountry || this.props.podCountry
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        lists: res.data.values || []
      })
    }
  }

  timer: any = null
  onSearch = (val: string) => {
    clearInterval(this.timer)

    this.timer = setTimeout(() => {
      this.getLists(val)
    }, 500)
  }

  render() {
    return (
      <div>
        <Select<string>
          value={this.props.value}
          disabled={this.state.disable}
          showArrow
          filterOption
          showSearch
          onChange={(val) => this.props.onChange && this.props.onChange(val)}
          onSearch={this.onSearch}
          loading={this.state.loading}
          onFocus={() => this.state.lists.length === 0 && this.getLists()}
          allowClear
        >
          { this.state.lists.map(item => <Select.Option value={item.hsCode || ''} key={item.description}>{`${item.hsCode}-${item.description}`}</Select.Option>) }
        </Select>
      </div>
    )
  }
}
