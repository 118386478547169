import request from "../../utils/request";

/**
** api name: Config
** api address: /api/Config/ConfigList
** request mode: GET
** interface description: 配置信息列表
*/
export function AGetConfigList (params: Api.V1Config.AGetConfigList.Request) {
  return request<Api.V1Config.AGetConfigList.Response>(
    `/api/Config/ConfigList`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Config
** api address: /api/Config/ConfigEdit
** request mode: PUT
** interface description: 编辑配置项
*/
export function APutConfigEdit (params: Api.V1Config.APutConfigEdit.Request) {
  return request<Api.V1Config.APutConfigEdit.Response>(
    `/api/Config/ConfigEdit`,
    {
      method: "PUT",
      data: params,
    }
  );
}
        
