import { RouteType } from "./router-config"

import SubscriptionLists from 'pages/subscription/lists/index'

export const SubscriptionRoute: RouteType[] = [
  {
    name: 'Subscription',
    icon: 'DeploymentUnitOutlined',
    path: '/subscription',
    moduleCode: 'subscription',
    route: [
      {
        path: '/subscription/effective',
        name: 'Effective',
        moduleCode: 'subscription.subscriptionList',
        component: SubscriptionLists,
        breadList: ['Subscription', 'Effective'],
      },
      {
        path: '/subscription/archive',
        name: 'Archive',
        moduleCode: 'subscription.subscriptionList',
        component: SubscriptionLists,
        breadList: ['Subscription', 'Archive'],
      },
      {
        path: '/subscription/my',
        name: 'MySubscription',
        moduleCode: 'subscription.mySubscription',
        component: SubscriptionLists,
        breadList: ['Subscription', 'My Subscription'],
      },
      {
        path: '/subscription/available',
        name: 'AvailableSubscription',
        moduleCode: 'subscription.availableSubscriptionList',
        component: SubscriptionLists,
        breadList: ['Subscription', 'Available'],
      },
    ]
  }
]