import { AGetDetails } from "services/v1/account";

/** 获取权限 */
export const fetchPermissionData = async (dispatch: any) => {
  dispatch({
    type: 'layoutLoading',
    data: true
  })
  const res = await AGetDetails({});
  
  if (res.success) {
    //   const permissList = res.data.claims.filter((item) => item.type[0] === 'm');
      const permissions = res.data.claims?.map(item => item.value)
    //   const claimsList = res.data.claims.filter((item) => item.type[0] === 'p');
      // const claims = res.data.claims?.map(item => item.value)
    dispatch({
      type: "updatePermission",
      data: { claims: [], permissions: permissions, username: res.data.userName, tenantName: res.data.tenantName }
    });
    setTimeout(() => {
      dispatch({
        type: 'layoutLoading',
        data: false
      })
    }, 300)
  } else {
    dispatch({
      type: "updatePermission",
    });
    dispatch({
      type: 'layoutLoading',
      data: false
    })
  }
};

const Setting = (
  state = {
    collapsed: false,
    pageTitle: "",
    breadList: [],
    pageClaims: [],
    btnClaims: [],
    username: "",
    tenantName: "",
    layoutLoading: true
  },
  action: any
) => {
  switch (action.type) {
    case "layoutLoading":
      return { ...state, layoutLoading: action.data };
    case "menuCollapsed":
      return { ...state, collapsed: action.data };
    case "pageTitle":
      return { ...state, pageTitle: action.data };
    case "breadList":
      return { ...state, breadList: action.data };
    case "updatePermission":
      if (!action.data) {
        window.localStorage.removeItem('userToken');
        window.location.replace(
          `/login?redirect=${encodeURIComponent(window.location.pathname)}`
        );
      }
      return {
        ...state,
        pageClaims: action.data.permissions,
        btnClaims: action.data.claims,
        username: action.data.username,
        tenantName: action.data.tenantName,
      };
    default:
      return state;
  }
};

export default Setting;
