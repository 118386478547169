import { AGetSlideCode_Token } from "services/v1/general"

const createRandom = function(e: number): string {    
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
  a = t.length,
  n = "";
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n
}

const getVerifyCode = function(): Promise<{ imgSrc: string, random: string }> {
  return new Promise(async (resolve) => {
    const random = createRandom(32)

    const res = await AGetSlideCode_Token({ token: random })

    if (res.success) {
      resolve({
        imgSrc: 'data:image/png;base64,' + res.data.image || '',
        random: random
      })
    }
  })
}

export default getVerifyCode