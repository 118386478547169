import { Modal, Form, Input, Space, FormInstance, notification } from "antd";
import React from "react";
import { APostChangePassWord } from "services/v1/account";
import GetVerifyCode from "../utils/verify-code"

interface ModifyPasswordProps {
  showModel: boolean
  onClose: () => void
}

interface ModifyPasswordState {
  loading: boolean
  verifyImgSrc: string
  random: string
}


const request = [{ required: true }];

const validateMessages = {
    required: `'\${label}' Is a required field`,
};

export default class ModifyPassword extends React.Component<ModifyPasswordProps, ModifyPasswordState> {
  state: ModifyPasswordState = {
    loading: false,
    verifyImgSrc: '',
    random: ''
  }

  componentDidMount () {
    this.getVerifyCode()
  }

  formRef = React.createRef<FormInstance<Api.V1Component.ChangePasswordByUserIdPo>>()

  sendData = async () => {
    const data = await this.formRef.current?.validateFields()

    this.setState({
      loading: true
    })
    const res = await APostChangePassWord({
      verifyCode: data?.verifyCode,
      slideProof: this.state.random,
      coverPassWord: data?.coverPassWord
    })

    this.setState({
      loading: false
    })
    
    if (res.success) {
      notification.success({
        message: 'Modify password success'
      })
      this.props.onClose()
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  getVerifyCode = async() => {
      const data = await GetVerifyCode()
      this.setState({
          verifyImgSrc: data.imgSrc,
          random: data.random
      })
  }

  render () {
    return (
      <>
        <Modal
          title="Modify Password"
          visible={this.props.showModel}
          onCancel={this.props.onClose} onOk={this.sendData}
          confirmLoading={this.state.loading}>
          <Form
            ref={this.formRef}
            size="large"
            name="loginForm"
            labelCol={{ span: 24 }}
            validateMessages={validateMessages}
            >
            <Form.Item label="New Password" name="coverPassWord" rules={request}>
              <Input placeholder="Please input the new password" />
            </Form.Item>
            <Form.Item label="Verify Code" name="verifyCode" rules={request}>
              <Space>
                  <Input placeholder="input the verify code" />
                  { this.state.verifyImgSrc &&
                    <img style={{ height: '31.78px' }} onClick={this.getVerifyCode} src={this.state.verifyImgSrc} alt="verify code"></img>
                  }
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}