import { Button, Form, Input, Space } from "antd";
import React from "react";

export interface Step2Props {
  commonConfig: Api.V1Component.ConstantConfigurationVo
  onNext: (value: FormData) => void
  onBack: () => void
  defaultValue?: FormData
}

interface Step2State {
  commericalValue: string
  finalDelivery: string
}

interface FormData {
  commericalValue?: string
  finalDelivery?: string
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export class TemplateStep2 extends React.Component<Step2Props, Step2State> {

  constructor(props: Step2Props) {
    super(props)
    let reg = ''

    for (const key of Object.keys(this.props.commonConfig)) {
      reg += `${key}|`
    }

    reg = reg.slice(0, reg.length - 1)

    this.reg = new RegExp(reg, 'g')

    this.state.commericalValue = this.props?.defaultValue?.commericalValue || ''
    this.state.finalDelivery = this.props?.defaultValue?.finalDelivery || ''
  }

  state:Step2State = {
    commericalValue: '',
    finalDelivery: ''
  }

  reg:RegExp | null = null

  renderCodeTextarea = (value?: string) => {
    if (!this.reg || !value) return <div></div>
    const val = value.replace(this.reg, '<span style="color: #1890ff">$&</span>')
    return <div dangerouslySetInnerHTML={{__html: val}}></div>
  }

  valueChange = (_changedValues: any, values: FormData) => {
    this.setState({
      commericalValue: values?.commericalValue || '',
      finalDelivery: values?.finalDelivery || ''
    })
  }

  render() {
    return (
      <>
        <Form<FormData>
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          style={{width: '800px'}}
          validateMessages={validateMessages}
          onValuesChange={this.valueChange}
          onFinish={this.props.onNext}
          initialValues={this.props.defaultValue}
        >
          <Form.Item
            label="Commerical Value"
            name="commericalValue"
            rules={request}
          >
            <Input placeholder="Please enter value"/>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
            {this.renderCodeTextarea(this.state.commericalValue)}
          </Form.Item>

          <Form.Item
            label="Final Delivery"
            name="finalDelivery"
            rules={request}
          >
            <Input placeholder="Please enter value"/>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
            {this.renderCodeTextarea(this.state.finalDelivery)}
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
            <Space>
              <Button onClick={() => this.props.onBack()}>Back</Button>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </>
    )
  }
}