import React from 'react';
import { message, Modal, Table } from 'antd';
import { AGetUser_UserId } from 'services/user';
import { GetUrlParams } from 'utils';
import { ColumnsType } from 'antd/lib/table';

interface StaffDetailProps {
  onCancel: () => void;
  userId: number;
}

interface StaffDetailState {
  pageLoading: boolean;
  userDetailsInfo?: Api.UserComponent.UserDetailVo;
  inputs: Array<any>,
  summaries: Array<any>,
  breakdowns: Array<any>
}

export default class StaffDetail extends React.Component<StaffDetailProps, StaffDetailState> {
  state: StaffDetailState = {
    pageLoading: false,
    inputs: [],
    summaries: [],
    breakdowns: []
  };

  inputColumns: ColumnsType<any> = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '0.5%',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: () => { return <span>Item,<br />material description</span> },
      dataIndex: 'itemDescription',
      key: 'itemDescription',
      align: 'center',
      width: '10%',
      className: 'tableFontSize'
    },
    {
      title: 'Buying INCOTERM',
      dataIndex: 'buyingIncoterm',
      key: 'buyingIncoterm',
      align: 'center',
      width: '5%',
      render: (text, record) => {
        return record.termSelection == null ? '' : record.termSelection.split('-')[0]
      }
    },
    {
      title: 'Palletisation before delivery?',
      dataIndex: 'palletisation',
      key: 'palletisation',
      align: 'center',
      width: '10%',
      render: (text, record) => {
        return record.palletization;
      }
    },
    {
      title: 'Port of Departure',
      dataIndex: 'originPol',
      key: 'originPol',
      align: 'center',
      width: '8%',
      className: 'tableFontSize'
    },
    {
      title: 'Delivery Location',
      dataIndex: 'deliveryLocation',
      key: 'deliveryLocation',
      align: 'center',
      width: '8%',
      className: 'tableFontSize'
    },
    {
      title: 'Port of Arrival',
      dataIndex: 'destinationPod',
      key: 'destinationPod',
      align: 'center',
      width: '8%',
      className: 'tableFontSize'
    },
    {
      title: 'HS Code',
      dataIndex: 'hsCode',
      key: 'hsCode',
      align: 'center',
      width: '4%',
      className: 'tableFontSize'
    },
    {
      title: () => {
        return <span>Unit Price <br />(USD)</span>
      },
      dataIndex: 'newUnitPrice',
      key: 'newUnitPrice',
      align: 'center',
      width: '6%',
      className: 'tableFontSize'
    },
    {
      title: 'GSC Commission%',
      dataIndex: 'gscCommission',
      key: 'gscCommission',
      align: 'center',
      width: '5%',
      className: 'tableFontSize'
    },
    {
      title: 'Pieces QTY',
      dataIndex: 'piecesQTY',
      key: 'piecesQTY',
      align: 'center',
      width: '6%',
      render: (text, record) => {
        return record.indicateQuantity;
      }
    },
    {
      title: 'Selling INCOTERM',
      dataIndex: 'sellingIncoterm',
      key: 'sellingIncoterm',
      align: 'center',
      width: '5%',
      render: (text, record) => {
        return record.termSelection == null ? '' : record.termSelection.split('-')[1]
      }
    },
    {
      title: () => { return <span>Carton Measurement <br /> (L x W x H)(cm)</span> },
      dataIndex: 'cartonMeasurement',
      key: 'cartonMeasurement',
      align: 'center',
      width: '13%',
      render: (text, record) => {
        return record.length + 'x' + record.width + 'x' + record.height;
      }
    },
    {
      title: () => { return <span>Pieces <br /> p/ Carton</span> },
      dataIndex: 'piecesPCarton',
      key: 'piecesPCarton',
      align: 'center',
      width: '5%',
      render: (text, record) => {
        return record.pcsCarton;
      }
    },
    {
      title: 'CTN GW',
      dataIndex: 'ctnGw',
      key: 'ctnGw',
      align: 'center',
      width: '4%',
      render: (text, record) => {
        return record.weight;
      }
    }
  ]

  summaryColumns: ColumnsType<any> = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'indx',
      align: 'center',
      width: '0.5%',
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: () => { return <span>Item,<br />material description</span> },
      dataIndex: 'itemDescription',
      key: 'itemDescription',
      align: 'center',
      width: '10%'
    },
    {
      title: 'Commerical Value',
      dataIndex: 'commericalValue',
      key: 'commericalValue',
      align: 'center',
      width: '4%'
    },
    {
      title: 'CTN CBM',
      dataIndex: 'ctnCbm',
      key: 'ctnCbm',
      align: 'center',
      width: '6%'
    },
    {
      title: 'Total CTNs',
      dataIndex: 'totalCtns',
      key: 'totalCtns',
      align: 'center',
      width: '5%'
    },
    {
      title: 'Total CBM',
      dataIndex: 'totalCbm',
      key: 'totalCbm',
      align: 'center',
      width: '6%'
    },
    {
      title: 'Total Gross Weight KG',
      dataIndex: 'totalGrossWeightKg',
      key: 'totalGrossWeightKg',
      align: 'center',
      width: '5%'
    },
    {
      title: 'Total Shipping excl. Duty + Tax',
      dataIndex: 'totalShippingExclDutyPlusTax',
      key: 'totalShippingExclDutyPlusTax',
      align: 'center',
      className: 'specialth',
      width: '8%'
    },
    {
      title: 'Total Duty & Tax Outlay',
      dataIndex: 'totalDutyAndTaxOutlay',
      key: 'totalDutyAndTaxOutlay',
      align: 'center',
      className: 'specialth',
      width: '8%'
    },
    {
      title: 'Total Shippingcosts incl. Duty + Tax',
      dataIndex: 'totalShippingCostsInclDutyPlusTax',
      key: 'totalShippingCostsInclDutyPlusTax',
      align: 'center',
      className: 'specialth',
      width: '10%'
    },
    {
      title: 'Freight and Duty per piece',
      dataIndex: 'freightAndDutyPerPiece',
      key: 'freightAndDutyPerPiece',
      align: 'center',
      className: 'specialth',
      width: '5%'
    },
    {
      title: 'DDP Price p/piece',
      dataIndex: 'ddpPricePPiece',
      key: 'ddpPricePPiece',
      align: 'center',
      className: 'specialth',
      width: '5%'
    },
    {
      title: 'Freight + Duty %',
      dataIndex: 'freightPlusDutyPercent',
      key: 'freightPlusDutyPercent',
      align: 'center',
      className: 'specialth',
      width: '6%',
      render: (text) => {
        return ((text * 10000) / 100.0).toFixed(1) + '%'
      }
    }
  ]

  breakdownColumns: ColumnsType<any> = [
    {
      title: '',
      dataIndex: '',
      key: '',
      align: 'center',
      width: '0.5%',
      className: 'parentCol',
      children: [{
        title: 'No.',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        render: (text, record, index) => {
          return index + 1;
        }
      },
      {
        title: 'Mode',
        dataIndex: 'mode',
        key: 'mode',
        align: 'center',
        width: '2%'
      }]
    },
    {
      title: 'Freight',
      dataIndex: 'itemDescription',
      key: 'itemDescription',
      align: 'center',
      width: '10%',
      className: 'parentCol',
      children: [
        {
          title: 'LCL Cost',
          dataIndex: 'lclCost',
          key: 'lclCost',
          align: 'center',
          width: '3%'
        },
        {
          title: 'LCL (CBM)',
          dataIndex: 'numLcl',
          key: 'numLcl',
          align: 'center',
          width: '3%'
        },
        {
          title: '20ft Freight Cost',
          dataIndex: 'twentyCost',
          key: 'twentyCost',
          align: 'center'
        },
        {
          title: '20ft QTY',
          dataIndex: 'twentyGpContainer',
          key: 'twentyGpContainer',
          align: 'center'
        },
        {
          title: '40HC Freight Cost',
          dataIndex: 'fortyCost',
          key: 'fortyCost',
          align: 'center',
          width: '4%'
        },
        {
          title: '40HC QTY',
          dataIndex: 'fortyGpContainer',
          key: 'fortyGpContainer',
          align: 'center',
          width: '3%'
        }]
    },
    {
      title: 'Destination Charges',
      dataIndex: 'destinationcharges',
      key: 'destinationcharges',
      align: 'center',
      width: '5%',
      className: 'parentCol',
      children: [{
        title: 'Documentation at Destination',
        dataIndex: 'documentation',
        key: 'documentation',
        align: 'center'
      },
      {
        title: 'Destination THC, incl ISP',
        dataIndex: 'thc',
        key: 'thc',
        align: 'center'
      },
      {
        title: 'Delivery to Consignee',
        dataIndex: 'warehouseDelivery',
        key: 'warehouseDelivery',
        align: 'center',
        width: '4%'
      },
      {
        title: 'Handling Charges',
        dataIndex: 'handling',
        key: 'handling',
        align: 'center',
        width: '6%'
      },
      {
        title: 'Customs Clearance',
        dataIndex: 'clearance',
        key: 'clearance',
        align: 'center',
        width: '6%'
      },
      {
        title: 'D/O Fee',
        dataIndex: 'doFee',
        key: 'doFee',
        align: 'center',
        width: '3%'
      }]

    },
    {
      title: 'OVAS or DVAS if required',
      dataIndex: 'itemDescription',
      key: 'itemDescription',
      align: 'center',
      width: '10%',
      className: 'parentCol',
      children: [{
        title: 'Warehousing Services incl. Palletization if requested',
        dataIndex: 'warehouse',
        key: 'warehouse',
        align: 'center',
        render: (text) => {
          return text === -1 ? '' : text
        }
      },
      {
        title: 'Final Delivery to requested destination',
        dataIndex: 'delivery',
        key: 'delivery',
        align: 'center',
        render: (text) => {
          return text === -1 ? '' : text
        }
      }]
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      align: 'center',
      children: [{
        title: 'Insurance',
        dataIndex: 'insurance',
        key: 'insurance',
        align: 'center'
      }]
    },
    {
      title: 'TAX',
      dataIndex: 'tax',
      key: 'tax',
      align: 'center',
      width: '10%',
      className: 'parentCol',
      children: [{
        title: 'DUTY %',
        dataIndex: 'dutyPercent',
        key: 'dutyPercent',
        align: 'center',
        width: '4%',
        render: (text) => {
          return ((text * 10000) / 100.0).toFixed(1) + '%'
        }
      },
      {
        title: 'DUTY',
        dataIndex: 'duty',
        key: 'duty',
        align: 'center',
        width: '3%'
      },
      {
        title: 'VAT/GST %',
        dataIndex: 'gstPercent',
        key: 'gstPercent',
        align: 'center',
        width: '5%',
        render: (text) => {
          return ((text * 10000) / 100.0).toFixed(1) + '%'
        }
      },
      {
        title: 'VAT if application',
        dataIndex: 'gst',
        key: 'gst',
        align: 'center',
        width: '3%'
      }]
    }
  ]

  componentDidMount() {
    this.getDetailsInfo();
  }

  componentWillUnmount() {
    this.setState = () => {
      return false;
    };
  }

  goBack = () => {
    const urlId = GetUrlParams({ key: 'userId' });
    if (urlId) {
      window.history.go(-1)
    } else {
      this.props.onCancel();
    }
  };

  /** 获取门店详情 */
  getDetailsInfo = async () => {
    const urlId = GetUrlParams({ key: 'userId' });
    if (urlId || this.props.userId) {
      this.setState({
        pageLoading: true,
      });
      const res = await AGetUser_UserId({ id: urlId ? Number(urlId) : this.props.userId });
      if (res.success) {
        const temp1: Array<any> = [];
        const temp2: Array<any> = [];
        const temp3: Array<any> = [];

        res.data.values.forEach(item => {
          temp1.push(item.inputs);
          temp2.push(item.summaries);
          temp3.push(item.breakDowns);
        });
        this.setState({
          inputs: temp1,
          summaries: temp2,
          breakdowns: temp3
        })
      } else {
        this.setState({
          pageLoading: false,
        });
        message.error(res.message || '获取详情失败');
      }
    } else {
      this.goBack()
    }
  };

  render() {
    return (
      <Modal
        width='84%'
        title="Detail"
        keyboard={true}
        visible
        maskClosable={false}
        footer={false}
        onCancel={() => this.props.onCancel()}
      >
        <Table
          className="user-details-table"
          bordered
          size={'small'}
          columns={this.inputColumns}
          dataSource={this.state.inputs}
          pagination={false}
        ></Table>
        <Table
          className="user-details-table"
          bordered
          size={'small'}
          columns={this.summaryColumns}
          dataSource={this.state.summaries}
          pagination={false}
        ></Table>
        <Table
          className="user-details-table"
          bordered
          size={'small'}
          columns={this.breakdownColumns}
          dataSource={this.state.breakdowns}
          pagination={false}
        ></Table>
      </Modal>
    );
  }
}
