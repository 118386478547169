import { Select } from 'antd'
import React from 'react'
import { AGetPods } from 'services/v1/data-dic'
import { PalletizationRadioType } from './item'

interface PortOfArrivalState {
  loading: boolean
  lists: Api.V1Component.PodVo[]
  disable: boolean
}

interface PortOfArrivalProps {
  value?: string
  onChange?: (val?: string) => void
  onChangeCode?: (code?: string) => void
  palletization?: PalletizationRadioType['value']
}

export class PortOfArrival extends React.Component<PortOfArrivalProps, PortOfArrivalState> {

  state:PortOfArrivalState = {
    loading: false,
    lists: [],
    disable: false
  }

  componentDidMount() {
    this.getLists()
    this.checkStates()
  }

  componentWillReceiveProps(nextProps: PortOfArrivalProps) {
    if (nextProps.palletization !== this.props.palletization) {
      this.checkStates(nextProps.palletization)
    }
    if (nextProps.value !== this.props.value) {
      this.onChange(nextProps.value)
    }
  }

  checkStates = (palletization?: PortOfArrivalProps['palletization']) => {
    const p = palletization || this.props.palletization
    if (p !== 'No') {
      this.setState({
        disable: true
      })
      this.onChange()
    } else {
      this.setState({
        disable: false
      })
    }
  }

  getLists = async (keyword?: string) => {
    this.setState({
      loading: true
    })

    const res = await AGetPods({
      pageIndex: 1,
      pageSize: 1000,
      keyword: keyword
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        lists: res.data.values || []
      })
    }
  }

  onChange = (podName?: string) => {
    if (podName) {
      const { lists } = this.state
      for (let i = 0; i < lists.length; i++) {
        if (podName === lists[i].pod) {
          const temp = lists[i]
          this.props.onChange && this.props.onChange(temp.pod)
          this.props.onChangeCode && this.props.onChangeCode(temp.podCountry)
          break
        }
      }
    } else {
      this.props.onChange && this.props.onChange(undefined)
      this.props.onChangeCode && this.props.onChangeCode(undefined)
    }
  }

  render() {
    return (
      <div>
        <Select<string>
          value={this.props.value}
          disabled={this.state.disable}
          showArrow
          filterOption
          showSearch
          onChange={(val) => this.onChange(val)}
          loading={this.state.loading}
          allowClear
        >
          { this.state.lists.map(item => <Select.Option value={item.pod || ''} key={item.pod}>{item.pod}</Select.Option>) }
        </Select>
      </div>
    )
  }
}
