import { Button, Divider, Form, FormInstance, message, notification, PageHeader, Select, Space, Spin } from "antd";
import React from "react";
import { AGetRates } from "services/v1/data-dic";
import { AGetDetail_Id, APostCreate, APutEdit } from "services/v1/rate";
import { formBtnCol, formCol, formRequest, validateMessages } from "utils/form-config";
import { SaveButton } from "utils/theme";
import { TenantProps, TenantSelect } from "../../../components/tenant/select";
import { RateArgs } from './rate-args'

export interface RateFormProps {
  onClose: (isRefresh: boolean) => void,
  id?: number
  show: boolean
}

type Options = { label: string, value: string, key: string, rawData: Api.V1Component.RateVo }[]

interface RateFormState {
  loading: boolean
  initLoading: boolean
  defaultValus?: FormData
  defaultTenantValues?: TenantProps['defaultValue']
  options: Options
  tempRawCode: {
    countryCode?: string
    deliveryLocation?: string
    portOfArrival?: string
  }
}

interface FormData {
  tenantId?: number
  rawCode?: string
  rateArgs?: Api.V1Component.StringSingleKeyValuePair[]
}

export default class RateForm extends React.Component<RateFormProps, RateFormState> {
  constructor(props: RateFormProps) {
    super(props)
    if (this.props.id) {
      this.state.initLoading = true
      this.getRateDetail(this.props.id)
    }
  }

  componentDidMount() {
    this.getDataDic()
  }  

  state: RateFormState = {
    loading: false,
    initLoading: false,
    options: [],
    tempRawCode: {
      countryCode: '',
      deliveryLocation: '',
      portOfArrival: ''
    }
  }

  formRef = React.createRef<FormInstance<FormData>>()

  getRateDetail = async (id: number) => {
    this.setState({ initLoading: true })

    const res = await AGetDetail_Id({ id })

    if (res.success) {
      const data = res.data
      const countryCode = data.countryCode || ''
      const deliveryLocation = data.deliveryLocation || ''
      const portOfArrival = data.portOfArrival || ''
      this.setState({
        initLoading: false,
        defaultValus: {
          tenantId: id,
          rawCode: `${countryCode} - ${deliveryLocation} - ${portOfArrival}`,
          rateArgs: res.data.rateArgs
        },
        defaultTenantValues: [{
          label: data.tenantName || '',
          value: data.tenantId,
          key: data.tenantId
        }],
        tempRawCode: {
          countryCode: countryCode,
          deliveryLocation: deliveryLocation,
          portOfArrival: portOfArrival
        }
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  getDataDic = async () => {
    const res = await AGetRates({
      pageIndex: 1,
      pageSize: 1000
    })
    if (res.success) {
      const { options } = this.state
      options.splice(0, options.length)
      
      res.data.values?.forEach(item => {
        const countryCode = item.countryCode || ''
        const deliveryLocation = item.deliveryLocation || ''
        const portOfArrival = item.portOfArrival || ''
        const jointVal = `${countryCode} - ${deliveryLocation} - ${portOfArrival}`
        options.push({
          label: jointVal,
          value: jointVal,
          key: jointVal,
          rawData: item
        })
      })

      this.setState({
        options
      })
    }
  } 

  onFinish = async (formData: FormData) => {
    const { tempRawCode } = this.state
    this.setState({
      loading: true
    })

    let res: Api.V1Rate.APostCreate.Response | Api.V1Rate.APutEdit.Response | null = null
    const data = {
      tenantId: formData.tenantId,
      countryCode: tempRawCode.countryCode,
      deliveryLocation: tempRawCode.deliveryLocation,
      portOfArrival: tempRawCode.portOfArrival,
      rateArgs: formData.rateArgs?.map(item => {
        return {
          key: item.key,
          value: item.value
        }
      })
    }

    if (this.props.id) {
      res = await APutEdit({
        ...data,
        id: this.props.id
      })
    } else {
      res = await APostCreate(data)
    }

    this.setState({
      loading: false
    })

    if (res.success) {
      message.success('Operate success.')
      this.props.onClose(true)
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  onChangeFormCode = (value?: string) => {
    if (!value) {
      this.setState({
        tempRawCode: {}
      })
      return
    }

    const { options } = this.state
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        this.setState({
          tempRawCode: options[i].rawData
        })
        break
      }
    }
  }

  renderSelect() {
    const { options } = this.state
    return (
      <Select<string>
        placeholder="Please select"
        showSearch
        allowClear
        onChange={(val) => this.onChangeFormCode(val)}
        >
        { options.map(item => <Select.Option value={item.value} key={item.key}>{item.label}</Select.Option>) }
      </Select>
    )
  }

  renderForm = () => {
    const { defaultValus, defaultTenantValues, tempRawCode } = this.state
    return (
      <Form<FormData>
        name="RateForm"
        layout="vertical"
        {...formCol}
        onFinish={this.onFinish}
        autoComplete="off"
        style={{width: '1000px', paddingLeft: '30px', paddingBottom: '30px'}}
        validateMessages={validateMessages}
        initialValues={defaultValus}
        ref={this.formRef}
      >
        <Form.Item
          label="Tenant"
          name="tenantId"
          rules={formRequest}
        >
          <TenantSelect defaultValue={defaultTenantValues} onChange={(value) => this.formRef.current?.setFieldsValue({ tenantId: value ? value[0] : undefined })} />
        </Form.Item>

        <Form.Item
          label="CountryCode - DeliveryLocation - PortOfArrival"
          name="rawCode"
          rules={formRequest}
        >
          {this.renderSelect()}
        </Form.Item>
        
        <Form.Item
          label="Rate Args"
          name="rateArgs"
          rules={formRequest}
        >
          <RateArgs deliveryLocation={tempRawCode.deliveryLocation} />
        </Form.Item>

        <Form.Item {...formBtnCol}>
          <Space>
            <SaveButton loading={this.state.loading} type="primary" htmlType="submit">Submit</SaveButton>
            <Button onClick={() => this.props.onClose(false)}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    )
  }

  render() {
    return (
      <>
      <div style={{ width: '100%', background: '#fff' }}>
        <PageHeader onBack={() => this.props.onClose(false)} title={ this.props.id ? 'Edit' : 'Add' } subTitle="tenant rate" />
        <Divider />
        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && this.renderForm()}
        </Spin>
      </div>
      </>
    )
  }
} 