import request, { requestFile } from "../../utils/request";

/**
** api name: Calculator
** api address: /api/Calculator/History
** request mode: GET
** interface description: get the history
*/
export function AGetHistory (params: Api.V1Calculator.AGetHistory.Request) {
  return request<Api.V1Calculator.AGetHistory.Response>(
    `/api/Calculator/History`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Calculator
** api address: /api/Calculator/HistoryDetail
** request mode: GET
** interface description: get the history
*/
export function AGetHistoryDetail (params: Api.V1Calculator.AGetHistoryDetail.Request) {
  return request<Api.V1Calculator.AGetHistoryDetail.Response>(
    `/api/Calculator/HistoryDetail`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Calculator
** api address: /api/Calculator/ImportAdmData
** request mode: POST
** interface description: import the excel - adm data
*/
export function APostImportAdmData (formData: FormData) {
  return requestFile<Api.V1Calculator.APostImportAdmData.Response>(
    `/api/Calculator/ImportAdmData`,
    formData
  );
}
        
/**
** api name: Calculator
** api address: /api/Calculator/ImportHsCode
** request mode: POST
** interface description: import the excel - hs code
*/
export function APostImportHsCode (formData: FormData) {
  return requestFile<Api.V1Calculator.APostImportHsCode.Response>(
    `/api/Calculator/ImportHsCode`,
    formData
  );
}
        
/**
** api name: Calculator
** api address: /api/Calculator/GetFileUploadHistory
** request mode: GET
** interface description: get this file upload history
*/
export function AGetGetFileUploadHistory (params: Api.V1Calculator.AGetGetFileUploadHistory.Request) {
  return request<Api.V1Calculator.AGetGetFileUploadHistory.Response>(
    `/api/Calculator/GetFileUploadHistory`,
    {
      method: "GET",
      params,
    }
  );
}
      