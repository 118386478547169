import React from 'react'
import { createFromIconfontCN } from '@ant-design/icons'

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2943712_jpri67lx1sf.js'
})

export class IconUser extends React.Component<{ style?: React.CSSProperties }> {
  render() {
    return <IconFont style={{ verticalAlign: '0', ...this.props.style}} type="icon-yonghu" />
  }
}