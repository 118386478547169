import { Button, Input, notification, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { AGetLists } from "services/v1/rate";

interface RateArgsProps {
  value?: Api.V1Component.StringSingleKeyValuePair[]
  onChange?: (val?: Api.V1Component.StringSingleKeyValuePair[]) => void
  deliveryLocation?: string
}

interface RateArgsState {
  loading: boolean
  lists: { label: string, value?: number, rawValue: Api.V1Component.RateArg, isEditor: boolean, message?: string }[]
}

export class RateArgs extends React.Component<RateArgsProps, RateArgsState> {
  constructor(props: RateArgsProps) {
    super(props)
    if (props.value) {
      this.state.lists = props.value.map(item => {
        return {
          label: item.key || '',
          value: item.value,
          isEditor: false,
          message: '',
          rawValue: item
        }
      })
    }
  }

  componentDidMount() {
    if (this.state.lists.length === 0) {
      this.getDefaultRateArgsLists(this.props.deliveryLocation)
    }
  }

  componentWillReceiveProps(newProps: RateArgsProps) {
    if (newProps.deliveryLocation !== this.props.deliveryLocation) {
      this.getDefaultRateArgsLists(newProps.deliveryLocation)
    }
  }

  state: RateArgsState = {
    loading: false,
    lists: []
  }

  getDefaultRateArgsLists = async (deliveryLocation?: string) => {
    if (!deliveryLocation) {
      this.setState({
        lists: []
      })
      this.props.onChange && this.props.onChange([])
      return
    }

    this.setState({
      loading: true
    })

    const res = await AGetLists({
      deliveryLocation
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        lists: res.data.rateArgs?.map(item => {
          return {
            label: item.key || '',
            value: item.value,
            isEditor: false,
            message: '',
            rawValue: item
          }
        }) || []
      }, () => {
        this.props.onChange && this.props.onChange(this.state.lists.map(item => {
          return {
            key: item.label,
            value: item.value || item.rawValue.value
          }
        }))
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  editor = (index: number) => {
    const current = this.state.lists[index]
    current.isEditor = true
    this.setState({
      lists: this.state.lists
    })
  }

  cancel = (index: number) => {
    const current = this.state.lists[index]
    current.isEditor = false
    current.value = current.rawValue.value
    current.message = ''
    this.setState({
      lists: this.state.lists
    })
  }

  save = (index: number) => {
    const current = this.state.lists[index]
    current.isEditor = false
    this.setState({
      lists: this.state.lists
    }, () => {
      this.props.onChange && this.props.onChange(this.state.lists.map(item => {
        return {
          key: item.label,
          value: item.value || item.rawValue.value
        }
      }))
    })
    
  }

  inputChange = (index: number, value: string) => {
    const current = this.state.lists[index]
    if (value) {
      const val = Number(value)
      if (isNaN(val)) {
        current.message = 'Not legal.'
        current.value = 0
      } else {
        current.value = val
        current.message = ''
      }
    } else {
      current.value = 0
      current.message = 'Not null.'
    }
    this.setState({
      lists: this.state.lists
    })
  }

  columns:ColumnsType<RateArgsState['lists'][0]>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1,
      width: '50px',
      align: 'center'
    },
    {
      title: 'Constant Name',
      render: (_val, record) => record.label,
      width: '200px'
    },
    {
      title: 'Description',
      render: (_val, record) => record.rawValue.description,
      width: '200px'
    },
    {
      title: 'Value',
      width: '200px',
      render: (_val, record, index) => {
        const value = record.value || record.rawValue.value
        if (record.isEditor) {
          return (
            <Tooltip title={record.message} placement="topLeft">
              <Input onChange={(e) => this.inputChange(index, e.target.value)} defaultValue={value} type="number" placeholder="Please enter value" />
            </Tooltip>
          )
        }
        return value
      }
    },
    {
      title: 'Option',
      render: (_val, record, index) => {
        const saveBtn = [
          <Button disabled={!!record.message} onClick={() => this.save(index)} type="link" key="Save">Save</Button>,
          <Button onClick={() => this.cancel(index)} type="link" key="Cancel">Cancel</Button>
        ]

        const editorBtn = <Button onClick={() => this.editor(index)} type="link" key="Editor">Edit</Button>
        return (
          <Space>
            {
              record.isEditor ? saveBtn : editorBtn
            }
          </Space>
        )
      }
    }
  ]
  
  render() {
    return (
      <Table
        loading={this.state.loading}
        columns={this.columns}
        dataSource={this.state.lists}
        pagination={false}
        ></Table>
    )
  }
}