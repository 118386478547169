import { Button, notification, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IconUser } from "components/icons";
import React from "react";
import { AGetLists, APostEnabledSet } from "services/v1/template";
import { TemplateForm, TemplateFormProps } from "./form"

interface TemplateState {
  templateLists: Api.V1Component.TemplateVo[],
  listFormData: Api.V1Template.AGetLists.Request
  loading: boolean
  showForm: boolean
  total: number
  editorId?: number
}

export class Template extends React.Component<any, TemplateState> {
  state: TemplateState = {
    templateLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    showForm: false,
    total: 0
  }
  
  componentWillMount() {
    this.getTemplateLists()
  }

  getTemplateLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await AGetLists(listFormData)
    
    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        templateLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns:ColumnsType<Api.V1Component.TemplateVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1,
      align: 'center'
    },
    {
      title: 'Template Name',
      render: (_val, record) => record.templateName
    },
    {
      title: 'Template Code',
      render: (_val, record) => record.templateCode
    },
    {
      title: 'Tempalte Remark',
      render: (_val, record) => record.remark
    },
    {
      title: 'Lockout Enabled',
      dataIndex: 'lockoutEnabled',
      align: 'center',
      render: (_val, _record) => {
        return <IconUser style={{ fontSize: '22px', color: _record.isEnable ? 'green' : 'gray' }} />
      }
    },
    {
      title: 'Option',
      render: (_val, _record, index) => {
        const btns = [
          <Button onClick={_ => this.updateStatus(index)} type="link" key="Enabled">Enabled</Button>,
          <Button onClick={_ => this.openForm(_record.id)} type="link" key="Editor">Editor</Button>
        ]
        return (
          <Space>
            {
              !_record.isEnable ? btns : <Button type="link" key="/">/</Button>
            }
          </Space>
        )
      }
    }
  ]

  updateStatus = async (index: number) => {
    const current = this.state.templateLists[index]
    const res = await APostEnabledSet({
      id: current.id,
      isEnable: !current.isEnable
    })

    if (res.success) {
      current.isEnable = !current.isEnable
      this.setState({
        templateLists: this.state.templateLists
      })
      notification.success({
        message: 'Operate success'
      })
    } else {
      this.setState({
        templateLists: this.state.templateLists
      })
      notification.error({
        message: res.message
      })
    }
  }

  onFormClose:TemplateFormProps['onClose'] = (isRefresh) => {
    this.setState({
      showForm: false,
      editorId: undefined
    })
    isRefresh && this.getTemplateLists()
  }

  openForm = (id?: number) => {
    this.setState({
      showForm: true,
      editorId: id
    })
  }

  renderTable() {
    return (
      <>
        <div style={{ marginBottom: '16px' }}>
          <Button onClick={() => this.setState({ showForm: true })} type="primary">Add</Button>
        </div>
        <Table
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.templateLists}
          pagination={{
            onChange: this.getTemplateLists,
            total: this.state.total,
            pageSize: this.state.listFormData.pageSize,
            current: this.state.listFormData.pageIndex,
            size: "small"
          }}></Table>
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.showForm ? <TemplateForm id={this.state.editorId} onClose={this.onFormClose} /> : this.renderTable()}
      </>
    )
  }
}