import React from 'react';
import { Form, Input, Button, Card, message, FormProps, Typography, Space } from 'antd';
import { GetUrlParams } from 'utils';
import logo from 'assets/ligentia-logo.svg';
import GetVerifyCode from "../../utils/verify-code"
import { APostLogin } from 'services/v1/account';
import "./style.less";

const request = [{ required: true }];


interface LoginStates {
    loginLoding: boolean;
    verifyImgSrc: string;
    random: string;
}

const validateMessages = {
    required: `'\${label}' Is a required field`,
};

export default class ELogin extends React.Component<any, LoginStates> {

    state: LoginStates = {
        loginLoding: false,
        verifyImgSrc: '',
        random: ''
    };

    componentWillMount() {
        this.getVerifyCode()
    }

    onFinish: FormProps['onFinish'] = async (values: Api.V1Component.SupLoginPo) => {
        this.setState({ loginLoding: true });
        const res = await APostLogin({
            userName: values.userName,
            passWord: values.passWord,
            verifyCode: values.verifyCode,
            slideProof: this.state.random
        });
        if (res.success && res.data) {
            message.destroy()
            message.success('login success');
            this.setState({ loginLoding: false });
            window.localStorage.setItem('userToken', `Bearer ${res.data.jwtToken.token}`);
            this.loginSuccess();
        } else {
            this.setState({ loginLoding: false });
            message.error(res.message);
        }
    };

    loginSuccess() {
        const redirectUrl = GetUrlParams({ key: 'redirect' });
        const url = redirectUrl ? decodeURIComponent(redirectUrl) : '/welcome';
        window.location.replace(url)
    };

    getVerifyCode = async() => {
        const data = await GetVerifyCode()
        this.setState({
            verifyImgSrc: data.imgSrc,
            random: data.random
        })
    }

    render() {
        return (
            <div className="loginBox" style={{ position: 'absolute', left: '38%', top: '15%', width: '394px' }}>
                <Typography.Title style={{ textAlign: 'center', marginBottom: '40px' }}>
                    <img src={logo} style={{ width: '200px' }} alt="logo" />
                </Typography.Title>
                <Card bordered={false} style={{ borderRadius: '12px', boxShadow: '0px 0px 3px #dedede' }}>
                    <input type="text" style={{ display: 'none' }} />
                    <input type="password" style={{ display: 'none' }} />
                    <Form
                        size="large"
                        name="loginForm"
                        validateMessages={validateMessages}
                        labelCol={{ span: 24 }}
                        onFinish={this.onFinish}
                    >
                        <Form.Item label="Account" name="userName" rules={request}>
                            <Input placeholder="Please input the account" autoComplete="off" />
                        </Form.Item>
                        <Form.Item className="password-box" label="Password" name="passWord" rules={request}>
                            <Input.Password placeholder="Please input the password" autoComplete="off" />
                        </Form.Item>
                        <Form.Item label="Verify Code" name="verifyCode" rules={request}>
                            <Space>
                                <Input placeholder="input the verify code" />
                                { this.state.verifyImgSrc &&
                                  <img style={{ height: '31.78px' }} onClick={this.getVerifyCode} src={this.state.verifyImgSrc} alt="verify code"></img>
                                }
                            </Space>
                        </Form.Item>

                        <Form.Item style={{ textAlign: 'center', paddingTop: '24px' }}>
                            <Button
                                loading={this.state.loginLoding}
                                type="primary"
                                htmlType="submit"
                                style={{
                                    width: '100%',
                                    backgroundColor: '#32AAE1'
                                }}
                            >
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        );
    }
}
