import request from "../../utils/request";

/**
** api name: InfraRole
** api address: /api/InfraRole/Lists
** request mode: POST
** interface description: 角色分页列表
*/
export function APostLists (params: Api.V1InfraRole.APostLists.Request) {
  return request<Api.V1InfraRole.APostLists.Response>(
    `/api/InfraRole/Lists`,
    {
      method: "POST",
      data: params,
    }
  );
}

/**
** api name: InfraRole
** api address: /api/InfraRole/Create
** request mode: POST
** interface description: 创建角色
*/
export function APostCreate (params: Api.V1InfraRole.APostCreate.Request) {
  return request<Api.V1InfraRole.APostCreate.Response>(
    `/api/InfraRole/Create`,
    {
      method: "POST",
      data: params,
    }
  );
}

/**
** api name: InfraRole
** api address: /api/InfraRole/ClaimLists
** request mode: POST
** interface description: 业务所拥有的所有权限列表(暂时没有分页显示)
*/
export function APostClaimLists (params: Api.V1InfraRole.APostClaimLists.Request) {
  return request<Api.V1InfraRole.APostClaimLists.Response>(
    `/api/InfraRole/ClaimLists`,
    {
      method: "POST",
      data: params,
    }
  );
}

/**
** api name: InfraRole
** api address: /api/InfraRole/ModifyClaims
** request mode: POST
** interface description: 修改角色下面的权限列表
*/
export function APostModifyClaims (params: Api.V1InfraRole.APostModifyClaims.Request) {
  return request<Api.V1InfraRole.APostModifyClaims.Response>(
    `/api/InfraRole/ModifyClaims`,
    {
      method: "POST",
      data: params,
    }
  );
}