import { Button, Form, Input, notification, Space } from "antd";
import React from "react";
import { APostCreate, APutEdit } from "services/v1/template";
import { SaveButton } from "utils/theme";

interface Step5Props {
  onBack: () => void
  onNext: () => void
  defaultValue?: {
    id?: number
    templateName?: string
    templateCode?: string
    remark?: string
    commonConfig?: Api.V1Component.ConstantConfigurationVo
    formula?: Api.V1Component.FormulaConfiguration
  }
}

interface Step5State {
  loading: boolean
  defaultFormValue?: FormData
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

interface FormData {
  templateName?: string
  templateCode?: string
  remark?: string
}

export class TemplateStep5 extends React.Component<Step5Props, Step5State> {
  constructor(props: Step5Props) {
    super(props)
    if (props.defaultValue) {
      const { templateName, templateCode, remark } = props.defaultValue
      this.state.defaultFormValue = {
        templateName,
        templateCode,
        remark
      }
    }
  }

  state: Step5State = {
    loading: false
  }

  onFinish = async (formData: FormData) => {
    this.setState({
      loading: true
    })

    const defaultValue = this.props.defaultValue
    let res: Api.V1Template.APutEdit.Response | Api.V1Template.APostGetTemplateTestResult.Response | null = null
    const data: Api.V1Component.TemplateAddPo = {
      templateName: formData.templateName,
      templateCode: formData.templateCode,
      remark: formData.remark,
      formula: defaultValue?.formula,
      commonConfig: defaultValue?.commonConfig
    }

    if (defaultValue?.id) {
      res = await APutEdit({
        ...data,
        id: defaultValue.id
      })
    } else {
      res = await APostCreate({ ...data })
    }

    this.setState({
      loading: false
    })

    if (res?.success) {
      this.props.onNext()
    } else {
      notification.error({
        message: res?.message
      })
    }
  }

  render() {
    return (
      <>
        <Form<FormData>
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={this.onFinish}
          style={{width: '400px'}}
          validateMessages={validateMessages}
          initialValues={this.state.defaultFormValue}
        >
          <Form.Item
            label="Template Name"
            name="templateName"
            rules={request}
          >
            <Input placeholder="Please enter template name"/>
          </Form.Item>

          <Form.Item
            label="Template Code"
            name="templateCode"
            rules={request}
          >
            <Input placeholder="Please enter template code"/>
          </Form.Item>
          
          <Form.Item
            label="Remark"
            name="remark"
          >
            <Input placeholder="Please enter remark"/>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <Button loading={this.state.loading} onClick={this.props.onBack}>Back</Button>
              <SaveButton loading={this.state.loading} type="primary" htmlType="submit">Save</SaveButton>
            </Space>
          </Form.Item>
        </Form>
      </>
    )
  }
}