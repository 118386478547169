import { Button, notification, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { APostEnabledSet, APostLists } from "services/v1/infra-mg";
import UserForm from "./form"
import { ModifyPassword, ModifyPasswordProps } from './modify-password'
import { ModifyEmail, ModifyEmailProps } from './modify-email'
import { IconUser } from "components/icons";
import { connect } from "react-redux";

interface UserProps {
  isAdmin: boolean
}

interface UserState {
  userLists: Api.V1Component.InfraMgsVo[],
  listFormData: Api.V1InfraMg.APostLists.Request
  loading: boolean
  showForm: boolean
  total: number
  modifyPasswordConfig: ModifyPasswordProps
  modifyEmailConfig: ModifyEmailProps
}

class ManageUser extends React.Component<UserProps, UserState> {
  state: UserState = {
    userLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    showForm: false,
    total: 0,
    modifyPasswordConfig: {
      id: -1,
      visible: false,
      onClose: () => {
        this.setState({
          modifyPasswordConfig: {
            ...this.state.modifyPasswordConfig,
            visible: false,
            id: -1
          }
        })
      }
    },
    modifyEmailConfig: {
      id: -1,
      visible: false,
      onClose: (isRefresh) => {
        this.setState({
          modifyEmailConfig: {
            ...this.state.modifyEmailConfig,
            visible: false,
            id: -1
          }
        })
        if (isRefresh) {
          this.getUserLists()
        }
      }
    }
  }

  componentWillMount() {
    this.getUserLists()
    if (this.props.isAdmin) {
      this.columns.push(
        { title: 'Tenant Name', dataIndex: 'tenantName' },
      )
    }
    this.columns.push(
      {
        title: 'Lockout Enabled',
        dataIndex: 'lockoutEnabled',
        align: 'center',
        render: (val: Api.V1Component.InfraMgsVo['lockoutEnabled'], _record, index) => {
          return <IconUser style={{ fontSize: '22px', color: _record.lockoutEnabled ? 'gray' : 'green' }} />
        }
      },
      {
        title: 'Operate',
        dataIndex: 'operate',
        render: (_val, _record, index) => {
          return (
            <Space>
              {
                _record.lockoutEnabled
                  ?
                  <Button onClick={_ => this.updateStatus(index)} type="link">Enabled</Button>
                  :
                  <Button onClick={_ => this.updateStatus(index)} type="link">Lockout</Button>
              }
              <Button onClick={_ => this.openModifyPassword(index)} type="link">Modify Password</Button>
              <Button onClick={_ => this.openModifyEmail(index)} type="link">Modify Email</Button>
            </Space>
          )
        }
      }
    )
  }

  getUserLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await APostLists(listFormData)

    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        userLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns: ColumnsType<Api.V1Component.InfraMgsVo> = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1
    },
    { title: 'User Name', dataIndex: 'userName' },
    { title: 'Email', dataIndex: 'email' },

  ]

  updateStatus = async (index: number) => {
    const current = this.state.userLists[index]
    const res = await APostEnabledSet({
      ids: [current.id],
      isEnabled: !current.lockoutEnabled
    })

    if (res.success) {
      current.lockoutEnabled = !current.lockoutEnabled
      this.setState({
        userLists: this.state.userLists
      })
      notification.success({
        message: 'Operate success'
      })
    } else {
      this.setState({
        userLists: this.state.userLists
      })
      notification.error({
        message: res.message
      })
    }
  }

  openModifyPassword = (index: number) => {
    this.setState({
      modifyPasswordConfig: {
        ...this.state.modifyPasswordConfig,
        visible: true,
        id: this.state.userLists[index].id
      }
    })
  }

  openModifyEmail = (index: number) => {
    this.setState({
      modifyEmailConfig: {
        ...this.state.modifyEmailConfig,
        visible: true,
        id: this.state.userLists[index].id
      }
    })
  }

  onFormClose = () => {
    this.setState({
      showForm: false
    })

    this.getUserLists()
  }

  renderAdd = () => {
    if (this.props.isAdmin) return null

    return (
      <div style={{ marginBottom: '16px' }}>
        <Button onClick={() => this.setState({ showForm: true })} type="primary">Add</Button>
      </div>
    )
  }

  renderTable() {
    return (
      <>
        {this.renderAdd()}
        <Table
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.userLists}
          pagination={{
            onChange: this.getUserLists,
            total: this.state.total,
            pageSize: this.state.listFormData.pageSize,
            current: this.state.listFormData.pageIndex,
            size: "small"
          }}></Table>
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.showForm ? <UserForm onClose={this.onFormClose} /> : this.renderTable()}
        {this.state.modifyPasswordConfig.id !== -1 && <ModifyPassword {...this.state.modifyPasswordConfig} key={`ModifyPassword${this.state.modifyPasswordConfig.id}`} />}
        {this.state.modifyEmailConfig.id !== -1 && <ModifyEmail {...this.state.modifyEmailConfig} key={`ModifyEmail${this.state.modifyEmailConfig.id}`} />}
      </>
    )
  }
}

export default connect((store: any) => {
  return {
    isAdmin: store.username === 'admin' || store.username === 'digital_super_user'
  }
})(ManageUser)