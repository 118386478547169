import { Select } from 'antd'
import React from 'react'
import { AGetRates } from 'services/v1/data-dic'
import { PalletizationRadioType } from './item'

interface DeliveryLocationState {
  loading: boolean
  lists: Api.V1Component.RateVo[]
  disable: boolean
}

interface DeliveryLocationProps {
  value?: string
  onChange?: (val?: string) => void
  onChangePortOfArrival?: (val?: string) => void
  palletization?: PalletizationRadioType['value']
}

export class DeliveryLocation extends React.Component<DeliveryLocationProps, DeliveryLocationState> {

  state:DeliveryLocationState = {
    loading: false,
    lists: [],
    disable: false
  }

  componentDidMount() {
    this.checkStates()
  }

  componentWillReceiveProps(nextProps: DeliveryLocationProps) {
    if (nextProps.palletization !== this.props.palletization) {
      this.checkStates(nextProps.palletization)
    }
  }

  checkStates = (palletization?: DeliveryLocationProps['palletization']) => {
    const p = palletization || this.props.palletization
    if (p === 'No') {
      this.setState({
        lists: [],
        disable: true
      })
      this.onChange()
    } else {
      this.setState({
        disable: false
      })
    }
  }

  onChange = (deliveryLocation?: string) => {
    if (deliveryLocation) {
      const { lists } = this.state
      for (let i = 0; i < lists.length; i++) {
        if (deliveryLocation === lists[i].deliveryLocation) {
          const temp = lists[i]
          this.props.onChange && this.props.onChange(temp.deliveryLocation)
          this.props.onChangePortOfArrival && this.props.onChangePortOfArrival(temp.portOfArrival)
          break
        }
      }
    } else {
      this.props.onChange && this.props.onChange(undefined)
      this.props.onChangePortOfArrival && this.props.onChangePortOfArrival(undefined)
    }
  }

  getLists = async (keyword?: string) => {
    this.setState({
      loading: true
    })
    const res = await AGetRates({
      pageIndex: 1,
      pageSize: 1000,
      keyword: keyword
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        lists: res.data.values || []
      })
    }
  }

  render() {
    return (
      <div>
        <Select<string>
          value={this.props.value}
          disabled={this.state.disable}
          showArrow
          filterOption
          showSearch
          onChange={(val) => this.onChange(val)}
          loading={this.state.loading}
          onFocus={() => this.state.lists.length === 0 && this.getLists()}
          allowClear
        >
          { this.state.lists.map(item => <Select.Option value={item.deliveryLocation || ''} key={item.deliveryLocation}>{item.deliveryLocation}</Select.Option>) }
        </Select>
      </div>
    )
  }
}
