import { RouteType } from "./router-config"

import SettingLists from 'pages/system/setting/index'

export const SystemRoute: RouteType[] = [
  {
    name: 'System',
    icon: 'SettingOutlined',
    path: '/system',
    moduleCode: 'systemConfiguration',
    route: [
      {
        path: '/system/setting',
        name: 'Setting',
        moduleCode: 'systemConfiguration',
        component: SettingLists,
        breadList: ['Subscription', 'Setting'],
      },
    ]
  }
]