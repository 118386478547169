import { Button, notification, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ModifyEmail, ModifyEmailProps } from "./modify-email";
import React from "react";
import { APostLists, APostEnabledSet } from "services/v1/tenant-manage";
import TenantAdministratorForm, { TenantAdministratorFormProps } from "./form"
import { ModifyPassword, ModifyPasswordProps } from "./modify-password";
import { GetUrlParams } from "utils";
import { IconUser } from "components/icons";


interface TenantAdministratorState {
  tenantAdministratorLists: Api.V1Component.TenantManageVo[],
  listFormData: Api.V1TenantManage.APostLists.Request
  loading: boolean
  showForm: boolean
  total: number
  modifyEmailConfig: ModifyEmailProps,
  modifyPasswordConfig: ModifyPasswordProps,
  administratorFormProps: TenantAdministratorFormProps
}

export default class TenantAdministrator extends React.Component<{}, TenantAdministratorState> {
  state: TenantAdministratorState = {
    tenantAdministratorLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    showForm: false,
    total: 0,
    administratorFormProps: {
      onClose: (isRefresh) => {
        if (isRefresh) {
          this.getTenantAdministratorLists()
        }
        this.setState({
          showForm: false
        })
      }
    },
    modifyEmailConfig: {
      id: -1,
      visible: false,
      onClose: (isRefresh) => {
        this.setState({
          modifyEmailConfig: {
            ...this.state.modifyEmailConfig,
            visible: false,
            id: -1
          }
        })
      }
    },
    modifyPasswordConfig: {
      id: -1,
      visible: false,
      onClose: () => {
        this.setState({
          modifyPasswordConfig: {
            ...this.state.modifyPasswordConfig,
            visible: false,
            id: -1
          }
        })
      }
    }
  }
  
  componentWillMount() {
    const openType = GetUrlParams({ key: 'open' })
    const tenantName = GetUrlParams({ key: 'tenantName' })
    const tenantId = GetUrlParams({ key: 'tenantId' })
    switch (openType) {
      case 'add':
        if (tenantId && tenantName) {
          this.addForm({ name: tenantName, id: Number(tenantId) })
        } else {
          this.addForm()
        }
        break
    }
    this.getTenantAdministratorLists()
  }

  getTenantAdministratorLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await APostLists(listFormData)
    
    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        tenantAdministratorLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns:ColumnsType<Api.V1Component.TenantManageVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1,
      align: 'center'
    },
    {
      title: 'Adminisator Name',
      render: (_val, record) => record.userName
    },
    {
      title: 'User Email',
      render: (_val, record) => record.email
    },
    {
      title: 'Phone Number',
      render: (_val, record) => record.phoneNumber
    },
    {
      title: 'Tenant Name',
      render: (_val, record) => record.tenantName
    },
    {
      title: 'TenantId',
      render: (_val, record) => record.tenantId
    },
    {
      title: 'Edi Code',
      render: (_val, record) => record.ediCode
    },
    {
      title: 'Lockout Enabled',
      dataIndex: 'lockoutEnabled',
      align: 'center',
      render: (_val, _record) => {
        return <IconUser style={{ fontSize: '22px', color: _record.lockoutEnabled ? 'gray' : 'green' }} />
      }
    },
    {
      title: 'Option',
      render: (_val, _record, index) => {
        return (
          <Space>
            {
              _record.lockoutEnabled
              ?
              <Button onClick={_ => this.updateStatus(index)} type="link">Enabled</Button>
              :
              <Button onClick={_ => this.updateStatus(index)} type="link">Lockout</Button>
            }
            <Button onClick={_ => this.openModifyEmail(index)} type="link">Modify Email</Button>
            <Button onClick={_ => this.openModifyPassword(index)} type="link">Modify Password</Button>
          </Space>
        )
      }
    }
  ]

  updateStatus = async (index: number) => {
    const current = this.state.tenantAdministratorLists[index]
    const res = await APostEnabledSet({
      id: current.id,
      isEnabled: !current.lockoutEnabled
    })

    if (res.success) {
      current.lockoutEnabled = !current.lockoutEnabled
      this.setState({
        tenantAdministratorLists: this.state.tenantAdministratorLists
      })
      notification.success({
        message: 'Operate success'
      })
    } else {
      this.setState({
        tenantAdministratorLists: this.state.tenantAdministratorLists
      })
      notification.error({
        message: res.message
      })
    }
  }

  openModifyEmail = (index: number) => {
    this.setState({
      modifyEmailConfig: {
        ...this.state.modifyEmailConfig,
        visible: true,
        id: this.state.tenantAdministratorLists[index].id
      }
    })
  }
  
  openModifyPassword = (index: number) => {
    this.setState({
      modifyPasswordConfig: {
        ...this.state.modifyPasswordConfig,
        visible: true,
        id: this.state.tenantAdministratorLists[index].id
      }
    })
  }

  addForm = (param?: { id: number, name: string }) => {
    const administratorFormProps = this.state.administratorFormProps

    if (param) {
      administratorFormProps.defaultValue = [{ label: param.name, value: param.id, key: param.id }]
    } else {
      administratorFormProps.defaultValue = undefined
    }

    this.setState({
      showForm: true,
      administratorFormProps: administratorFormProps
    })
  }

  renderTable() {
    return (
      <>
        <Table
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.tenantAdministratorLists}
          pagination={{
            onChange: this.getTenantAdministratorLists,
            total: this.state.total,
            pageSize: this.state.listFormData.pageSize,
            current: this.state.listFormData.pageIndex,
            size: "small"
          }}></Table>
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.showForm ? <TenantAdministratorForm { ...this.state.administratorFormProps } /> : this.renderTable()}
        {this.state.modifyPasswordConfig.id !== -1 && <ModifyPassword {...this.state.modifyPasswordConfig} key={`ModifyPassword${this.state.modifyPasswordConfig.id}`}/>}
        {this.state.modifyEmailConfig.id !== -1 && <ModifyEmail {...this.state.modifyEmailConfig} key={`ModifyEmail${this.state.modifyEmailConfig.id}`}/>}
      </>
    )
  }
}