import { Divider, message, PageHeader, Spin, Steps } from "antd";
import { Step1Props, TemplateStep1 } from './step1'
import { Step2Props, TemplateStep2 } from './step2'
import { Step3Props, TemplateStep3 } from "./step3/step3";
import { TemplateStep4 } from './step4'
import React from "react";
import { TemplateStep5 } from "./step5";
import { AGetDetail_Id } from "services/v1/template";

export interface TemplateFormProps {
  onClose: (isRefresh: boolean) => void
  id?: number
}

interface TemplateFormState {
  formData: Api.V1Template.APostCreate.Request | Api.V1Component.TemplateDetailVo
  currentStep: number
  result?: Api.V1Component.CalculateResultVo
  loading: boolean
}

export class TemplateForm extends React.Component<TemplateFormProps, TemplateFormState> {

  componentDidMount() {
    const id = this.props.id
    if (id) {
      this.getTemplateDetail(id)
    } else {
      this.setState({
        currentStep: 0
      })
    }
  }

  state: TemplateFormState = {
    formData: {},
    currentStep: -1,
    loading: false
  }

  async getTemplateDetail(id: number) {
    this.setState({
      loading: true
    })
    
    const res = await AGetDetail_Id({ id })

    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        formData: res.data,
        currentStep: 0
      })
    }
  }

  onStep1Next: Step1Props['onNext'] = (data) => {
    this.setState({
      formData: {
        ...this.state.formData,
        commonConfig: data
      },
      currentStep: 1
    })
  }

  onStep2Back = () => {
    this.setState({
      currentStep: 0
    })
  }

  onStep2Next: Step2Props['onNext'] = (value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        formula: {
          commericalValue: value.commericalValue,
          finalDelivery: value.finalDelivery
        }
      },
      currentStep: 2
    })
  }

  onStep3Back = () => {
    this.setState({
      currentStep: 1
    })
  }

  onStep3Next: Step3Props['onSuccess'] = (result) => {
    this.setState({
      currentStep: 3,
      result
    })
  }

  onStep4Back = () => {
    this.setState({
      currentStep: 2
    })
  }

  onStep4Next = () => {
    this.setState({
      currentStep: 4
    })
  }
  
  onStep5Back = () => {
    this.setState({
      currentStep: 3
    })
  }

  onStep5Next = () => {
    message.success('Operate success.')
    this.props.onClose(true)
  }

  renderStep = () => {
    const { currentStep, formData, result } = this.state
    switch (currentStep) {
      case 0:
        return <TemplateStep1 defaultValue={formData.commonConfig} onNext={this.onStep1Next} onCancel={() => this.props.onClose(false)} />
      case 1:
        if (formData.commonConfig) {
          return <TemplateStep2
                  onBack={this.onStep2Back}
                  onNext={this.onStep2Next}
                  commonConfig={formData.commonConfig}
                  defaultValue={{
                    commericalValue: formData.formula?.commericalValue,
                    finalDelivery: formData.formula?.finalDelivery
                  }}/>
        }
        break
      case 2:
        return <TemplateStep3
                  formula={formData.formula}
                  commonConfig={formData.commonConfig}
                  onBack={this.onStep3Back}
                  onSuccess={this.onStep3Next}/>
      case 3:
        return <TemplateStep4
                  formula={formData.formula}
                  commonConfig={formData.commonConfig}
                  result={result}
                  onBack={this.onStep4Back}
                  onNext={this.onStep4Next}/>
      case 4:
        return <TemplateStep5
                onBack={this.onStep5Back}
                onNext={this.onStep5Next}
                defaultValue={formData}/>
    }
  }

  render () {
    return (
      <>
      <div style={{ width: '100%', background: '#fff' }}>
        <PageHeader onBack={() => this.props.onClose(false)} title="Add" subTitle="tenant" />
        <Divider />
        <Spin spinning={this.state.loading}>
          <div style={{ padding: '0 40px 40px 40px' }}>
            <Steps current={this.state.currentStep}>
              <Steps.Step title="Editor Calculate Constant"></Steps.Step>
              <Steps.Step title="Editor Calculate Formula"></Steps.Step>
              <Steps.Step title="Editor Calculate Template Param Setting"></Steps.Step>
              <Steps.Step title="Calculate Result Confirm"></Steps.Step>
              <Steps.Step title="Finish"></Steps.Step>
            </Steps>
            <Divider dashed />
            { this.renderStep() }
          </div>
        </Spin>
      </div>
      </>
    )
  }
} 