import { Modal, notification, Spin, Transfer } from "antd";
import React from "react";
import { APostChangeGroup, APostLists } from "services/v1/infra-mg";
import { AGetLists_GroupId } from "services/v1/group";
import { TransferDirection } from "antd/lib/transfer";

export interface RelevanceUserProps {
  visible: boolean
  id: number
  onClose: () => void
}

interface RecordType {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
}

interface RelevanceUserState {
  loading: boolean
  data: RecordType[]
  targetKeys: string[]
}

export class RelevanceUserTranfer extends React.Component<RelevanceUserProps, RelevanceUserState> {
  componentDidMount() {
    if (this.props.id === -1) return
    this.getLists();
    this.getTargetKeys()
  }

  state: RelevanceUserState = {
    loading: false,
    data: [],
    targetKeys: [],
  }

  getTargetKeys = async () => {
    const res = await AGetLists_GroupId({
      groupId: this.props.id
    })
    if (res.success && res.data.users !== undefined) {
      const result: string[]= res.data.users.map(u => u.value?.toString() ?? "");
      this.setState({
        targetKeys: result
      })
    }
  }

  onFinish = async () => {
    this.setState({
      loading: true
    })

    const res = await APostChangeGroup({
      groupId: this.props.id,
      userIds: this.state.targetKeys.map(m => parseInt(m))
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Relevance success.'
      })

      this.props.onClose()
    } else {
      notification.error({
        message: res.message
      })
    }
  }
  

  getLists = async (keyword?: string) => {
    this.setState({ loading: true })
    const res = await APostLists({
      pageIndex: 1,
      pageSize: 200,
      keyword
    })
    if (res.success) {
      if (res.data.values !== undefined) {
        const result: RecordType[] = res.data.values.map(m => {
          return {
            key: m.id.toString(),
            title: m.userName ?? "",
            description: '',
            chosen: false
          }
        })
        this.setState({
          loading: false,
          data: result
        })
      } else {
        this.setState({
          loading: false
        })
      }
    }
  }

 onChange = (newTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
    console.log(newTargetKeys, direction, moveKeys);
    this.setState({
      targetKeys: newTargetKeys
    });
  };

  render() {
    let notFoundContent = null
    const { loading, data, targetKeys } = this.state
    if (loading) {
      notFoundContent = <Spin />
    }
    return (
      <Modal
        title="Relevance user"
        visible={this.props.visible}
        onCancel={this.props.onClose}
        onOk={this.onFinish}
        width={1000}
      >
        <Transfer
          rowKey={record => record.key}
          titles={['Username', 'Selected']}
          dataSource={data}
          targetKeys={targetKeys}
          onChange={this.onChange}
          render={item => item.title}
          showSearch={true}
          oneWay={true}
          pagination={{ pageSize: 20 }}
          listStyle={{ width: "465px" }}
        />
      </Modal>
    )
  }
}
