import { Avatar, Button, notification, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { Link } from "react-router-dom";
import { APostLists } from "services/v1/tenant";
import TenantForm from "./form"
import { RelevanceTemplate, RelevanceTemplateProps } from'./relevance-template'


interface TenantState {
  tenantLists: Api.V1Component.TenantInfoVo[],
  listFormData: Api.V1Tenant.APostLists.Request
  loading: boolean
  showForm: boolean
  total: number
  relevanceTemplateProps: RelevanceTemplateProps
}

export default class Tenant extends React.Component<any, TenantState> {
  state: TenantState = {
    tenantLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    showForm: false,
    total: 0,
    relevanceTemplateProps: {
      onClose: () => {
        this.setState({
          relevanceTemplateProps: {
            ...this.state.relevanceTemplateProps,
            visible: false,
            id: -1
          }
        })
      },
      visible: false,
      id: -1
    }
  }
  
  componentWillMount() {
    this.getTenantLists()
  }

  getTenantLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await APostLists(listFormData)
    
    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        tenantLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns:ColumnsType<Api.V1Component.TenantInfoVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1,
      align: 'center'
    },
    {
      title: 'Tenant Id',
      render: (_val, record) => record.tenantId
    },
    {
      title: 'Tenant Name',
      render: (_val, record) => record.tenantName
    },
    {
      title: 'Logo',
      render: (_val, record) => <Avatar size="small" src={record.logo} />
    },
    {
      title: 'Edi Code',
      render: (_val, record) => record.ediCode
    },
    {
      title: 'Phone Number',
      render: (_val, record) => record.phoneNumber
    },
    {
      title: 'Address',
      render: (_val, record) => record.address
    },
    {
      title: 'Remark',
      render: (_val, record) => record.remarks
    },
    {
      title: 'Option',
      render: (_val, record, index) => {
        return (
          <Space>
            <Link to={this.disposeUrl('/tenant/administrator?open=add', index)}><Button type="link">Add Administrator</Button></Link>
            <Button onClick={() => this.openRelevanceTemplate(record.tenantId)} type="link" key="Set Template">Set Template</Button>
          </Space>
        )
      }
    }
  ]

  onFormClose = () => {
    this.setState({
      showForm: false
    })

    this.getTenantLists()
  }

  disposeUrl = (url: string, index: number) => {
    if (!url.includes('?')) {
      url += '?'
    }
    const currentTenant = this.state.tenantLists[index]
    return `${url}&tenantName=${currentTenant.tenantName}&tenantId=${currentTenant.tenantId}`
  }

  openRelevanceTemplate = (id: number) => {
    this.setState({
      relevanceTemplateProps: {
        ...this.state.relevanceTemplateProps,
        id,
        visible: true
      }
    })
  }

  renderTable() {
    return (
      <>
        <div style={{ marginBottom: '16px' }}>
          <Button onClick={() => this.setState({ showForm: true })} type="primary">Add</Button>
        </div>
        <Table
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.tenantLists}
          pagination={{
            onChange: this.getTenantLists,
            total: this.state.total,
            pageSize: this.state.listFormData.pageSize,
            current: this.state.listFormData.pageIndex,
            size: "small"
          }}></Table>
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.showForm ? <TenantForm onClose={this.onFormClose} /> : this.renderTable()}
        <RelevanceTemplate {...this.state.relevanceTemplateProps} key={this.state.relevanceTemplateProps.id} />
      </>
    )
  }
}