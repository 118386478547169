import { Button, notification, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { APostLists } from "services/v1/infra-role";
import RoleForm from "./form"
import ModifyPermission, { ModifyPermissionProps } from "./modify-permission"


interface RoleState {
  roleLists: Api.V1Component.InfraRolesVo[],
  listFormData: Api.V1InfraRole.APostLists.Request
  loading: boolean
  showForm: boolean
  total: number
  modifyPermisson: ModifyPermissionProps
}

export default class RoleManage extends React.Component<any, RoleState> {
  state: RoleState = {
    roleLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    showForm: false,
    total: 0,
    modifyPermisson: {
      visible: false,
      id: 0,
      claims: [],
      onClose: () => {
        this.setState({
          modifyPermisson: { ...this.state.modifyPermisson, visible: false }
        })
      }
    }
  }
  
  componentDidMount() {
    this.getRoleLists()
  }

  getRoleLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await APostLists(listFormData)

    if (res.success) {
      this.setState({
        roleLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns:ColumnsType<Api.V1Component.InfraRolesVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1
    },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Normalized Name', dataIndex: 'normalizedName' },
    {
      title: 'Operate',
      render: (_val, _record, index) => {
        return (
          <Button onClick={_ => this.openModiifyPermission(index)} type="link">Modify Permission</Button>
        )
      }
    }
  ]

  openModiifyPermission = (index: number) => {
    const current = this.state.roleLists[index]
    this.setState({
      modifyPermisson: {
        ...this.state.modifyPermisson,
        visible: true,
        id: current.id,
        claims: []
      }
    })
  }

  onFormClose = () => {
    this.setState({
      showForm: false
    })

    this.getRoleLists()
  }

  renderTable() {
    return (
      <>
        <div style={{ marginBottom: '16px' }}>
          <Button onClick={() => this.setState({ showForm: true })} type="primary">Add</Button>
        </div>
        <Table<Api.V1Component.InfraRolesVo>
          columns={this.columns}
          dataSource={this.state.roleLists}
          pagination={{
            onChange: this.getRoleLists,
            total: this.state.total,
            pageSize: this.state.listFormData.pageSize,
            current: this.state.listFormData.pageIndex,
            size: "small"
          }}></Table>
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.showForm ? <RoleForm onClose={this.onFormClose} /> : this.renderTable()}
        <ModifyPermission {...this.state.modifyPermisson} key={this.state.modifyPermisson.id} />
      </>
    )
  }
}