import request from "../utils/request";

/**
 ** 接口名称: User
 ** 接口地址: /api/User/commonList
 ** 请求方式: GET
 ** 接口描述: 用户列表
 */
export function AGetCommonList(params?: Api.UserUser.AGetCommonList.Request) {
  return request<Api.UserUser.AGetCommonList.Response>('/api/calculator/history', {
    params,
  });
}

/**
 ** 接口名称: User
 ** 接口地址: /api/User/{userId}
 ** 请求方式: GET
 ** 接口描述: 查询详情
 */
export function AGetUser_UserId(params?: Api.UserUser.AGetUser_UserId.Request) {
  return request<Api.UserUser.AGetUser_UserId.Response>(`/api/calculator/historyDetail`, {
    params,
  });
}