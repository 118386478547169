import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Spin, Upload, Select, notification } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import React from "react";
import { APostImportAdmData, APostImportHsCode } from "services/v1/calculator";

interface CalculatorProps {
  visible: boolean
  onCancel: () => void
}

interface CalculatorState {
  actionValue: string
  loading: boolean
  disabledBtn: boolean
}

export class CalculatorImport extends React.Component<CalculatorProps, CalculatorState> {
  
  selectLists = [
    { label: 'LCC_Rate', value: 'LCC_Rate' },
    { label: 'LCC_HSCode', value: 'LCC_HSCode' },
  ]

  state: CalculatorState = {
    actionValue: this.selectLists[0].value,
    loading: false,
    disabledBtn: true
  }

  formData = new FormData()

  selectChange = (val: string) => {
    for (let i = 0; i < this.selectLists.length; i++) {
      if (this.selectLists[i].value === val) {
        this.setState({
          actionValue: this.selectLists[i].value
        })
        break
      }
    }
  }

  onChangeFile = (rawFile: UploadChangeParam<UploadFile<any>>) => {
    const KEY = 'file'
    if (rawFile.fileList.length) {
      this.formData.set(KEY, rawFile.file as unknown as any, rawFile.file.name.replace(/^.+\./, `${this.state.actionValue}.`))
      this.setState({ disabledBtn: false })
    } else {
      this.formData.delete(KEY)
      this.setState({ disabledBtn: true })
    }
    return false
  }

  onSendData = async () => {
    let res: Api.V1Calculator.APostImportAdmData.Response | Api.V1Calculator.APostImportHsCode.Response | null = null
    this.setState({
      loading: true
    })

    if (this.state.actionValue === 'LCC_Rate') {
      res = await APostImportAdmData(this.formData)
    } else if (this.state.actionValue === 'LCC_HSCode') {
      res = await APostImportHsCode(this.formData)
    }

    this.setState({
      loading: false
    })
    if (res?.success) {
      notification.success({
        message: 'Import success'
      })
      this.props.onCancel()
    } else {
      notification.error({
        message: res?.message
      })
    }
  }

  render () {
    const { loading } = this.state
    return (
      <Modal
        visible={this.props.visible}
        title="Import Excel"
        confirmLoading={loading}
        okText="Import"
        okButtonProps={{
          disabled: false
        }}
        onCancel={this.props.onCancel}
        onOk={this.onSendData}>
        <Form 
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          style={{width: '400px'}}
        >
          <Form.Item
            label="Excel Type"
          >
            <Select<string> onChange={this.selectChange} defaultValue={this.state.actionValue}>
              { this.selectLists.map(item => <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>) }
            </Select>
          </Form.Item>

          <Form.Item
            label="Import"
          >
            <Spin spinning={loading}>
              <Upload onChange={(file) => this.onChangeFile(file)} beforeUpload={() => false} maxCount={1}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Spin>
          </Form.Item>
        </Form>
        
      </Modal>
    )
  }
}