import { Button, notification, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { APostLists } from "services/v1/group";
import { dateFormat } from "utils";
import UserGroupForm from "./form"
import { RelevanceTemplate, RelevanceTemplateProps } from'./relevance-template'
import { RelevanceUser, RelevanceUserProps } from './relecance-user'
import { connect } from "react-redux";
import { RelevanceUserTranfer } from "./relecance-user-tranfer";

interface UserGroupProps {
  isAdmin: boolean
}

interface UserGroupState {
  userGroupLists: Api.V1Component.GroupVo[],
  listFormData: Api.V1Group.APostLists.Request
  loading: boolean
  showForm: boolean
  total: number
  relevanceTemplateProps: RelevanceTemplateProps
  relevanceUserProps: RelevanceUserProps
}

class UserGroup extends React.Component<UserGroupProps, UserGroupState> {
  state: UserGroupState = {
    userGroupLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    showForm: false,
    total: 0,
    relevanceTemplateProps: {
      onClose: () => {
        this.setState({
          relevanceTemplateProps: {
            ...this.state.relevanceTemplateProps,
            visible: false,
            id: -1
          }
        })
      },
      visible: false,
      id: -1
    },
    relevanceUserProps: {
      onClose: () => {
        this.setState({
          relevanceUserProps: {
            ...this.state.relevanceUserProps,
            visible: false,
            id: -1
          }
        })
        this.getUserGroupLists()
      },
      visible: false,
      id: -1
    }
  }
  
  componentWillMount() {
    this.getUserGroupLists()
  }

  getUserGroupLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await APostLists(listFormData)
    
    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        userGroupLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns:ColumnsType<Api.V1Component.GroupVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1,
      align: 'center'
    },
    {
      title: 'Group Name',
      render: (_val, record) => record.groupName
    },
    {
      title: 'Tenant Name',
      render: (_val, record) => record.tenantName
    },
    {
      title: 'Edi Code',
      render: (_val, record) => record.ediCode
    },
    {
      title: 'Create Time',
      render: (_val, record) => dateFormat(record.createTime)
    },
    {
      title: 'Remark',
      render: (_val, record) => record.remark
    },
    {
      title: 'Number Of Users',
      render: (_val, record) => record.userCount
    },
    {
      title: 'Option',
      render: (_val, record) => {
        const btns = []
        if (!this.props.isAdmin) {
          btns.push(<Button onClick={() => this.openRelevanceTemplate(record.id)} type="link" key="Set Template">Set Template</Button>)
          btns.push(<Button onClick={() => this.openRelevanceUser(record.id)} type="link" key="Relevance User">Relevance User</Button>)
        }
        return (
          <Space>
            {btns}
          </Space>
        )
      }
    }
  ]

  addForm = () => {
    this.setState({
      showForm: true
    })
  }

  closeForm = (isRefresh: boolean) => {
    this.setState({
      showForm: false
    })

    if (isRefresh) {
      this.getUserGroupLists()
    }
  }

  openRelevanceTemplate = (id: number) => {
    this.setState({
      relevanceTemplateProps: {
        ...this.state.relevanceTemplateProps,
        id,
        visible: true
      }
    })
  }

  openRelevanceUser = (id: number) => {
    this.setState({
      relevanceUserProps: {
        ...this.state.relevanceUserProps,
        id,
        visible: true
      }
    })
  }

  renderTable() {
    return (
      <>
        {
          !this.props.isAdmin &&
          <div style={{ marginBottom: '16px' }}>
            <Button onClick={() => this.addForm()} type="primary">Add</Button>
          </div>
        }
        <Table
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.userGroupLists}
          pagination={{
            onChange: this.getUserGroupLists,
            total: this.state.total,
            pageSize: this.state.listFormData.pageSize,
            current: this.state.listFormData.pageIndex,
            size: "small"
          }}></Table>
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.showForm ? <UserGroupForm onClose={this.closeForm} /> : this.renderTable()}
        <RelevanceTemplate {...this.state.relevanceTemplateProps} key={`RelevanceTemplate${this.state.relevanceTemplateProps.id}`} />
        <RelevanceUserTranfer {...this.state.relevanceUserProps} key={`RelevanceUser${this.state.relevanceUserProps.id}}`} />
      </>
    )
  }
}

export default connect((store: any) => {
  return {
    isAdmin: store.username === 'admin' || store.username === 'digital_super_user'
  }
})(UserGroup)