import request from "../../utils/request";

/**
** api name: Statistics
** api address: /api/Statistics/SupList
** request mode: GET
** interface description: 超管仪表盘统计（商户总数、商户管理员总数、终端用户总数、商户组别总数）
*/
export function AGetCreate (params: Api.V1Statistics.AGetSupList.Request) {
  return request<Api.V1Statistics.AGetSupList.Response>(
    `/api/Statistics/SupList`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Statistics
** api address: /api/Statistics/Lists
** request mode: GET
** interface description: 商户管理员仪表盘统计（终端用户总数、商户组别总数）
*/
export function AGetLists (params: Api.V1Statistics.AGetLists.Request) {
  return request<Api.V1Statistics.AGetLists.Response>(
    `/api/Statistics/Lists`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Statistics
** api address: /api/Statistics/CalcStatistics
** request mode: GET
** interface description: 调用计算总数
*/
export function AGetCalcStatistics (params: Api.V1Statistics.AGetCalcStatistics.Request) {
  return request<Api.V1Statistics.AGetCalcStatistics.Response>(
    `/api/Statistics/CalcStatistics`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Statistics
** api address: /api/Statistics/UserDailyStatistics
** request mode: GET
** interface description: 终端用户登录次总数
*/
export function AGetUserDailyStatistics (params: Api.V1Statistics.AGetUserDailyStatistics.Request) {
  return request<Api.V1Statistics.AGetUserDailyStatistics.Response>(
    `/api/Statistics/UserDailyStatistics`,
    {
      method: "GET",
      params,
    }
  );
}
        
