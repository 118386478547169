import request from "../../utils/request";

/**
** api name: Template
** api address: /api/Template/Constants
** request mode: GET
** interface description: 获取模板常量（带默认值）
*/
export function AGetConstants (params: Api.V1Template.AGetConstants.Request) {
  return request<Api.V1Template.AGetConstants.Response>(
    `/api/Template/Constants`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/Create
** request mode: POST
** interface description: 添加新模板
*/
export function APostCreate (params: Api.V1Template.APostCreate.Request) {
  return request<Api.V1Template.APostCreate.Response>(
    `/api/Template/Create`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/Lists
** request mode: GET
** interface description: 获取模板列表
*/
export function AGetLists (params: Api.V1Template.AGetLists.Request) {
  return request<Api.V1Template.AGetLists.Response>(
    `/api/Template/Lists`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/Detail/{id}
** request mode: GET
** interface description: 获取模板详情
*/
export function AGetDetail_Id (params: Api.V1Template.AGetDetail_Id.Request) {
  return request<Api.V1Template.AGetDetail_Id.Response>(
    `/api/Template/Detail/${params.id}`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/Edit
** request mode: PUT
** interface description: 编辑模板
*/
export function APutEdit (params: Api.V1Template.APutEdit.Request) {
  return request<Api.V1Template.APutEdit.Response>(
    `/api/Template/Edit`,
    {
      method: "PUT",
      data: params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/EnabledSet
** request mode: POST
** interface description: 设置模板状态
*/
export function APostEnabledSet (params: Api.V1Template.APostEnabledSet.Request) {
  return request<Api.V1Template.APostEnabledSet.Response>(
    `/api/Template/EnabledSet`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/TenantRefTemplateList
** request mode: GET
** interface description: 获取租户可关联模板列表
*/
export function AGetTenantRefTemplateList (params: Api.V1Template.AGetTenantRefTemplateList.Request) {
  return request<Api.V1Template.AGetTenantRefTemplateList.Response>(
    `/api/Template/TenantRefTemplateList`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/GroupRefTemplateList
** request mode: GET
** interface description: 获取用户组可关联模板列表
*/
export function AGetGroupRefTemplateList (params: Api.V1Template.AGetGroupRefTemplateList.Request) {
  return request<Api.V1Template.AGetGroupRefTemplateList.Response>(
    `/api/Template/GroupRefTemplateList`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/TenantTemplateList
** request mode: GET
** interface description: 获取租户已关联模板列表
*/
export function AGetTenantTemplateList (params: Api.V1Template.AGetTenantTemplateList.Request) {
  return request<Api.V1Template.AGetTenantTemplateList.Response>(
    `/api/Template/TenantTemplateList`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/GroupTemplateList
** request mode: GET
** interface description: 获取用户组已关联模板列表
*/
export function AGetGroupTemplateList (params: Api.V1Template.AGetGroupTemplateList.Request) {
  return request<Api.V1Template.AGetGroupTemplateList.Response>(
    `/api/Template/GroupTemplateList`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/TenantRefTemplate
** request mode: POST
** interface description: 租户关联模板
*/
export function APostTenantRefTemplate (params: Api.V1Template.APostTenantRefTemplate.Request) {
  return request<Api.V1Template.APostTenantRefTemplate.Response>(
    `/api/Template/TenantRefTemplate`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/GroupRefTemplate
** request mode: POST
** interface description: 用户组关联模板
*/
export function APostGroupRefTemplate (params: Api.V1Template.APostGroupRefTemplate.Request) {
  return request<Api.V1Template.APostGroupRefTemplate.Response>(
    `/api/Template/GroupRefTemplate`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: Template
** api address: /api/Template/GetTemplateTestResult
** request mode: POST
** interface description: 测试计算模板
*/
export function APostGetTemplateTestResult (params: Api.V1Template.APostGetTemplateTestResult.Request) {
  return request<Api.V1Template.APostGetTemplateTestResult.Response>(
    `/api/Template/GetTemplateTestResult`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
