import { Button, Divider, Form,  FormInstance,  Input, notification, PageHeader, Space } from "antd";
import React from "react";
import { APostCreate } from "services/v1/group";
import { SaveButton } from "utils/theme";

interface UserGroupFormState {
  loading: boolean
}

export interface UserGroupFormProps {
  onClose: (isRefresh: boolean) => void
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export default class UserGroupForm extends React.Component<UserGroupFormProps, UserGroupFormState> {

  state: UserGroupFormState = {
    loading: false
  }

  formRef = React.createRef<FormInstance<Api.V1Group.APostCreate.Request>>()

  onFinish = async (formData: Api.V1Group.APostCreate.Request) => {
    this.setState({
      loading: true
    })

    const res = await APostCreate({
      groupName: formData.groupName,
      groupCode: formData.groupCode,
      remark: formData.remark
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Add Group Success.'
      })
      
      this.props.onClose(true)
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <>
      <div style={{ width: '100%', height: '100%', background: '#fff' }}>
        <PageHeader onBack={() => this.props.onClose(false)} title="Add" subTitle="New User Group" />
        <Divider />
        <Form<Api.V1Group.APostCreate.Request>
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={this.onFinish}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
          ref={this.formRef}
        >
          <Form.Item
            label="Group Name"
            name="groupName"
            rules={[...request, { pattern: /^[^ ]+$/g, message: 'The group name cannot contain Spaces' }]}
          >
            <Input placeholder="Please enter group name"/>
          </Form.Item>

          <Form.Item
            label="Group Code"
            name="groupCode"
            rules={request}
          >
            <Input placeholder="Please enter group code"/>
          </Form.Item>

          <Form.Item
            label="Remark"
            name="remark"
            rules={request}
          >
            <Input placeholder="Please enter remark"/>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <SaveButton loading={this.state.loading} htmlType="submit">Submit</SaveButton>
              <Button onClick={() => this.props.onClose(false)}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      </>
    )
  }
} 