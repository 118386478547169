import { Select, Spin, Empty, } from "antd";
import { SelectProps } from "antd/lib/select";
import { isArray } from "lodash";
import React from "react";
import { AGetLists } from "services/v1/template";

export interface TemplateProps {
  multiple?: boolean
  onChange: (value?: number[]) => void
  defaultValue?: {label: string, value: number, key: number}[]
}

interface TemplateState {
  options: SelectProps<number>['options']
  loading: boolean
  defaultValue?: number[]
  value: number[]
}

export class TemplateSelect extends React.Component<TemplateProps, TemplateState> {

  constructor(props: TemplateProps) {
    super(props)
    if (props.defaultValue) {
      this.state.options = props.defaultValue
      this.state.defaultValue = props.defaultValue.map(item => item.value)
      this.state.value = this.state.defaultValue
    }
  }

  state: TemplateState = {
    options: [],
    loading: false,
    value: []
  }

  timer:any = undefined

  onSelect: SelectProps<number>['onSearch'] = (value) => {
    clearInterval(this.timer)

    this.timer = setTimeout(async () => {
      this.setState({
        loading: true
      })

      const res = await AGetLists({
        pageSize: 50,
        pageIndex: 1,
        templateName: value
      })

      if (res.success) {
        const selectedOptions = this.state.options?.filter(item => this.state.value.includes(item.value)) || []
        const filterOptions = res.data?.values?.filter((item) => !this.state.value.includes(item.id)) || []
        const createNewOptions = filterOptions.map(item => {
          return { label: item.templateName, value: item.id, key: item.id }
        })
        this.setState({
          options: selectedOptions.concat(createNewOptions),
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
    }, 200)
  }

  onChange = (value: number | number[]) => {
    if (isArray(value)) {
      this.props.onChange(value)
      this.setState({
        value
      })
    } else {
      this.props.onChange()
      this.setState({
        value: [value]
      })
    }
  }

  render() {
    let notFoundContent = null
    const { loading, options } = this.state
    if (loading) {
      notFoundContent = <Spin />
    } else if (options?.length === 0) {
      notFoundContent = <Empty />
    }
    return (
      <Select<number[] | number>
        options={options}
        onSearch={this.onSelect}
        placeholder="Please select template"
        showSearch
        filterOption={false}
        mode={this.props.multiple ? 'multiple' : undefined}
        notFoundContent={notFoundContent}
        onChange={this.onChange}
        allowClear
        defaultValue={this.state.defaultValue}
      ></Select>
    )
  }
}