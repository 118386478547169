import request from "../../utils/request";

/**
** api name: Account
** api address: /api/Account/Login
** request mode: POST
** interface description: 超管登录
*/
export function APostLogin(params: Api.V1Account.APostLogin.Request) {
  return request<Api.V1Account.APostLogin.Response>(
    `/api/Account/Login`,
    {
      method: "POST",
      data: params,
    }
  );
}

/**
** api name: Account
** api address: /api/Account/Details
** request mode: GET
** interface description: 超管用户详情
*/
export function AGetDetails(params: Api.V1Account.AGetDetails.Request) {
  return request<Api.V1Account.AGetDetails.Response>(
    `/api/Account/Details`,
    {
      method: "GET",
      params,
    }
  );
}

/**
** api name: Account
** api address: /api/Account/LoginOut
** request mode: GET
** interface description: 超管登出
*/
export function AGetLoginOut(params: Api.V1Account.AGetLoginOut.Request) {
  return request<Api.V1Account.AGetLoginOut.Response>(
    `/api/Account/LoginOut`,
    {
      method: "GET",
      params,
    }
  );
}

/**
** api name: Account
** api address: /api/Account/ChangePassWord
** request mode: POST
** interface description: 超管自己修改密码
*/
export function APostChangePassWord(params: Api.V1Account.APostChangePassWord.Request) {
  return request<Api.V1Account.APostChangePassWord.Response>(
    `/api/Account/ChangePassWord`,
    {
      method: "POST",
      data: params,
    }
  );
}

export function AGetGetSupUserTenantList(params: Api.V1Account.AGetGetSupUserTenantList.Request) {
  return request<Api.V1Account.AGetGetSupUserTenantList.Response>(
    `/api/Account/GetSupUserTenantList`,
    {
      method: "GET",
      params
    }
  );
}

export function APutSwitchTenant(params: Api.V1Account.APutSwitchTenant.Request) {
  return request<Api.V1Account.APutSwitchTenant.Response>(
    `/api/Account/SwitchTenant/${params.id}`,
    {
      method: "PUT",
      data: params,
    }
  );
}



