import request from "../../utils/request";

/**
** api name: Audit
** api address: /api/Audit/SupLogs
** request mode: GET
** interface description: 账号变更日志
*/
export function AGetSupLogs (params: Api.V1Audit.AGetSupLogs.Request) {
  return request<Api.V1Audit.AGetSupLogs.Response>(
    `/api/Audit/SupLogs`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Audit
** api address: /api/Audit/TemplateLogs
** request mode: GET
** interface description: 模板操作日志
*/
export function AGetTemplateLogs (params: Api.V1Audit.AGetTemplateLogs.Request) {
  return request<Api.V1Audit.AGetTemplateLogs.Response>(
    `/api/Audit/TemplateLogs`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Audit
** api address: /api/Audit/SystemConfigLogs
** request mode: GET
** interface description: 模板操作日志
*/
export function AGetSystemConfigLogs (params: Api.V1Audit.AGetSystemConfigLogs.Request) {
  return request<Api.V1Audit.AGetSystemConfigLogs.Response>(
    `/api/Audit/SystemConfigLogs`,
    {
      method: "GET",
      params,
    }
  );
}
        