import request from "../../utils/request";

/**
** api name: DataDic
** api address: /api/DataDic/Items
** request mode: GET
** interface description: get items
*/
export function AGetItems (params: Api.V1DataDic.AGetItems.Request) {
  return request<Api.V1DataDic.AGetItems.Response>(
    `/api/DataDic/Items`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: DataDic
** api address: /api/DataDic/Pols
** request mode: GET
** interface description: get pols
*/
export function AGetPols (params: Api.V1DataDic.AGetPols.Request) {
  return request<Api.V1DataDic.AGetPols.Response>(
    `/api/DataDic/Pols`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: DataDic
** api address: /api/DataDic/Pods
** request mode: GET
** interface description: get pods
*/
export function AGetPods (params: Api.V1DataDic.AGetPods.Request) {
  return request<Api.V1DataDic.AGetPods.Response>(
    `/api/DataDic/Pods`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: DataDic
** api address: /api/DataDic/Rates
** request mode: GET
** interface description: get warehouse rate
*/
export function AGetRates (params: Api.V1DataDic.AGetRates.Request) {
  return request<Api.V1DataDic.AGetRates.Response>(
    `/api/DataDic/Rates`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: DataDic
** api address: /api/DataDic/HsCodes
** request mode: GET
** interface description: get hs codes
*/
export function AGetHsCodes (params: Api.V1DataDic.AGetHsCodes.Request) {
  return request<Api.V1DataDic.AGetHsCodes.Response>(
    `/api/DataDic/HsCodes`,
    {
      method: "GET",
      params,
    }
  );
}
        
