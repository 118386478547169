import { Button, Card, Form, FormInstance, Input, message, Modal, notification, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { ADeleteDelete_Id, APostEnabledSet } from "services/v1/rate";
import RateForm, { RateFormProps } from "./form"
import { IconUser } from "components/icons";
import { AGetTenantRateLists } from "services/v1/rate";


interface RateStateState {
  rateLists: Api.V1Component.TenantRateListVo[],
  listFormData: Api.V1Rate.AGetTenantRateLists.Request
  loading: boolean
  total: number
  rateFormProps: RateFormProps
  visibleRateArgs: boolean
}

interface FormData {
  tenantName: string
}

export default class RateState extends React.Component<{}, RateStateState> {
  componentWillMount() {
    this.getRateLists()
  }

  state: RateStateState = {
    rateLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    total: 0,
    rateFormProps: {
      onClose: (isRefresh) => {
        if (isRefresh) {
          this.getRateLists()
        }
        this.setState({
          rateFormProps: {
            ...this.state.rateFormProps,
            id: undefined,
            show: false
          }
        })
      },
      show: false
    },
    visibleRateArgs: false
  }

  formRef = React.createRef<FormInstance<FormData>>()

  getRateLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await AGetTenantRateLists(listFormData)
    
    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        rateLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns:ColumnsType<Api.V1Component.TenantRateListVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1,
      align: 'center'
    },
    {
      title: 'Tenant Name',
      render: (_val, record) => record.tenantName
    },
    {
      title: 'EDI Code',
      render: (_val, record) => record.ediCode
    },
    {
      title: 'Country Code',
      render: (_val, record) => record.countryCode
    },
    {
      title: 'Delivery Location',
      render: (_val, record) => record.deliveryLocation
    },
    {
      title: 'PortOf Arrival',
      render: (_val, record) => record.portOfArrival
    },
    {
      title: 'Tenant Name',
      render: (_val, record) => record.tenantName
    },
    {
      title: 'Rate Args',
      render: (_val, record) => {
        return <Button onClick={() => this.openRateArgsModal(record.rateArgs)} type="link">View Rate Args</Button>
      }
    },
    {
      title: 'Lockout Enabled',
      align: 'center',
      render: (_val, record) => {
        return <IconUser style={{ fontSize: '22px', color: record.isEnable ? 'green' : 'gray' }} />
      }
    },
    {
      title: 'Option',
      render: (_val, record, index) => {
        return (
          <Space>
            <Button onClick={_ => this.updateStatus(index)} type="link" key="Lockout&Enabled">{ record.isEnable ? 'Lockout' : 'Enabled'}</Button>
            <Button onClick={_ => this.editor(record.id)} type="link" key="Editor">Edit</Button>
            <Popconfirm
              title="Do you want to delete and change lines?"
              onConfirm={() => this.deletel(record.id)}
              okText="Yes"
              cancelText="No"
              >
              <Button type="link" key="Delete">Delete</Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]

  editor = (id: number) => {
    this.setState({
      rateFormProps: {
        ...this.state.rateFormProps,
        id,
        show: true
      }
    })
  }

  add = () => {
    this.setState({
      rateFormProps: {
        ...this.state.rateFormProps,
        id: undefined,
        show: true
      }
    })
  }

  deletel = async (id: number) => {
    const res = await ADeleteDelete_Id({ id })

    if (res.success) {
      message.success('Delete success.')
      this.getRateLists()
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  updateStatus = async (index: number) => {
    const current = this.state.rateLists[index]
    const res = await APostEnabledSet({
      id: current.id,
      isEnabled: !current.isEnable
    })

    if (res.success) {
      current.isEnable = !current.isEnable
      this.setState({
        rateLists: this.state.rateLists
      })
      notification.success({
        message: 'Operate success'
      })
    } else {
      this.setState({
        rateLists: this.state.rateLists
      })
      notification.error({
        message: res.message
      })
    }
  }

  onFinish = () => {
    const tenantName = this.formRef.current?.getFieldValue('tenantName')
    this.setState({
      listFormData: {
        ...this.state.listFormData,
        pageIndex: 1,
        tenantName: tenantName
      }
    }, () => {
      this.getRateLists()
    })
  }

  onReset = () => {
    this.formRef.current?.resetFields(['tenantName'])
    this.setState({
      listFormData: {
        ...this.state.listFormData,
        pageIndex: 1,
        tenantName: ''
      }
    }, () => {
      this.getRateLists()
    })
  }

  currentRateArgs: Api.V1Component.RateArg[] = []
  openRateArgsModal = (rawData?: Api.V1Component.RateArg[]) => {
    this.currentRateArgs = rawData || []
    this.setState({
      visibleRateArgs: true
    })
  }

  renderForm = () => {
    return (
      <Card>
        <Form<FormData>
          layout="inline"
          onFinish={this.onFinish}
          ref={this.formRef}
          autoComplete="off">
          <Form.Item label="Tenant Name" name="tenantName">
            <Input placeholder="Please enter name" allowClear></Input>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button onClick={this.onFinish} type="primary">Search</Button>
              <Button onClick={this.onReset}>Reset</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    )
  }

  renderTable() {
    return (
      <>
        {this.renderForm()}
        <Card style={{ marginTop: '14px' }}>
          <div style={{ marginBottom: '16px' }}>
            <Button onClick={this.add} type="primary">Add</Button>
          </div>
          <Table
            loading={this.state.loading}
            columns={this.columns}
            dataSource={this.state.rateLists}
            pagination={{
              onChange: this.getRateLists,
              total: this.state.total,
              pageSize: this.state.listFormData.pageSize,
              current: this.state.listFormData.pageIndex,
              size: "small"
            }}></Table>
        </Card>
      </>
    )
  }

  renderRateArgsModel() {
    const columns: ColumnsType<Api.V1Component.RateArg> = [
      {
        title: 'Index',
        render: (_val, _record, index) => index + 1,
        width: '50px',
        align: 'center'
      },
      {
        title: 'Constant Name',
        render: (_val, record) => record.description,
        width: '200px'
      },
      {
        title: 'Value',
        width: '200px',
        render: (_val, record) => record.value
      },
    ]

    const close = () => this.setState({ visibleRateArgs: false })

    return (
      <Modal visible={this.state.visibleRateArgs} onOk={close} onCancel={close}>
        <Table columns={columns} dataSource={this.currentRateArgs}></Table>
      </Modal>
    )
  }

  render() {
    return (
      <>
        {this.state.rateFormProps.show ? <RateForm { ...this.state.rateFormProps } /> : this.renderTable()}
        {this.renderRateArgsModel()}
      </>
    )
  }
}