import { Button, Divider, Form, Input, notification, PageHeader, Space } from "antd";
import React from "react";
import { APostCreate } from "services/v1/tenant";
import { SaveButton } from "utils/theme";

interface TenantFormState {
  loading: boolean
}

interface TenantFormProps {
  onClose: () => void
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export default class TenantForm extends React.Component<TenantFormProps, TenantFormState> {

  state: TenantFormState = {
    loading: false
  }

  onFinish = async (formData: Api.V1Tenant.APostCreate.Request) => {
    this.setState({
      loading: true
    })

    const res = await APostCreate({
      tenantName: formData.tenantName,
      ediCode: formData.ediCode,
      phoneNumber: formData.phoneNumber,
      address: formData.address,
      remarks: formData.remarks
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Add Group Success.'
      })
      
      this.props.onClose()
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <>
      <div style={{ width: '100%', height: '100%', background: '#fff' }}>
        <PageHeader onBack={this.props.onClose} title="Add" subTitle="tenant" />
        <Divider />
        <Form<Api.V1Tenant.APostCreate.Request>
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={this.onFinish}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="Tenant Name"
            name="tenantName"
            rules={[...request, { pattern: /^[^ ]+$/g, message: 'The tenant name cannot contain Spaces' }]}
          >
            <Input placeholder="Please enter tenant name"/>
          </Form.Item>

          <Form.Item
            label="Edi Code"
            name="ediCode"
            rules={request}
          >
            <Input placeholder="Please enter edi code"/>
          </Form.Item>
          
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
          >
            <Input placeholder="Please enter phone number"/>
          </Form.Item>

          <Form.Item
            label="Address"
            name="address"
            rules={request}
          >
            <Input placeholder="Please enter address"/>
          </Form.Item>

          <Form.Item
            label="Remark"
            name="remarks"
            rules={request}
          >
            <Input placeholder="Please enter remark"/>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <SaveButton loading={this.state.loading} type="primary" htmlType="submit">Submit</SaveButton>
              <Button onClick={this.props.onClose}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      </>
    )
  }
} 