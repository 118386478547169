import request from "../../utils/request";

/**
** api name: InfraMg
** api address: /api/InfraMg/Create
** request mode: POST
** interface description: 新增业务账户
*/
export function APostCreate (params: Api.V1InfraMg.APostCreate.Request) {
  return request<Api.V1InfraMg.APostCreate.Response>(
    `/api/InfraMg/Create`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: InfraMg
** api address: /api/InfraMg/ModifyPassWord
** request mode: POST
** interface description: 修改业务账户的密码
*/
export function APostModifyPassWord (params: Api.V1InfraMg.APostModifyPassWord.Request) {
  return request<Api.V1InfraMg.APostModifyPassWord.Response>(
    `/api/InfraMg/ModifyPassWord`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: InfraMg
** api address: /api/InfraMg/Lists
** request mode: POST
** interface description: 业务账户分页列表
*/
export function APostLists (params: Api.V1InfraMg.APostLists.Request) {
  return request<Api.V1InfraMg.APostLists.Response>(
    `/api/InfraMg/Lists`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: InfraMg
** api address: /api/InfraMg/EnabledSet
** request mode: POST
** interface description: 用户状态设置
*/
export function APostEnabledSet (params: Api.V1InfraMg.APostEnabledSet.Request) {
  return request<Api.V1InfraMg.APostEnabledSet.Response>(
    `/api/InfraMg/EnabledSet`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: InfraMg
** api address: /api/InfraMg/ChangeRoles
** request mode: POST
** interface description: 修改业务账户的角色
*/
export function APostChangeRoles (params: Api.V1InfraMg.APostChangeRoles.Request) {
  return request<Api.V1InfraMg.APostChangeRoles.Response>(
    `/api/InfraMg/ChangeRoles`,
    {
      method: "POST",
      data: params,
    }
  );
}

/**
** api name: InfraMg
** api address: /api/InfraMg/ChangeEmail
** request mode: POST
** interface description: 修改业务账户的邮箱
*/
export function APostChangeEmail (params: Api.V1InfraMg.APostChangeEmail.Request) {
  return request<Api.V1InfraMg.APostChangeEmail.Response>(
    `/api/InfraMg/ChangeEmail`,
    {
      method: "POST",
      data: params,
    }
  );
}

/**
** api name: InfraMg
** api address: /api/InfraMg/ChangeGroup
** request mode: POST
** interface description: 用户关联用户组
*/
export function APostChangeGroup (params: Api.V1InfraMg.APostChangeGroup.Request) {
  return request<Api.V1InfraMg.APostChangeGroup.Response>(
    `/api/InfraMg/ChangeGroup`,
    {
      method: "POST",
      data: params,
    }
  );
}