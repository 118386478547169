import React from "react";
import "./index.less"

interface ListItem {
  title: string
  count: number
}

export interface CountBoxProps {
  lists: ListItem[]
}

interface ClorInfoItem {
  circleColor: string
  bigCircleColor: string
  smallCircleColor: string
}

interface CountBoxState {
  clorInfo: ClorInfoItem[]
}

export class CountBox extends React.Component<CountBoxProps, CountBoxState> {

  state: CountBoxState = {
    clorInfo: [
      {
        circleColor: 'linear-gradient(-32deg, #846AD7 0%, #B39FF2 100%)',
        bigCircleColor: 'linear-gradient(-32deg, #846AD7 0%, #B39FF2 100%)',
        smallCircleColor: '#FFFFFF'
      },
      {
        circleColor: 'linear-gradient(-32deg, #32AAE1 0%, #55C8FC 100%)',
        bigCircleColor: 'linear-gradient(-32deg, #32AAE1 0%, #55C8FC 100%)',
        smallCircleColor: '#FFFFFF'
      },
      {
        circleColor: 'linear-gradient(-32deg, #B5C106 0%, #C7D31E 100%)',
        bigCircleColor: 'linear-gradient(-32deg, #B5C106 0%, #C7D31E 100%)',
        smallCircleColor: '#FFFFFF'
      },
      {
        circleColor: 'linear-gradient(-32deg, #FAB43C 0%, #FCC260 100%)',
        bigCircleColor: 'linear-gradient(-32deg, #FAB43C 0%, #FCC260 100%)',
        smallCircleColor: '#FFFFFF'
      },
    ]
  }

  renderTag = (item: ListItem, colorInfo: ClorInfoItem) => {
    return (
      <div className="box">
        <div style={{ background: colorInfo.circleColor }} className="circle"></div>
        <p className="title">{ item.title }</p>
        <p className="count">{ item.count }</p>
        <p style={{ background: colorInfo.bigCircleColor }} className="big-circle"></p>
        <p style={{ background: colorInfo.smallCircleColor }} className="small-circle"></p>
      </div>
    )
  }
  
  render() {
    return (
      <>
        <div className="main">
          {this.props.lists.map((item, index) => {
            return this.renderTag(item, this.state.clorInfo[index])
          })}
        </div>
      </>
    )
  }
}