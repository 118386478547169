import { RouteType } from "./router-config"

import Calculator from 'pages/history/calculator/index2'
import Logs from 'pages/audit/logs'

export const AuditRoute: RouteType[] = [
  {
    name: 'Audit',
    icon: 'FileSearchOutlined',
    path: '/audit',
    moduleCode: 'audit',
    route: [
      {
        path: '/audit/logs',
        name: 'Logs',
        moduleCode: 'audit.log',
        component: Logs,
        breadList: ['Subscription', 'Logs'],
      },
      {
        path: '/audit/history',
        name: 'History',
        moduleCode: 'audit.history',
        component: Calculator,
        breadList: ['Subscription', 'History'],
      },
    ]
  },
]