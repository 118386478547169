import { Button, Form, Space } from "antd";
import React from "react";

interface Step4Props {
  result?: Api.V1Component.CalculateResultVo
  formula?: Api.V1Component.FormulaConfiguration
  commonConfig?: Api.V1Component.ConstantConfigurationVo
  onBack: () => void
  onNext: () => void
}

interface Step4State {
  commonConfigLists: { label: string, value: number }[]
}

export class TemplateStep4 extends React.Component<Step4Props, Step4State> {
  constructor(props: Step4Props) {
    super(props)

    if (props.commonConfig) {
      const lists: Step4State['commonConfigLists'] = []
      for (const [key, value] of Object.entries(props.commonConfig)) {
        lists.push({
          label: key,
          value: value as number
        })
      }
      this.state.commonConfigLists = lists
    }
  }

  state: Step4State = {
    commonConfigLists: []
  }

  render() {
    return (
      <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{width: '600px'}}
      >
        <Form.Item label="Constant Value">
          {this.state.commonConfigLists.map(item => <p>{item.label} = {item.value}</p>)}
        </Form.Item>

        <Form.Item label="Commerical Value">
          {this.props.formula?.commericalValue}
        </Form.Item>

        <Form.Item label="Final Delivery">
          {this.props.formula?.finalDelivery}
        </Form.Item>

        <Form.Item label="Result">
          <p>Commerical Value Result: { this.props.result?.commericalValueResult }</p>
          <p>Final Delivery Result: { this.props.result?.finalDeliveryResult }</p>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Space>
            <Button onClick={this.props.onBack}>Back</Button>
            <Button onClick={this.props.onNext} type="primary" htmlType="submit">
              Next
            </Button>
          </Space>
        </Form.Item>
      </Form>
      </>
    )
  }
}