import { Button, Divider, Form, Input, notification, PageHeader, Space } from "antd";
import React from "react";
import { APostCreate } from "services/v1/sup";
import { SaveButton } from "utils/theme";

interface ManageUserFormState {
  loading: boolean
}

interface ManageUserFormProps {
  onClose: () => void
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export default class RoleForm extends React.Component<ManageUserFormProps, ManageUserFormState> {

  state: ManageUserFormState = {
    loading: false
  }

  onFinish = async (formData: Api.V1Component.SupAddPo) => {
    this.setState({
      loading: true
    })

    const res = await APostCreate({
      userName: formData.userName,
      email: formData.email
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Add administrator success.'
      })
      
      this.props.onClose()
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <>
      <div style={{ width: '100%', height: '100%', background: '#fff' }}>
        <PageHeader onBack={this.props.onClose} title="Add" subTitle="administrator" />
        <Divider />
        <Form<Api.V1Component.SupAddPo>
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={this.onFinish}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="User Name"
            name="userName"
            rules={[...request, { pattern: /^[^ ]+$/g, message: 'The username cannot contain Spaces' }]}
          >
            <Input placeholder="Please enter role user name"/>
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={request}
          >
            <Input placeholder="Please enter email"/>
          </Form.Item>


          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <SaveButton loading={this.state.loading} type="primary" htmlType="submit">Submit</SaveButton>
              <Button onClick={this.props.onClose}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      </>
    )
  }
} 