import { Checkbox, CheckboxOptionType, notification, Spin } from "antd";
import React from "react";
import { APostClaimLists } from "services/v1/infra-role";

interface PermissionState {
  spinLoading: boolean,
  checkboxOptions: CheckboxOptionType[]
}

interface PermissionProps {
  onChange: (value: string[]) => void
}

export default class PermissionSelect extends React.Component<PermissionProps, PermissionState> {
  state: PermissionState = {
    spinLoading: true,
    checkboxOptions: []
  }

  componentDidMount() {
    this.getClaimsData()
  }

  getClaimsData = async () => {
    this.setState({
      spinLoading: true
    })
    
    const res = await APostClaimLists({})

    this.setState({
      spinLoading: false
    })
    if (res.success) {
      const checkboxOptions: CheckboxOptionType[] = []

      res.data.values?.forEach(item => {
        for (let i = 0; i < checkboxOptions.length; i++) {
          if (checkboxOptions[i].value === item.type) return
        }

        checkboxOptions.push({
          label: item.describe,
          value: item.type || ''
        })
      })


      this.setState({
        checkboxOptions: checkboxOptions
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <>
        { !this.state.spinLoading ? <Checkbox.Group onChange={(value) => this.props.onChange(value as unknown as string[])} options={this.state.checkboxOptions} /> : <Spin tip="Load permission table..." />}
      </>
    )
  }
}