import { Button, Divider, Form, FormInstance, Input, notification, PageHeader, Space } from "antd";
import React from "react";
import { APostCreate } from "services/v1/infra-mg";
import { SaveButton } from "utils/theme";

interface UserFormState {
  loading: boolean
}

interface UserFormProps {
  onClose: () => void
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export default class UserForm extends React.Component<UserFormProps, UserFormState> {

  state: UserFormState = {
    loading: false
  }

  formRef = React.createRef<FormInstance<Api.V1InfraMg.APostCreate.Request>>()

  onFinish = async (formData: Api.V1InfraMg.APostCreate.Request) => {
    this.setState({
      loading: true
    })

    const res = await APostCreate({
      userName: formData.userName,
      passWord: formData.passWord,
      email: formData.email,
      phoneNumber: formData.phoneNumber
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Add user success.'
      })
      
      this.props.onClose()
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <>
      <div style={{ width: '100%', height: '100%', background: '#fff' }}>
        <PageHeader onBack={this.props.onClose} title="Add" subTitle="user" />
        <Divider />
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={this.onFinish}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="User Name"
            name="userName"
            rules={[...request, { pattern: /^[^ ]+$/g, message: 'The username cannot contain Spaces' }]}
          >
            <Input placeholder="Please enter user name"/>
          </Form.Item>

          <Form.Item
            label="Password"
            name="passWord"
            rules={request}
          >
            <Input placeholder="Please enter passWord"/>
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[...request, { type: 'email', message: 'Please enter email' }]}
          >
            <Input placeholder="Please enter email"/>
          </Form.Item>
          
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
          >
            <Input placeholder="Please enter phone number"/>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <SaveButton loading={this.state.loading} type="primary" htmlType="submit">Submit</SaveButton>
              <Button onClick={this.props.onClose}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      </>
    )
  }
} 