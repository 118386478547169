import { Button, DatePicker, Divider, Form, FormInstance, Input, notification, PageHeader, Space } from "antd";
import moment, { Moment } from "moment";
import React from "react";
import { APostCreate, APutUpdate } from "services/v1/subscription";
import { SaveButton } from "utils/theme";
import { TemplateSelect, TemplateProps } from '../../../components/template/select'

interface SubscriptionFormState {
  loading: boolean
  templateSelectDefaultValue: TemplateProps['defaultValue']
  initialFormData?: formData
}

export interface SubscriptionFormProps {
  onClose: (isRefresh: boolean) => void
  editorData?: {
    subscriptionName?: string
    subscriptionRemark?: string
    price?: number
    startTime?: number
    endTime?: number
    id: number
    templates?: Api.V1Component.Int32StringKeyValuePair[]
  }
  show: boolean
}

interface formData {
  subscriptionName?: string
  subscriptionRemark?: string
  templateIds?: number[]
  price?: number
  time?: [Moment, Moment]
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export default class SubscriptionForm extends React.Component<SubscriptionFormProps, SubscriptionFormState> {

  constructor(props: SubscriptionFormProps) {
    super(props)

    const editorData = props.editorData
    if (editorData) {
      this.state.initialFormData = {
        subscriptionName: editorData?.subscriptionName,
        subscriptionRemark: editorData?.subscriptionRemark,
        time: [moment(editorData?.startTime ? editorData?.startTime * 1000 : undefined), moment(editorData?.endTime ? editorData?.endTime * 1000 : undefined)],
        price: editorData?.price,
        templateIds: editorData.templates ? editorData.templates.map(item => item.key || 0) : undefined
      }
      this.state.templateSelectDefaultValue = editorData.templates ? editorData.templates.map(item => {
        return { label: item.value || '', value: item.key || 0 , key: item.key || 0 }
      }) : []
    }
  }

  state: SubscriptionFormState = {
    loading: false,
    templateSelectDefaultValue: []
  }
  
  formRef = React.createRef<FormInstance<formData>>()

  onFinish = async (formData: formData) => {
    this.setState({
      loading: true
    })

    let res: Api.V1Subscription.APostCreate.Response | Api.V1Subscription.APutUpdate.Response | null = null

    const data: Api.V1Subscription.APostCreate.Request = {
      subscriptionName: formData.subscriptionName,
      subscriptionRemark: formData.subscriptionRemark,
      startTime: formData.time ? Math.ceil(formData.time[0].valueOf() / 1000 ): undefined,
      endTime: formData.time ? Math.ceil(formData.time[1].valueOf() / 1000) : undefined,
      price: formData.price,
      templateIds: formData.templateIds
    }

    if (this.props.editorData) {
      res = await APutUpdate({
        ...data,
        id: this.props.editorData.id
      })
    } else {
      res = await APostCreate(data)
    }

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Options subscription Success.'
      })
      
      this.props.onClose(true)
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <>
      <div style={{ width: '100%', height: '100%', background: '#fff' }}>
        <PageHeader onBack={() => this.props.onClose(false)} title={this.props.editorData ? 'Editor' : 'Add'} subTitle="subscription" />
        <Divider />
        <Form<formData>
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={this.onFinish}
          autoComplete="off"
          style={{width: '500px'}}
          validateMessages={validateMessages}
          initialValues={this.state.initialFormData}
        >
          <Form.Item
            label="Subscription Name"
            name="subscriptionName"
            rules={[...request, { pattern: /^[^ ]+$/g, message: 'The tenant name cannot contain Spaces' }]}
          >
            <Input placeholder="Please enter subscription name"/>
          </Form.Item>

          <Form.Item
            label="Time"
            name="time"
            rules={request}
          >
            <DatePicker.RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          
          <Form.Item
            label="Templates"
            name="templateIds"
            rules={request}
          >
            <TemplateSelect defaultValue={this.state.templateSelectDefaultValue} multiple onChange={(value) => this.formRef.current?.setFieldsValue({ templateIds: value && undefined })} />
          </Form.Item>
          
          <Form.Item
            label="Pirce"
            name="price"
          >
            <Input type="number" placeholder="Please enter pirce"/>
          </Form.Item>

          <Form.Item
            label="Subscription Remark"
            name="subscriptionRemark"
          >
            <Input placeholder="Please enter subscription remark"/>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <SaveButton loading={this.state.loading} type="primary" htmlType="submit">Submit</SaveButton>
              <Button onClick={() => this.props.onClose(false)}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      </>
    )
  }
}