import { Button, message, notification, Progress, ProgressProps, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AGetAvailableLists, AGetBoughLists, AGetLists, APostAction } from "services/v1/subscription";
import { dateFormat } from "utils";
import SubscriptionForm, { SubscriptionFormProps } from "./form"
import { BuySubscriptionProps, BuySubscription } from './buy'

interface SubscriptionProps extends RouteComponentProps {
  isAdmin: boolean
}

interface SubscriptionState {
  subscriptionLists: Api.V1Component.SubscriptionVo[],
  listFormData: Api.V1Subscription.AGetLists.Request
  loading: boolean
  total: number
  formProps: SubscriptionFormProps
  isAvailableSubscription: boolean
  isMySubscription: boolean
  buyScriptionProps: BuySubscriptionProps
}

class SubscriptionLists extends React.Component<SubscriptionProps, SubscriptionState> {
  state: SubscriptionState = {
    subscriptionLists: [],
    listFormData: {
      isEffective: false,
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    total: 0,
    formProps: {
      onClose: (isRefresh) => {
        this.setState({
          formProps: {
            ...this.state.formProps,
            show: false
          }
        })

        isRefresh && this.getSubscriptionLists()
      },
      show: false,
      editorData: undefined
    },
    isAvailableSubscription: false,
    isMySubscription: false,
    buyScriptionProps: {
      onClose: (isRefresh) => {
        this.setState({
          buyScriptionProps: {
            ...this.state.buyScriptionProps,
            visible: false
          }
        })

        isRefresh && this.getSubscriptionLists()
      },
      visible: false,
      id: -1
    }
  }
  
  componentWillMount() {
    this.setState({
      listFormData: {
        ...this.state.listFormData,
        isEffective: this.props.match.url.includes('effective')
      },
      isAvailableSubscription: this.props.match.url.includes('available'),
      isMySubscription: this.props.match.url.includes('my'),
    }, () => {
      this.getSubscriptionLists()
    })
  }

  getSubscriptionLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })
    let res: Api.V1Subscription.AGetLists.Response | Api.V1Subscription.AGetBoughLists.Response | Api.V1Subscription.AGetAvailableLists.Response | null = null

    if (this.state.isMySubscription) {
      res = await AGetBoughLists(listFormData)
    } else if (this.state.isAvailableSubscription) {
      res = await AGetAvailableLists(listFormData)
    } else {
      res = await AGetLists(listFormData)
    }
    
    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        subscriptionLists: res.data.values || [],
        total: res.data.total
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  columns:ColumnsType<Api.V1Component.SubscriptionVo>  = [
    {
      title: 'Index',
      render: (_val, _record, index) => index + 1,
      width: '50px',
      align: 'center'
    },
    {
      title: 'Subscription Name',
      render: (_val, record) => record.subscriptionName,
      align: 'center'
    },
    {
      title: 'Subscription Price',
      render: (_val, record) => record.price,
      width: '150px',
      align: 'center'
    },
    {
      title: 'Start Time & End Time',
      width: '300px',
      render: (_val, record) => <p>{dateFormat(record.startTime)} ~ {dateFormat(record.endTime)}</p>,
      align: 'center'
    },
    {
      title: 'Progress Rate',
      width: '300px',
      render: (_val, record) => {
        let percent = 0
        const currentTime = Date.now()
        const startTime = record.startTime * 1000
        const endTime = record.endTime * 1000
        if (currentTime > startTime) {
          if (currentTime > endTime) {
            percent = 100
          } else {
            percent = Math.floor((currentTime - startTime) / (endTime - startTime) * 100)
          }
          if (percent > 100) {
            percent = 100
          }
        }
        let status: ProgressProps['status'] = 'normal'
        if (percent > 0 && percent < 80) {
          status = 'active'
        } else if (percent > 80 && percent < 100) {
          status = 'exception'
        } else if (percent === 100) {
          status = 'success'
        }
        return <Progress percent={percent} status={status} format={() => `${percent}%`}></Progress>
      }
    },
    {
      title: 'Subscription Remark',
      render: (_val, record) => record.subscriptionRemark
    },
    {
      title: 'Options',
      align: 'center',
      render: (_val, record, index) => {
        let btnLists:any[] = []

        if (!this.state.isAvailableSubscription && !this.state.isMySubscription) {
          const disableAndEnabled = [1, 2, 4].includes(record.status) && [
            [1, 2].includes(record.status) && <Button onClick={() => this.disableOrEnabled(index, false)} type="link" key="Disable">Disable</Button>,
            [4].includes(record.status) && <Button onClick={() => this.disableOrEnabled(index, true)} type="link" key="Enabled">Enabled</Button>,
          ]
  
          const editor = [1, 4].includes(record.status) && [<Button onClick={() => this.editor(record)} type="link" key="Editor">Editor</Button>]
          if (disableAndEnabled) {
            btnLists = [...btnLists, ...disableAndEnabled]
          }
          if (editor) {
            btnLists = [...btnLists, ...editor]
          }
        }

        if (this.state.isAvailableSubscription) {
          btnLists = [...btnLists, <Button onClick={() => this.buySubscription(record)} type="link" key="Buy">Buy</Button>]
        }

        return (
          <Space>
            {btnLists}
          </Space>
        )
      }
    }
  ]

  disableOrEnabled = async (index: number, isUp: boolean) => {
    const currentTable = this.state.subscriptionLists[index]

    const res = await APostAction({
      id: currentTable.id,
      isUp
    })

    if (res.success) {
      message.success('Option success.')
      currentTable.status = isUp ? 2 : 4
      this.setState({
        subscriptionLists: this.state.subscriptionLists
      })
    } else {
      notification.error({
        message: res.message
      })
    }

  }

  editor = (itemData: Api.V1Component.SubscriptionVo) => {
    this.setState({
      formProps: {
        ...this.state.formProps,
        editorData: {
          subscriptionName: itemData.subscriptionName,
          subscriptionRemark: itemData.subscriptionRemark,
          startTime: itemData.startTime,
          endTime: itemData.endTime,
          price: itemData.price,
          templates: itemData.templates,
          id: itemData.id
        },
        show: true
      }
    })
  }

  add = () => {
    this.setState({
      formProps: {
        ...this.state.formProps,
        editorData: undefined,
        show: true
      }
    })
  }

  buySubscription = (item: Api.V1Component.SubscriptionVo) => {
    this.setState({
      buyScriptionProps: {
        ...this.state.buyScriptionProps,
        visible: true,
        id: item.id,
        subscriptionName: item.subscriptionName,
        subscriptionRemark: item.subscriptionRemark,
        startTime: item.startTime,
        endTime: item.endTime,
        price: item.price
      }
    })
  }

  renderBtn = () => {
    if (this.state.listFormData.isEffective) {
      return (
        <div style={{ marginBottom: '16px' }}>
          <Button onClick={this.add} type="primary">Add</Button>
        </div>
      )
    }
  }

  renderTable() {
    return (
      <>
        { this.renderBtn() }
        <Table
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.subscriptionLists}
          pagination={{
            onChange: this.getSubscriptionLists,
            total: this.state.total,
            pageSize: this.state.listFormData.pageSize,
            current: this.state.listFormData.pageIndex,
            size: "small"
          }}></Table>
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.formProps.show ? <SubscriptionForm { ...this.state.formProps } /> : this.renderTable()}
        <BuySubscription {...this.state.buyScriptionProps} key={this.state.buyScriptionProps.id} />
      </>
    )
  }
}

export default SubscriptionLists