import { RouteType } from "./router-config"

import TenantManage from 'pages/tenant/manage'
import TenantAdministrator from 'pages/tenant/administrator/index'
import User from 'pages/user-manage/user/index'
import UserGroup from 'pages/user-manage/group/index'
import Rate from 'pages/tenant/rate/index'

export const TenantRoute: RouteType[] = [
  {
    name: 'Tenant',
    icon: 'SolutionOutlined',
    path: '/tenant',
    moduleCode: 'tenant',
    route: [
      {
        path: '/tenant/manage',
        name: 'TenantManage',
        moduleCode: 'tenant.tenantManage',
        component: TenantManage,
        breadList: ['Tenant', 'Manage'],
      },
      {
        path: '/tenant/administrator',
        name: 'Administrator',
        moduleCode: 'tenant.administrator',
        component: TenantAdministrator,
        breadList: ['Tenant', 'Administrator'],
      },
      {
        path: '/tenant/rate',
        name: 'Rate',
        moduleCode: 'tenant.rate',
        component: Rate,
        breadList: ['Tenant', 'Rate'],
      },
      {
        path: '/tenant/group',
        name: 'User Group',
        moduleCode: 'tenant.userGroup',
        component: UserGroup,
        breadList: ['Tenant', 'User Group'],
      },
      {
        path: '/tenant/user-list',
        name: 'User',
        moduleCode: 'tenant.user',
        component: User,
        breadList: ['Tenant', 'User'],
      }
    ]
  }
]