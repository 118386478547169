import request from "../../utils/request";

/**
** api name: Sup
** api address: /api/Sup/Create
** request mode: POST
** interface description: 新增指定账户的用户
*/
export function APostCreate (params: Api.V1Sup.APostCreate.Request) {
  return request<Api.V1Sup.APostCreate.Response>(
    `/api/Sup/Create`,
    {
      method: "POST",
      data: params,
    }
  );
}

/**
** api name: Sup
** api address: /api/Sup/Lists
** request mode: POST
** interface description: 管理用户列表
*/
export function APostLists (params: Api.V1Sup.APostLists.Request) {
  return request<Api.V1Sup.APostLists.Response>(
    `/api/Sup/Lists`,
    {
      method: "POST",
      data: params,
    }
  );
}