import { Modal, notification, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { AGetGetFileUploadHistory } from "services/v1/calculator";
import { dateFormat } from "utils";

interface UploadHistoryProps {
  onCancel: () => void
  visible: boolean
}

interface UploadHistoryState {
  historyLists: Api.V1Component.FileUploadHistoryVo[]
  listFormData: Api.V1Calculator.AGetGetFileUploadHistory.Request
  loading: boolean
  total: number
}

export class UploadHistory extends React.Component<UploadHistoryProps, UploadHistoryState> {
  state: UploadHistoryState = {
    historyLists: [],
    listFormData: {
      pageIndex: 1,
      pageSize: 10
    },
    loading: true,
    total: 0
  }

  componentDidMount () {
    this.getUploadFileHistoryLists()
  }

  historyColumns: ColumnsType<Api.V1Component.FileUploadHistoryVo> = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
    },
    {
      title: 'Create Time',
      dataIndex: 'createTime',
      render: (_val, record) => dateFormat(record.createTime)
    },
    {
      title: 'User Name',
      dataIndex: 'userName'
    }
  ]

  getUploadFileHistoryLists = async (page?: number) => {
    const listFormData = {
      ...this.state.listFormData,
      pageIndex: page || this.state.listFormData.pageIndex
    }

    this.setState({
      loading: true,
      listFormData: listFormData
    })

    const res = await AGetGetFileUploadHistory(listFormData)

    this.setState({
      loading: false
    })
    
    if (res.success) {
      this.setState({
        historyLists: res.data.values || []
      })
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <>
        <Modal
          title="Upload file history"
          visible={this.props.visible}
          onCancel={this.props.onCancel}>
          <Table
            loading={this.state.loading}
            columns={this.historyColumns}
            dataSource={this.state.historyLists}
            pagination={{
              onChange: this.getUploadFileHistoryLists,
              total: this.state.total,
              pageSize: this.state.listFormData.pageSize,
              current: this.state.listFormData.pageIndex,
              size: "small"
            }}></Table>
        </Modal>
      </>
    )
  }
}