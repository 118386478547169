import request from "../../utils/request";

/**
** api name: General
** api address: /api/General/SlideCode/{token}
** request mode: GET
** interface description: 获取产生的随机数据
*/
export function AGetSlideCode_Token (params: Api.V1General.AGetSlideCode_Token.Request) {
  return request<Api.V1General.AGetSlideCode_Token.Response>(
    `/api/General/SlideCode/${params.token}`,
    {
      method: "GET"
    }
  );
}
        
