import { Form, FormInstance, Input, Modal, notification, Space } from "antd";
import React from "react";
import { APostChangeEmail } from "services/v1/tenant-manage";
import GetVerifyCode from "../../../utils/verify-code"

export interface ModifyEmailProps {
  visible: boolean
  id: number
  onClose: (isRefresh?: boolean) => void
}

interface ModifyEmailState {
  loading: boolean
  verifyImgSrc: string;
  random: string;
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export class ModifyEmail extends React.Component<ModifyEmailProps, ModifyEmailState> {
  state: ModifyEmailState = {
    loading: false,
    verifyImgSrc: '',
    random: ''
  }

  formRef = React.createRef<FormInstance<Api.V1TenantManage.APostChangeEmail.Request>>()

  componentWillMount() {
    this.getVerifyCode()
  }
  
  getVerifyCode = async() => {
    const data = await GetVerifyCode()
    this.setState({
        verifyImgSrc: data.imgSrc,
        random: data.random
    })
  }

  onFinish = async () => {
    const data = await this.formRef.current?.validateFields()

    this.setState({
      loading: true
    })

    const res = await APostChangeEmail({
      coverEmail: data?.coverEmail,
      userId: this.props.id,
      slideProof: this.state.random,
      verifyCode: data?.verifyCode
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Modify email success.'
      })
      
      this.props.onClose()
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    return (
      <Modal
        title="Modify email"
        visible={this.props.visible}
        onCancel={() => this.props.onClose()}
        onOk={this.onFinish}>
        <Form 
          ref={this.formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="New email"
            name="coverEmail"
            rules={request}
          >
            <Input placeholder="Please enter new email"/>
          </Form.Item>
          
          <Form.Item label="Verify Code" name="verifyCode" rules={request}>
              <Space>
                  <Input placeholder="input the verify code" />
                  { this.state.verifyImgSrc &&
                    <img style={{ height: '28px' }} onClick={this.getVerifyCode} src={this.state.verifyImgSrc} alt="verify code"></img>
                  }
              </Space>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}