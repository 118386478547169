import request from "../../utils/request";

/**
** api name: Group
** api address: /api/Group/Create
** request mode: POST
** interface description: 创建用户组
*/
export function APostCreate (params: Api.V1Group.APostCreate.Request) {
  return request<Api.V1Group.APostCreate.Response>(
    `/api/Group/Create`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: Group
** api address: /api/Group/Lists
** request mode: POST
** interface description: 获取用户组列表
*/
export function APostLists (params: Api.V1Group.APostLists.Request) {
  return request<Api.V1Group.APostLists.Response>(
    `/api/Group/Lists`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: Group
** api address: /api/Group/Lists/{groupId}
** request mode: GET
** interface description: 获取用户组已关联用户列表
*/
export function AGetLists_GroupId (params: Api.V1Group.AGetLists_GroupId.Request) {
  return request<Api.V1Group.AGetLists_GroupId.Response>(
    `/api/Group/Lists/${params.groupId}`,
    {
      method: "GET",
      params,
    }
  );
}