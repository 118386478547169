import request from "../../utils/request";

/**
** api name: Subscription
** api address: /api/Subscription/Create
** request mode: POST
** interface description: 创建订阅
*/
export function APostCreate (params: Api.V1Subscription.APostCreate.Request) {
  return request<Api.V1Subscription.APostCreate.Response>(
    `/api/Subscription/Create`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: Subscription
** api address: /api/Subscription/Lists
** request mode: GET
** interface description: 获取订阅列表
*/
export function AGetLists (params: Api.V1Subscription.AGetLists.Request) {
  return request<Api.V1Subscription.AGetLists.Response>(
    `/api/Subscription/Lists`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Subscription
** api address: /api/Subscription/BoughLists
** request mode: GET
** interface description: 获取已购买订阅列表
*/
export function AGetBoughLists (params: Api.V1Subscription.AGetBoughLists.Request) {
  return request<Api.V1Subscription.AGetBoughLists.Response>(
    `/api/Subscription/BoughLists`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Subscription
** api address: /api/Subscription/AvailableLists
** request mode: GET
** interface description: 获取可购买订阅列表
*/
export function AGetAvailableLists (params: Api.V1Subscription.AGetAvailableLists.Request) {
  return request<Api.V1Subscription.AGetAvailableLists.Response>(
    `/api/Subscription/AvailableLists`,
    {
      method: "GET",
      params,
    }
  );
}
        
/**
** api name: Subscription
** api address: /api/Subscription/Purchase/{id}
** request mode: POST
** interface description: 购买订阅
*/
export function APostPurchase_Id (params: Api.V1Subscription.APostPurchase_Id.Request) {
  return request<Api.V1Subscription.APostPurchase_Id.Response>(
    `/api/Subscription/Purchase/${params.id}`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: Subscription
** api address: /api/Subscription/Update
** request mode: PUT
** interface description: 编辑订阅
*/
export function APutUpdate (params: Api.V1Subscription.APutUpdate.Request) {
  return request<Api.V1Subscription.APutUpdate.Response>(
    `/api/Subscription/Update`,
    {
      method: "PUT",
      data: params,
    }
  );
}
        
/**
** api name: Subscription
** api address: /api/Subscription/Action
** request mode: POST
** interface description: 操作订阅
*/
export function APostAction (params: Api.V1Subscription.APostAction.Request) {
  return request<Api.V1Subscription.APostAction.Response>(
    `/api/Subscription/Action`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
