import { Select, SelectProps } from 'antd'
import React from 'react'
import { AGetPols } from 'services/v1/data-dic'

interface PortOfDepartureState {
  loading: boolean
  lists: Api.V1Component.PolVo[]
  disable: boolean
}

interface PortOfDepartureProps {
  value?: string
  onChange?: SelectProps<string>['onChange']
}

export class PortOfDeparture extends React.Component<PortOfDepartureProps, PortOfDepartureState> {

  state:PortOfDepartureState = {
    loading: false,
    lists: [],
    disable: false
  }

  getLists = async (keyword?: string) => {
    this.setState({
      loading: true
    })
    const res = await AGetPols({
      pageIndex: 1,
      pageSize: 1000,
      keyword: keyword
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      this.setState({
        lists: res.data.values || []
      })
    }
  }

  render() {
    return (
      <div>
        <Select<string>
          value={this.props.value}
          disabled={this.state.disable}
          showArrow
          filterOption
          showSearch
          onChange={this.props.onChange}
          loading={this.state.loading}
          onFocus={() => this.state.lists.length === 0 && this.getLists()}
          allowClear
        >
          { this.state.lists.map(item => <Select.Option value={item.pol || ''} key={item.pol}>{item.pol}</Select.Option>) }
        </Select>
      </div>
    )
  }
}
