import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, Button, Divider, Layout, Menu, Result, Space, Spin } from 'antd'
import { AppstoreOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Switch, Route } from "react-router-dom";
import routes, { RouteType } from '../routes/router-config'
import * as Icon from '@ant-design/icons';
import Authorized, { getCurrentRoute, getRouteList } from 'routes/authorized';
import { fetchPermissionData } from 'reducers';
import LogoBg from 'assets/ligentia-logo.svg'
import Logo from 'assets/ligentia-logo.svg'
import "./layout-container"
import ModifyPassword from './modify-password'
import SwitchClient from './switch-client';

const { Sider, Header, Content } = Layout;

export interface LayoutProps {
    /** 菜单栏是否收起 */
    collapsed?: boolean;
    /** dispatch */
    dispatch?: any;
    /** dispatch */
    location?: any;
    /** dispatch */
    history?: any;
    /** 面包屑 */
    breadList?: Array<string>;
    pageTitle?: string;
    /** 菜单权限数组 */
    pageClaims?: Array<string>;
    /** 按你权限数组 */
    btnClaims?: Array<string>;
    /** 当前登录的用户名 */
    username?: string;
    tenantName?: string;
    /** 权限接口是否请求完成 */
    layoutLoading?: boolean;
}

interface LayoutStates {
    pageLoading: boolean;
    menuKeys?: Array<string>;
    openKeys?: Array<string>;
    isFireFox?: boolean;
    showModifyPassword: boolean;
    showSwitchClient: boolean;
}

class LayoutContainer extends React.Component<LayoutProps, LayoutStates>{

    state: LayoutStates = {
        pageLoading: true,
        showModifyPassword: false,
        showSwitchClient: false
    }

    componentDidMount() {
        this.getInitSetting();
        this.whatBrowser();
    }

    UNSAFE_componentWillReceiveProps(nextProps: LayoutProps) {
        document.title = nextProps.breadList && nextProps.breadList.length ? nextProps.breadList?.join('-') : 'Ligentix Landed Cost Calculation';
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.settingByPathname(nextProps.location.pathname);
        }
    }

    iconFunc = (name: string, level: number) => {
        return React.createElement(Icon && (Icon as any)[name], {
            style: { fontSize: this.props.collapsed && level === 1 ? '20px' : '16px', fontWeight: 'bold' }
        })
    }

    // 判断浏览器类型
    whatBrowser() {
        const userAgent = navigator.userAgent.toLowerCase();
        this.setState({
            isFireFox: !!userAgent && userAgent.indexOf('firefox') > 0
        })
    }

    /** 通过pathname获取设置侧边栏，面包屑 */
    settingByPathname = (pathname: string) => {
        const { dispatch } = this.props;
        const currentRoute = getCurrentRoute(routes, pathname);
        if (currentRoute) {
            dispatch({
                type: 'breadList',
                data: currentRoute.breadList
            })
        }
        this.setState({
            menuKeys: [pathname]
        })
        const pathnameList = pathname.split('/')
        if (pathnameList && pathnameList.length > 2) {
            const list: string[] = [];
            let str = '';
            pathnameList.forEach((item, index) => {
                if (index < pathnameList.length - 1 && index > 0) {
                    str = str + '/' + item;
                    list.push(str)
                }
            });
            this.setState({
                openKeys: this.props.collapsed ? [] : list,
                menuKeys: [pathname]
            })
        }

    }

    getInitSetting = () => {
        this.setState({
            pageLoading: true
        }, () => {
            const { dispatch, location } = this.props;
            fetchPermissionData(dispatch).then(res => {
                setTimeout(() => {
                    this.setState({
                        pageLoading: false
                    })
                }, 1000);
            })
            this.settingByPathname(location.pathname)
        })
    }

    /**
     * 菜单-点击
     * @param e
     */
    MenuClick = (e: any) => {
        this.settingByPathname(e.key)
    }

    renderMain = () => {
        const { location } = this.props;
        const { pathname } = location;
        const currentRoute = getCurrentRoute(routes, pathname);
        const routeList = getRouteList(routes);
        if (currentRoute) {
            return (
                <Switch>
                    {routeList.map(route => {
                        const { ...rest } = route;
                        return (
                            <Authorized {...rest} key={route.path} {...this.props}>
                                <Route path={route.path} component={route.component} />
                            </Authorized>
                        )
                    })}
                </Switch>
            )
        }
        return (
            <Result
                status="404"
                subTitle="Sorry, the page you visited does not exist！"
                extra={
                    <Button type="primary">Return</Button>
                }
            />
        )
    }

    /** 侧边栏收起、展开 */
    onCollapse = () => {
        const { dispatch, collapsed } = this.props;
        dispatch({
            type: 'menuCollapsed',
            data: !collapsed
        });
    }

    openChange = (keys: React.Key[]) => {
        this.setState({
            openKeys: keys as string[]
        })
    }

    layout = () => {
        window.localStorage.clear()
        window.location.replace(`/login?redirect=${encodeURIComponent(window.location.pathname)}`)
    }

    renderMenu = (menuData: RouteType, level: number) => {
        return (
            <Menu.SubMenu
                className={`layout-submenu level${level}`}
                key={menuData.path}
                title={menuData.name}
                icon={menuData.icon ? this.iconFunc(menuData.icon, 1) : null}
            >
                {menuData.route?.map(subData => {
                    const isMenu = (subData.moduleCode && this.props.pageClaims?.indexOf(subData.moduleCode) !== -1 && !subData.hidden) || !subData.moduleCode
                    if (isMenu) {
                        if (subData.route) {
                            return this.renderMenu(subData, 3)
                        }
                        return (
                            <Menu.Item
                                className={`layout-last-menu level${level}`}
                                key={subData.path}
                                icon={subData.icon ? this.iconFunc(subData.icon, 2) : null}
                                onClick={this.MenuClick}
                            >
                                <Link to={subData.path}>
                                    {subData.name}
                                </Link>
                            </Menu.Item>
                        )
                    }
                    return null;
                })}
            </Menu.SubMenu>
        )
    }

    render() {
        const { breadList, pageTitle, username, collapsed, tenantName } = this.props;
        const { menuKeys, openKeys, isFireFox } = this.state;
        if (!this.props.layoutLoading) {
            return (
                <Layout style={{ minHeight: '100vh' }} className={`website-root-layout ${isFireFox ? 'website-firefox-layout' : ''}`}>
                    <Sider theme="light" className={collapsed ? 'collapsed' : ''} collapsible collapsedWidth={collapsed ? 50 : 160} collapsed={collapsed} onCollapse={this.onCollapse} trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}>
                        <div style={{ padding: "11px 12px 11px 12px" }} >
                            <img className={collapsed ? "small" : "large"} src={collapsed ? Logo : LogoBg} alt="" />
                        </div>
                        <Menu
                            mode="inline"
                            className="website-root-menu"
                            inlineIndent={12}
                            defaultSelectedKeys={menuKeys}
                            selectedKeys={menuKeys}
                            openKeys={openKeys}
                            onOpenChange={this.openChange}
                            style={{
                                height: 'calc(100vh - 108px)'
                            }}
                        >
                            {routes.map(menuData => {
                                const isShow = (menuData.moduleCode && this.props.pageClaims?.indexOf(menuData.moduleCode) !== -1 && !menuData.hidden) || !menuData.moduleCode
                                if (menuData.name && isShow) {
                                    if (menuData.route) {
                                        return this.renderMenu(menuData, 2)
                                    }
                                    return (
                                        <Menu.Item
                                            className="root-submenu"
                                            key={menuData.path}
                                            icon={menuData.icon ? this.iconFunc(menuData.icon, 1) : null}
                                            onClick={this.MenuClick}
                                        >
                                            <Link to={menuData.path}>
                                                {menuData.name}
                                            </Link>
                                        </Menu.Item>
                                    )
                                }
                                return null
                            })}
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-layout-background" style={{ padding: 0 }}>
                            <Space direction='horizontal' className="website-header">
                                <Breadcrumb style={{ paddingLeft: '12px' }}>
                                    {breadList && breadList.map(item => <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>)}
                                    {pageTitle && pageTitle !== 'list' && <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>}
                                </Breadcrumb>
                                <div className="website-header-menu">
                                    <Space split={<Divider type="vertical" />}>
                                        {username}
                                        <Button type="link" onClick={() => this.setState({ showSwitchClient: true })}><AppstoreOutlined />{tenantName}</Button>
                                        <Button type="link" onClick={() => this.setState({ showModifyPassword: true })}>Modify Password</Button>
                                        <Button type="link" className="logout-btn" onClick={this.layout}>Logout</Button>
                                    </Space>
                                </div>
                            </Space>
                        </Header>
                        <Content className="website-main-layout" style={{ padding: 12 }} >
                            {this.renderMain()}
                        </Content>
                    </Layout>

                    <ModifyPassword
                        showModel={this.state.showModifyPassword}
                        onClose={() => this.setState({ showModifyPassword: false })}
                        key={this.state.showModifyPassword.toString()}/>
                    <SwitchClient
                        showModel={this.state.showSwitchClient}
                        onClose={() => this.setState({ showSwitchClient: false })}
                        key={this.state.showSwitchClient.toString()}/>
                </Layout>
            );
        }
        return (
            <div className="body-loading">
                <Spin size="large" tip="loading..." />
            </div>
        )
    }
};

export default connect((store: any) => {
    return {
        collapsed: store.collapsed,
        breadList: store.breadList,
        pageTitle: store.pageTitle,
        pageClaims: store.pageClaims,
        btnClaims: store.btnClaims,
        username: store.username,
        tenantName: store.tenantName,
        layoutLoading: store.layoutLoading
    }
})(
    LayoutContainer
);