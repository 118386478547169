import React from 'react'
import { DatePicker, Input, InputProps, Radio, RadioChangeEvent, Select, SelectProps, Space } from "antd"

export type PalletizationRadioType = {
  label: 'YES' | 'NO' | 'by Vendor',
  value: 'Yes' | 'No' | 'by Vendor'
}
const palletizationRadioArr: PalletizationRadioType[] = [
  { label: 'YES', value: 'Yes' },
  { label: 'NO', value: 'No' },
  { label: 'by Vendor', value: 'by Vendor' },
]
export const PalletizationRadio = (props: { onChange?: (e: RadioChangeEvent) => void, value?: PalletizationRadioType['value'] }) => {
  return (
    <Radio.Group
      value={props.value}
      onChange={(e) => {
        props.onChange && props.onChange(e)
      }}
    >
      {palletizationRadioArr.map(item => <Radio.Button value={item.value} className="radio-btn">{item.label}</Radio.Button>)}
    </Radio.Group>
  )
}

export type BuyingInCoterm_Type = {
  label: 'EXW' | 'FOB',
  value: 'EXW' | 'FOB'
}
const BuyingInCoterm_Arr: BuyingInCoterm_Type[] = [
  { label: 'EXW', value: 'EXW' },
  { label: 'FOB', value: 'FOB' }
]
export const BuyingInCoterm = (props: { value?: string,  onChange?: SelectProps<string>['onChange'] }) => {
  return (
    <Select<string>
      value={props.value}
      onChange={props.onChange}
    >
      {BuyingInCoterm_Arr.map(item => <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>)}
    </Select>
  )
}

export type Mode_Type = {
  label: 'FCL/AUTO' | 'LCL',
  value: 'FCL' | 'LCL'
}
const Mode_Arr: Mode_Type[] = [
  { label: 'FCL/AUTO', value: 'FCL' },
  { label: 'LCL', value: 'LCL' }
]
export const Mode = (props: { value?: string,  onChange?: SelectProps<string>['onChange'] }) => {
  return (
    <Select<string>
      value={props.value}
      onChange={props.onChange}
    >
      {Mode_Arr.map(item => <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>)}
    </Select>
  )
}

export type LWHValue = { L?: number, W?: number, H?: number }
export const LWH = (props: { value?: LWHValue,  onChange?: (value: LWHValue) => void }) => {
  const onChange = (type: 'L' | 'W' | 'H', value: string) => {
    if (props.value) {
      props.onChange && props.onChange({...props.value, [type]: value || undefined})
    } else {
      props.onChange && props.onChange({[type]: value || undefined})
    }
  }

  return (
    <>
      <Space>
        <Input onChange={(e) => onChange('L', e.target.value)} value={props.value?.L} type="number" placeholder="L" />
        X
        <Input onChange={(e) => onChange('W', e.target.value)} value={props.value?.W} type="number" placeholder="W" />
        X
        <Input onChange={(e) => onChange('H', e.target.value)} value={props.value?.H} type="number" placeholder="H" />
      </Space>
    </>
  )
}

export const CTNGW = (props: { value?: number,  onChange?: InputProps['onChange'] }) => {
  return (
    <Input onChange={props.onChange} value={props.value} type="number" placeholder="Please enter value" />
  )
}

export const PiecesPCarton = (props: { value?: number,  onChange?: InputProps['onChange'] }) => {
  return (
    <Input onChange={props.onChange} value={props.value} type="number" placeholder="Please enter value" />
  )
}

export const Description = (props: { value?: string, onChange?: (val?: string) => void }) => {
  return <Input value={props.value} onChange={(e) => props.onChange && props.onChange(e.target.value)} placeholder="Please enter value" />
}

export const UnitPrice = (props: { value?: number, onChange?: (val?: number) => void }) => {
  return <Input value={props.value} onChange={(e) => props.onChange && props.onChange(e.target.value ? Number(e.target.value) : undefined)} type="number" placeholder="Please enter value" />
}

export const GSCCommission = (props: { value?: number, onChange?: (val?: number) => void }) => {
  return <Input
            addonAfter="%"
            value={props.value}
            onChange={(e) => props.onChange && props.onChange(e.target.value ? Number(e.target.value) : undefined)}
            type="number"
            placeholder="Please enter value" />
}

export const PiecesQTY = (props: { value?: number, onChange?: (val?: number) => void }) => {
  return <Input
            value={props.value}
            onChange={(e) => props.onChange && props.onChange(e.target.value ? Number(e.target.value) : undefined)}
            type="number"
            placeholder="Please enter value" />
}

export type Selling_INCOTERM_Type = {
  label: 'CPT' | 'DAP' | 'DDP EXCL GST' | 'DDP INCL GST',
  value: 'CPT' | 'DAP' | 'DDP EXCL GST' | 'DDP INCL GST'
}
const Selling_INCOTERM_Arr: Selling_INCOTERM_Type[] = [
  { label: 'CPT', value: 'CPT' },
  { label: 'DAP', value: 'DAP' },
  { label: 'DDP EXCL GST', value: 'DDP EXCL GST' },
  { label: 'DDP INCL GST', value: 'DDP INCL GST' },
]
export const SellingINCOTERM = (props: { onChange?: (value?: Selling_INCOTERM_Type['value']) => void, value?: Selling_INCOTERM_Type['value'] }) => {
  return (
    <Select<Selling_INCOTERM_Type['value']>
      value={props.value}
      onChange={(val) => props.onChange && props.onChange(val)}
      allowClear
    >
      {Selling_INCOTERM_Arr.map(item => <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>)}
    </Select>
  )
}

export const Step3Date = (props: { onChange?: (value: moment.Moment | null) => void, value?: moment.Moment | null }) => {
  return <DatePicker
            value={props.value}
            onChange={(value) => props.onChange && props.onChange(value)}/>
}

export const Step3Input = (props: { value?: string, onChange?: (val?: string) => void }) => {
  return <Input value={props.value} onChange={(e) => props.onChange && props.onChange(e.target.value)} placeholder="Please enter value" />
}

interface Select3Props {
  value?: string
  onChange?: (val?: string) => void
}

export const Step3Select = function(props: { value?: string,  onChange?: (val?: string) => void, options: {label: string, value: string}[] }) {
  return (
    <Select<string>
      value={props.value}
      onChange={(val) => props.onChange && props.onChange(val)}
    >
      {props.options.map(item => <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>)}
    </Select>
  )
}

export const InsuranceRequired = (props: { onChange?: (val?: boolean) => void, value?: boolean }) => {
  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]
  return (
    <Radio.Group
      value={props.value}
      onChange={(e) => {
        props.onChange && props.onChange(e.target.value)
      }}
    >
      {options.map(item => <Radio.Button value={item.value} className="radio-btn">{item.label}</Radio.Button>)}
    </Radio.Group>
  )
}
 
export const TransportMode = (props: Select3Props) => {
  return Step3Select({
    ...props,
    options: [
      { label: 'SEA', value: 'SEA' }
    ]
  })
}
 
export const ImportVAT = (props: Select3Props) => {
  return Step3Select({
    ...props,
    options: [
      { label: 'Importing Client', value: 'Importing Client' },
      { label: 'Entity', value: 'Entity' }
    ]
  })
}
 
export const LigentiaInvoice = (props: Select3Props) => {
  return Step3Select({
    ...props,
    options: [
      { label: 'Malaysia', value: 'Malaysia' },
      { label: 'Netherlands', value: 'Netherlands' },
      { label: 'UK', value: 'UK' },
      { label: 'Hong Kong', value: 'Hong Kong' },
      { label: 'USA', value: 'USA' },
      { label: 'Korea', value: 'Korea' },
      { label: 'Thailand', value: 'Thailand' },
      { label: 'Singapore', value: 'Singapore' },
      { label: 'Vietnam', value: 'Vietnam' },
      { label: 'Australia', value: 'Australia' },
      { label: 'New Zealand', value: 'New Zealand' },
      { label: 'Spain', value: 'Spain' },
      { label: 'Japan', value: 'Japan' },
      { label: 'Canada', value: 'Canada' },
    ]
  })
} 

export const YourCurrency = (props: Select3Props) => {
  return Step3Select({
    ...props,
    options: [
      { label: 'USD', value: 'USD' },
      { label: 'EUR', value: 'EUR' },
      { label: 'GBP', value: 'GBP' },
      { label: 'HKD', value: 'HKD' },
      { label: 'CAD', value: 'CAD' }
    ]
  })
}