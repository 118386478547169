import request from "../../utils/request";

/**
** api name: TenantManage
** api address: /api/TenantManage/Create
** request mode: POST
** interface description: 新增租户管理员
*/
export function APostCreate (params: Api.V1TenantManage.APostCreate.Request) {
  return request<Api.V1TenantManage.APostCreate.Response>(
    `/api/TenantManage/Create`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: TenantManage
** api address: /api/TenantManage/Lists
** request mode: POST
** interface description: 租户管理员列表
*/
export function APostLists (params: Api.V1TenantManage.APostLists.Request) {
  return request<Api.V1TenantManage.APostLists.Response>(
    `/api/TenantManage/Lists`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: TenantManage
** api address: /api/TenantManage/ChangePassWord
** request mode: POST
** interface description: 修改租户管理员密码
*/
export function APostChangePassWord (params: Api.V1TenantManage.APostChangePassWord.Request) {
  return request<Api.V1TenantManage.APostChangePassWord.Response>(
    `/api/TenantManage/ChangePassWord`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: TenantManage
** api address: /api/TenantManage/ChangeEmail
** request mode: POST
** interface description: 修改租户管理员邮箱
*/
export function APostChangeEmail (params: Api.V1TenantManage.APostChangeEmail.Request) {
  return request<Api.V1TenantManage.APostChangeEmail.Response>(
    `/api/TenantManage/ChangeEmail`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: TenantManage
** api address: /api/TenantManage/EnabledSet
** request mode: POST
** interface description: 设置租户管理员状态
*/
export function APostEnabledSet (params: Api.V1TenantManage.APostEnabledSet.Request) {
  return request<Api.V1TenantManage.APostEnabledSet.Response>(
    `/api/TenantManage/EnabledSet`,
    {
      method: "POST",
      data: params,
    }
  );
}

export function AGetTenantSelectList(params: Api.V1TenantManage.AGetTenantSelectList.Request) {
  return request<Api.V1TenantManage.AGetTenantSelectList.Response>(
    `/api/TenantManage/TenantSelectList`,
    {
      method: "GET",
      params,
    }
  );
}    

export function APutTenantSet(params: Api.V1TenantManage.APutTenantSet.Request) {
  return request<Api.V1TenantManage.APutTenantSet.Response>(
    `/api/TenantManage/TenantSet`,
    {
      method: "PUT",
      data: params,
    }
  );
}    
