import React from "react"; 
import { Button, Col, Divider, Form, FormInstance, message, notification, Row, Space } from "antd";
import { PortOfDeparture } from './portOfDeparture'
import { DeliveryLocation } from './deliveryLocation'
import { PortOfArrival } from './portOfArrival'
import { HSCode } from "./HS_Code";
import { BuyingInCoterm, Mode, PalletizationRadio, LWH, CTNGW, PiecesPCarton, PalletizationRadioType, LWHValue, Description, UnitPrice, GSCCommission, PiecesQTY, SellingINCOTERM, Selling_INCOTERM_Type, Mode_Type, BuyingInCoterm_Type, Step3Date, Step3Input, InsuranceRequired, TransportMode, ImportVAT, LigentiaInvoice, YourCurrency } from './item'
import { APostGetTemplateTestResult } from "services/v1/template";
import moment from 'moment';

export interface Step3Props {
  onBack: () => void
  onSuccess?: (result: Api.V1Component.CalculateResultVo) => void
  formula?: Api.V1Component.FormulaConfiguration
  commonConfig?: Api.V1Component.ConstantConfigurationVo
}

interface Step3State {
  palletizationRadioType?: PalletizationRadioType['value']
  podCountry?: string
  loading: boolean
}

interface FormData {
  // Input the summary information of quitation
  admRequestDate?: moment.Moment
  brandProject?: string
  payImportVat?: string
  cargoReadyDate?: moment.Moment
  insuranceRequired?: boolean
  ligentiaInvoice?: string
  ligentiaQuoteDate?: moment.Moment
  transportMode?: string
  currency?: string

  // Input the cargo information
  itemDescription?: string
  buyingIncoterm?: BuyingInCoterm_Type['value']
  mode?: Mode_Type['value']
  palletization?: PalletizationRadioType['value']
  originPol?: string
  deliveryLocation?: string
  destinationPod?: string
  hsValue?: string
  newUnitPrice?: number
  gscCommission?: number
  piecesQTY?: number
  sellingIncoterm?: Selling_INCOTERM_Type['value']
  LWH?: LWHValue
  piecesPCarton?: number
  ctnGw?: number
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

export class TemplateStep3 extends React.Component<Step3Props, Step3State> {

  constructor(props: Step3Props) {
    super(props)
    const nowDate = moment()
    this.initFormValue = {
      palletization: 'No',
      buyingIncoterm: 'FOB',
      mode: 'FCL',
      admRequestDate: nowDate,
      ligentiaQuoteDate: nowDate,
      insuranceRequired: true,
      transportMode: 'SEA',
      currency: 'USD'
    }

    this.state.palletizationRadioType = this.initFormValue.palletization
  }

  state: Step3State = {
    podCountry: '',
    loading: false
  }

  initFormValue: FormData | undefined

  onFinish = async (data: FormData) => {
    
    this.setState({
      loading: true
    })

    const res = await APostGetTemplateTestResult({
      admRequestDate: data.admRequestDate?.toISOString(),
      brandProject: data.brandProject,
      payImportVat: data.payImportVat,
      cargoReadyDate: data.cargoReadyDate?.toISOString(),
      insuranceRequired: data.insuranceRequired,
      ligentiaInvoice: data.ligentiaInvoice,
      ligentiaQuoteDate: data.ligentiaQuoteDate?.toISOString(),
      transportMode: data.transportMode,
      currency: data.currency,

      itemDescription: data.itemDescription,
      termSelection: data.buyingIncoterm && data.sellingIncoterm ? `${data.buyingIncoterm} - ${data.sellingIncoterm}` : '',
      mode: data.mode,
      palletization: data.palletization,
      originPol: data.originPol,
      deliveryLocation: data.deliveryLocation,
      destinationPod: data.destinationPod,
      hsCode: data.hsValue,
      newUnitPrice: data.newUnitPrice,
      gscCommission: typeof data.gscCommission !== 'undefined' ? data.gscCommission / 100 : undefined,
      indicateQuantity: data.piecesQTY,
      width: data.LWH?.W,
      height: data.LWH?.H,
      length: data.LWH?.L,
      pcsCarton: data.piecesPCarton,
      weight: data.ctnGw,
      formula: this.props.formula,
      commonConfig: this.props.commonConfig
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      message.success(res.message)
      this.props.onSuccess && this.props.onSuccess(res.data)
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  formRef = React.createRef<FormInstance<FormData>>()

  render() {
    return (
      <>
      <Form<FormData>
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={this.onFinish}
        autoComplete="off"
        style={{width: '1400px'}}
        validateMessages={validateMessages}
        ref={this.formRef}
        initialValues={this.initFormValue}
      >
        <Divider orientation="left">Input the summary information of quitation</Divider>
        <Row>
          <Col span={11}>
            <Form.Item
              label="Request Date"
              name="admRequestDate"
            >
              <Step3Date />
            </Form.Item>

            <Form.Item
              label="Ligentia Quote Date"
              name="ligentiaQuoteDate"
            >
              <Step3Date />
            </Form.Item>

            <Form.Item
              label="Cargo Ready Date"
              name="cargoReadyDate"
            >
              <Step3Date />
            </Form.Item>

            <Form.Item
              label="Brand/Project"
              name="brandProject"
            >
              <Step3Input />
            </Form.Item>

            <Form.Item
              label="Insurance Required?"
              name="insuranceRequired"
            >
              <InsuranceRequired />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label="Transport Mode"
              name="transportMode"
            >
              <TransportMode />
            </Form.Item>

            <Form.Item
              label="Who will pay import VAT?"
              name="payImportVat"
            >
              <ImportVAT />
            </Form.Item>

            <Form.Item
              label="Who should Ligentia invoice?"
              name="ligentiaInvoice"
            >
              <LigentiaInvoice />
            </Form.Item>

            <Form.Item
              label="Select your currency"
              name="currency"
            >
              <YourCurrency />
            </Form.Item>
          </Col>
        </Row>


        <Divider orientation="left">Input the cargo information</Divider>
        <Row>
          <Col span={11}>
            <Form.Item
              label="Item, material description"
              name="itemDescription"
            >
              <Description />
            </Form.Item>

            <Form.Item
              label="Buying In Coterm"
              name="buyingIncoterm"
            >
              <BuyingInCoterm />
            </Form.Item>
            
            <Form.Item
              label="Mode"
              name="mode"
            >
              <Mode />
            </Form.Item>

            <Form.Item
              label="Palletisation before delivery?"
              name="palletization"
            >
              <PalletizationRadio onChange={(value) => this.setState({ palletizationRadioType: value.target.value })} />
            </Form.Item>

            <Form.Item
              label="Port Of Departure"
              name="originPol"
            >
              <PortOfDeparture />
            </Form.Item>

            <Form.Item
              label="Delivery Location"
              name="deliveryLocation"
            >
              <DeliveryLocation
                palletization={this.state.palletizationRadioType}
                onChangePortOfArrival={(val) => this.formRef.current?.setFieldsValue({ destinationPod: val })}/>
            </Form.Item>

            <Form.Item
              label="Port of Arrival"
              name="destinationPod"
            >
              <PortOfArrival
                onChangeCode={(val) => this.setState({ podCountry: val })}
                palletization={this.state.palletizationRadioType} />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label="HS Code"
              name="hsValue"
            >
              <HSCode podCountry={this.state.podCountry} />
            </Form.Item>

            <Form.Item
              label="Unit Price (USD)"
              name="newUnitPrice"
            >
              <UnitPrice />
            </Form.Item>

            <Form.Item
              label="GSC Commission%"
              name="gscCommission"
            >
              <GSCCommission />
            </Form.Item>

            <Form.Item
              label="Pieces QTY"
              name="piecesQTY"
            >
              <PiecesQTY />
            </Form.Item>

            <Form.Item
              label="Selling INCOTERM"
              name="sellingIncoterm"
            >
              <SellingINCOTERM />
            </Form.Item>

            <Form.Item
              label="Carton Measurement(L x W x H)CM"
              name="LWH"
            >
              <LWH />
            </Form.Item>

            <Form.Item
              label="Piecesp/ Carton"
              name="piecesPCarton"
            >
              <PiecesPCarton  />
            </Form.Item>

            <Form.Item
              label="GTN_GW"
              name="ctnGw"
            >
              <CTNGW />
            </Form.Item>

          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Space>
            <Button loading={this.state.loading} onClick={this.props.onBack}>Back</Button>
            <Button loading={this.state.loading} type="primary" htmlType="submit">
              Next
            </Button>
          </Space>
        </Form.Item>
      </Form>
      </>
    )
  }
}