import request from "../../utils/request";

/**
** api name: Tenant
** api address: /api/Tenant/Create
** request mode: POST
** interface description: 创建租户信息
*/
export function APostCreate (params: Api.V1Tenant.APostCreate.Request) {
  return request<Api.V1Tenant.APostCreate.Response>(
    `/api/Tenant/Create`,
    {
      method: "POST",
      data: params,
    }
  );
}
        
/**
** api name: Tenant
** api address: /api/Tenant/Lists
** request mode: POST
** interface description: 获取租户列表
*/
export function APostLists (params: Api.V1Tenant.APostLists.Request) {
  return request<Api.V1Tenant.APostLists.Response>(
    `/api/Tenant/Lists`,
    {
      method: "POST",
      data: params,
    }
  );
}