import { Button, Divider, Form, FormInstance, Input, notification, PageHeader, Space } from "antd";
import React from "react";
import { APostCreate } from "services/v1/tenant-manage";
import { SaveButton } from "utils/theme";
import { TenantSelect, TenantProps } from '../../../components/tenant/select'

interface TenantAdministratorFormState {
  loading: boolean
}

export interface TenantAdministratorFormProps {
  onClose: (isRefresh: boolean) => void,
  defaultValue?: TenantProps['defaultValue']
}

const validateMessages = {
  required: `'\${label}' Is a required field`,
};

const request = [{ required: true }];

export default class TenantAdministratorForm extends React.Component<TenantAdministratorFormProps, TenantAdministratorFormState> {

  state: TenantAdministratorFormState = {
    loading: false
  }

  formRef = React.createRef<FormInstance<Api.V1TenantManage.APostCreate.Request>>()

  onFinish = async (formData: Api.V1TenantManage.APostCreate.Request) => {
    this.setState({
      loading: true
    })

    const res = await APostCreate({
      userName: formData.userName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      tenantId: formData.tenantId
    })

    this.setState({
      loading: false
    })

    if (res.success) {
      notification.success({
        message: 'Add tenant administrator success.'
      })
      
      this.props.onClose(true)
    } else {
      notification.error({
        message: res.message
      })
    }
  }

  render () {
    const initialValues:Api.V1TenantManage.APostCreate.Request = {
      tenantId: this.props.defaultValue ? this.props.defaultValue[0].value : undefined
    } 
    return (
      <>
      <div style={{ width: '100%', height: '100%', background: '#fff' }}>
        <PageHeader onBack={() => this.props.onClose(false)} title="Add" subTitle="tenant administartor" />
        <Divider />
        <Form<Api.V1TenantManage.APostCreate.Request>
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={this.onFinish}
          autoComplete="off"
          style={{width: '400px'}}
          validateMessages={validateMessages}
          ref={this.formRef}
          initialValues={initialValues}
        >
          <Form.Item
            label="User Name"
            name="userName"
            rules={[...request, { pattern: /^[^ ]+$/g, message: 'The user name cannot contain Spaces' }]}
          >
            <Input placeholder="Please enter user name"/>
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[...request, { type: 'email', message: 'Please enter email' }]}
          >
            <Input placeholder="Please enter address"/>
          </Form.Item>
          
          <Form.Item
            label="Tenant"
            name="tenantId"
            rules={request}
          >
            <TenantSelect defaultValue={this.props.defaultValue} onChange={(value) => this.formRef.current?.setFieldsValue({ tenantId: value ? value[0] : undefined })} />
          </Form.Item>
          
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
          >
            <Input placeholder="Please enter phone number"/>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <SaveButton loading={this.state.loading} type="primary" htmlType="submit">Submit</SaveButton>
              <Button onClick={() => this.props.onClose(false)}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      </>
    )
  }
} 